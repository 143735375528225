
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import MicRecorder from 'mic-recorder-to-mp3';
import './audio.styles.scss'
import recordAgain from '../../../components/assets/profile/record-again.svg';
import sendAudio from '../../../components/assets/profile/send-audio.svg';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

class Audio extends Component {
  state = {
    isRecording: false,
    blobURL: '',
    isBlocked: false,
    file: null
  }


  // redo for iphone
  componentDidMount() {
    // navigator.getUserMedia({ audio: true },
    //   () => {
    //     console.log('Permission Granted');
    //     this.setState({ isBlocked: false });
    //   },
    //   () => {
    //     console.log('Permission Denied');
    //     this.setState({ isBlocked: true })
    //   },
    // );

    if(this.props.audioUrl) {
      this.setState({
        ...this.state,
        blobURL: this.props.audioUrl
      })
    }

  }

  start = () => {
    if (this.state.isBlocked) {
      console.log('Permission Denied');
    } else {
      Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true });
        }).catch((e) => console.error(e));
    }
  };
  stop = () => {
    Mp3Recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob)

        const file = new File([blob], 'audio-message.mp3', {
          type: blob.type,
          // type: 'multipart/form-data',
          lastModified: Date.now()
        });

        this.setState({ blobURL, isRecording: false,file: file });

       

      }).catch((e) => console.log(e));
  };

  clearAudio = () => {
    this.setState({
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      file: null
    })
  }

  render() {

    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className='Audio '>
          <p className='record mt-2'>Please add your Audio Introduction</p>
          {
            !this.state.isRecording ? (
              <div className="audio-container">
                <div
                  onClick={this.start}
                  className='d-flex-col justify-content-center align-items-center mt-5'
                >
                  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="35" cy="35" r="35" fill="#DAAA4C" />
                    <path d="M34.6531 18C33.4423 18 32.2812 18.481 31.425 19.3371C30.5689 20.1933 30.0879 21.3544 30.0879 22.5652V34.7391C30.0879 35.9499 30.5689 37.1111 31.425 37.9672C32.2812 38.8234 33.4423 39.3043 34.6531 39.3043C35.8639 39.3043 37.0251 38.8234 37.8812 37.9672C38.7373 37.1111 39.2183 35.9499 39.2183 34.7391V22.5652C39.2183 21.3544 38.7373 20.1933 37.8812 19.3371C37.0251 18.481 35.8639 18 34.6531 18Z" fill="white" stroke="white" strokeLinecap="round" stroke-linejoin="round" />
                    <path d="M45.3043 31.696V34.7395C45.3043 37.5646 44.1821 40.274 42.1844 42.2717C40.1867 44.2694 37.4773 45.3917 34.6522 45.3917C31.827 45.3917 29.1176 44.2694 27.1199 42.2717C25.1223 40.274 24 37.5646 24 34.7395V31.696" stroke="white" strokeLinecap="round" stroke-linejoin="round" />
                    <path d="M34.6521 45.391V51.478" stroke="white" strokeLinecap="round" stroke-linejoin="round" />
                    <path d="M28.564 51.478H40.7379" stroke="white" strokeLinecap="round" stroke-linejoin="round" />
                  </svg>

                  <span className='record-now-text'>Record Now</span>
                </div>
               
                {
                  this.state.blobURL ? 
                  (
                  <div className="d-flex-col">
                    <audio src={this.state.blobURL} controls="controls" />
                    <div className="d-flex audio-controller">
                    <button className="btn logo-btn" onClick={this.clearAudio}>
                    <img className="audio-action-icon-again" src={recordAgain} alt="Record Again"/>
                    Record Again
                    </button>

                    <button className="btn logo-btn" onClick={() => this.props.uploadAudio(this.state.file)}>
                    <img className="audio-action-icon-send" src={sendAudio} alt="Send Audio"/>
                    Proceed
                    </button>
                      </div>
                    </div>
                  )
                  : null
                }

                <span className="warning-text">
                  Mention your number will lead to account ban
                </span>
              </div>
            ) : (
                <div className='d-flex justify-content-around align-items-center mt-5'>
                  <div className='record'>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 0C8.954 0 0 8.954 0 20C0 31.046 8.954 40 20 40C31.046 40 40 31.046 40 20C40 8.954 31.046 0 20 0ZM18 28H14V12H18V28ZM26 28H22V12H26V28Z" fill="#DAAA4C" />
                    </svg>
                    <div>
                      <span>Pause</span>
                    </div>
                  </div>
                  <div className='record' onClick={this.stop} disabled={!this.state.isRecording}>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 0C8.97212 0 0 8.97212 0 20C0 31.0279 8.97212 40 20 40C31.0279 40 40 31.0279 40 20C40 8.97212 31.0279 0 20 0ZM27.6923 25.2308C27.6918 25.8835 27.4323 26.5093 26.9708 26.9708C26.5093 27.4323 25.8835 27.6918 25.2308 27.6923H14.7692C14.1165 27.6918 13.4907 27.4323 13.0292 26.9708C12.5677 26.5093 12.3082 25.8835 12.3077 25.2308V14.7692C12.3082 14.1165 12.5677 13.4907 13.0292 13.0292C13.4907 12.5677 14.1165 12.3082 14.7692 12.3077H25.2308C25.8835 12.3082 26.5093 12.5677 26.9708 13.0292C27.4323 13.4907 27.6918 14.1165 27.6923 14.7692V25.2308Z" fill="#DB7250" />
                    </svg>
                    <div>
                      <span>Stop</span>
                    </div>
                  </div>
                </div>
              )
          }
        </div>

      </Modal>
    )
  }
}

export default Audio