import { createSelector } from "reselect";


import { convertItem, setName } from '../../utils/display.utils';
import { selectIsPremium } from "../auth/auth.selector";

export const history = state => state.history;

export const selectContact = createSelector(
  [history],
  history => history.contact
)
export const selectReject = createSelector(
  [history],
  history => history.reject
)
export const selectShortlist = createSelector(
  [history],
  history => history.shortlist
)
export const selectProfile = createSelector(
  [history],
  history => history.profile ? convertItem(history.profile[0]) : null
)

export const selectCount = createSelector(
  [history],
  history => ({ SL: history.likesCount, C: history.contactedCount, R: history.rejectedCount })
)

// export const selectHistoryByProps = createSelector(
//   [history, (_, props) => props.active,selectIsPremium],
//   (history, active,isPremium) => {
//     if (['shortlist', 'reject', 'contact'].includes(active)) {
//       return history[active].map(item => getHeading(item,isPremium))
//     }
//     return []
//   }
// )
// console.log(history);

export const selectHistoryByProps = createSelector(
  [history, (_, props) => props.active, selectIsPremium],
  (history, active, isPremium) => {
    if (['shortlist', 'reject', 'contact'].includes(active) && Array.isArray(history[active])) {
      return history[active].map(item => getHeading(item, isPremium));
    }
    return [];
  }
);

export const selectHistorySearch = createSelector(
  [history, selectIsPremium],
  (history, isPremium) => [...history.contact, ...history.shortlist, ...history.reject].map(item => getHeading(item, isPremium))
)

const getHeading = (item, isPremium) => ({
  // console.log(item);
  title: `${item.type !== 'CONTACTED' ? setName(item.name, isPremium) : item.name}, ${item.age}`,
  subTitle1: `${item.religion} | ${item.caste}`,
  subTitle2: `${item.working_city}`,
  subTitle3: `${item.annual_income / 1} Lpa`,
  photo: item.photo ? item.photo : null,
  id: item.id,
  mobile: item.type === 'CONTACTED' ? item.user_mobile : null
})