import React from "react";
import Slider from "react-slick";
import "./video-carouselTestimonial.scss";

const VideoCarouselTestimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="video-carousel">
      <div className="video-carousel-header">
        <h1>"Personalised matches done on Hans Matrimony"</h1>
      </div>
      <Slider {...settings}>
        <div className="video-carousel-slide">
          <div className="video-container">
            <iframe
              src="https://www.youtube.com/embed/GHwb1Obrwaw"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div className="video-carousel-slide">
          <div className="video-container">
            <iframe
              src="https://www.youtube.com/embed/M_F2jBaA6Wo"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div className="video-carousel-slide">
          <div className="video-container">
            <iframe
              src="https://www.youtube.com/embed/7bJnrh2ITdQ"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div className="video-carousel-slide">
          <div className="video-container">
            <iframe
              src="https://www.youtube.com/embed/2nr_Ssj8ouo"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div className="video-carousel-slide">
          <div className="video-container">
            <iframe
              src="https://www.youtube.com/embed/lV5gzGIj2xc"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default VideoCarouselTestimonial;
