import React from 'react';

import { Modal } from 'react-bootstrap';

import './full-screen-image.styles.scss';

const FullScreenImage = (props) => {

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className='full-screen'>
        <img src={props.url} className='full-screen-img' alt="" />
      </div>
    </Modal>
  )
}

export default FullScreenImage;