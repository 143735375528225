import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { updatePersonalDetails } from '../../../redux/my-profile/my-profile.action'

function AboutForm({personalData,submitForm,updatePersonalDetails,close}) {

    const [formDetails,setFormDetails] = useState({
        about: personalData.about
    })

    useEffect(() => {
        if (submitForm) {
            updatePersonalDetails(formDetails)
            close()
        }
    },[submitForm])

    const handleAboutChange = (e) => {
        setFormDetails({
            ...formDetails,
            about: e.target.value
        })
    }

  return (
    <div className="about-form-container">
        <span>
            About (max 300 characters)
        </span>
        <textarea className="w-100 mt-3" cols={50} rows={8}
        maxLength="300"
        defaultValue={formDetails.about}
        placeholder="About (max 300 characters)"
        onChange={handleAboutChange}
        />
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
    updatePersonalDetails: (data) => dispatch(updatePersonalDetails(data),)
  })

export default connect(null, mapDispatchToProps)(AboutForm)