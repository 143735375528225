import React, { useState, useEffect } from "react";

import "./offline-center.styles.scss";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Frame6 from "../../../components/assets/hero/Frame6.svg";

import center1 from "../../../components/assets/offlineCenters/center1.jpg";
import center2 from "../../../components/assets/offlineCenters/center2.jpg";
import center3 from "../../../components/assets/offlineCenters/center3.jpg";
import arrows from "../../../components/assets/offlineCenters/arrows.png";
import yourstorylogo from "../../../components/assets/hero/yourstorylogo.svg";
import financialexpresslogo from "../../../components/assets/hero/financialexpresslogo.svg";
import economictimeslogo from "../../../components/assets/hero/economictimeslogo.svg";
import techcirclelogo from "../../../components/assets/hero/techcirclelogo.svg";
import Modal from "react-modal";
import imagelogo1 from "../../../components/assets/hero/number-logo-1.svg";
import imagelogo3 from "../../../components/assets/hero/number-logo-2.svg";
import imagelogo2 from "../../../components/assets/hero/number-logo-3.svg";

import prev from "../../../components/assets/offlineCenters/arrowLeft.svg";
import next from "../../../components/assets/offlineCenters/arrowRight.svg";
import { Padding } from "@mui/icons-material";

const OfflineCenter = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const phoneNumber = "+919697989697"; // Your phone number here
  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    border: "none",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 1)",
    maxWidth: "400px",
    width: "100%",
    textAlign: "center",
    zIndex: "9999",
  };

  const overlayStyle = {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "9999",
  };
  const openModal = () => {
    console.log("inside openModal");
    setModalIsOpen(true);
    console.log(modalIsOpen);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let view;
  if (!isMobile) {
    view = (
      <>
        <div className="off-match-makers">
          <div className="laptop-view-con">
            <div className="d-flex justify-content-center">
              <h1 className="off-h1">
                50+ <span style={{ color: "#24ADD8" }}>Temple</span> centres
                <hr className="off-one" />
                <hr className="off-two" />
              </h1>
            </div>
          </div>

          <div className="carousel-div">
            <OwlCarousel
              items={1}
              className="owl-theme"
              margin={8}
              autoplay={true}
              nav={true}
              navText={[`<img src=${prev}>`, `<img src=${next}>`]}
              dots={false}
              loop={true}
            >
              <div className="off-slide item">
                <div className="off-temple-card off-text-shadow">
                  <span className="off-temple-name"></span>
                  <div className="off-featureCard2">
                    <img
                      className="off-feature2Img"
                      src={center1}
                      alt="Shiv Sai Dham Mandir"
                    />
                  </div>
                </div>
                <p className="off-p">Shree Shiv Sai Dham Mandir</p>
              </div>
              <div className="off-slide item">
                <div className="off-temple-card off-text-shadow">
                  <span className="off-temple-name"></span>
                  <div className="off-featureCard2">
                    <img
                      className="off-feature2Img"
                      src={center2}
                      alt="Sanathan Dharam Mandir"
                    />
                  </div>
                </div>
                <p className="off-p">Shree Lakshmi Narayan Mandir</p>
              </div>
              <div className="off-slide item">
                <div className="off-temple-card off-text-shadow">
                  <span className="off-temple-name"></span>
                  <div className="off-featureCard2">
                    <img
                      className="off-feature2Img"
                      src={center3}
                      alt="Lakshmi Narayan Mandir"
                    />
                  </div>
                </div>
                <p className="off-p">Shree Sanatan Dharam Mandir</p>
              </div>
            </OwlCarousel>

            <div className="d-flex justify-content-center">
              <button
                className="off-button"
                routerLink="/viewCentres"
                routerLinkActive="router-link-active"
              >
                Show More
              </button>
            </div>
          </div>

          <div className="brand-images-div">
            <div className="brand-container-div" style={{ marginTop: "0px" }}>
              <div className="brand-details-con" style={{}}>
                A community of over 1 million+ Famlies
              </div>
              <div className="brand-images-con-div" style={{}}>
                <img
                  className="frame-image-6"
                  src={Frame6}
                  alt="Frame6"
                  style={{ marginLeft: "25px" }}
                />
                <div style={{}}>with 200,000+ Families united</div>
              </div>
            </div>
            <img
              className="financial-express-logo"
              src={financialexpresslogo}
              alt="financialexpresslogo"
            />
            <img
              className="your-story-logo"
              src={yourstorylogo}
              alt="yourstorylogo"
            />
            <img
              className="economic-times-logo"
              src={economictimeslogo}
              alt="economictimeslogo"
              style={{ width: "30%" }}
            />
            <img
              className="tech-circle-logo"
              src={techcirclelogo}
              alt="techcirclelogo"
            />
          </div>
          <div className="hans-steps-div">
            <h1 className="h1-offline">Hans Matrimony Way!</h1>
            <div className="number-logo-container">
              <div className="number-logo-with-text-con">
                <img className="imagelogo1" src={imagelogo1} alt="imagelogo1" />
                <p className="image-text-con">
                  Register with us and connect with Personal Matchmaker
                </p>
              </div>
              <div className="number-logo-with-text-con">
                <img className="imagelogo2" src={imagelogo2} alt="imagelogo2" />
                <p className="image-text-con">
                  Get introduced to curated profiles weekly
                </p>
              </div>
              <div className="number-logo-with-text-con">
                <img className="imagelogo3" src={imagelogo3} alt="imagelogo2" />
                <p className="image-text-con">
                  Meet your Match in Person with family!
                </p>
              </div>
            </div>
            <div className="image-container">
              <img src={arrows} alt="Your Image" className="image" />
            </div>
            <h3 className="h3-offline">
              Lead a happy life with your life partner
            </h3>
            <button className="hire-btn" onClick={openModal}>
              Hire Personal Matchmaker
            </button>

            {/* <a href="#register-now-redirect" className="hire-btn-1">
            Hire Personal Matchmaker
            </a> */}
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Call Modal"
          style={{
            content: modalStyle,
            overlay: overlayStyle,
          }}
        >
          <h2 style={{}}>Please give us a call at:</h2>
          <p style={{ fontSize: "20px" }}>{phoneNumber}</p>
          <button
            onClick={closeModal}
            style={{
              backgroundColor: "#FF9A9E",
              border: "none",
              borderRadius: "5px",
              color: "black",
              width: "100px",
              marginTop: "20px",
            }}
          >
            Close
          </button>
        </Modal>
      </>
    );
  } else {
    view = (
      <div className="off-match-makers">
        <div className="laptop-view-con">
          <div className="d-flex justify-content-center">
            <h1 className="off-h1">
              50+ <span style={{ color: "#24ADD8" }}>Temple</span> centres
              <hr className="off-one" />
              <hr className="off-two" />
            </h1>
          </div>
        </div>

        <div className="carousel-div">
          <OwlCarousel
            items={1}
            className="owl-theme"
            margin={8}
            autoplay={true}
            nav={true}
            navText={[`<img src=${prev}>`, `<img src=${next}>`]}
            dots={false}
            loop={true}
          >
            <div className="off-slide item">
              <div className="off-temple-card off-text-shadow">
                <span className="off-temple-name"></span>
                <div className="off-featureCard2">
                  <img
                    className="off-feature2Img"
                    src={center1}
                    alt="Shiv Sai Dham Mandir"
                  />
                </div>
              </div>
              <p className="off-p">Shree Shiv Sai Dham Mandir</p>
            </div>
            <div className="off-slide item">
              <div className="off-temple-card off-text-shadow">
                <span className="off-temple-name"></span>
                <div className="off-featureCard2">
                  <img
                    className="off-feature2Img"
                    src={center2}
                    alt="Sanathan Dharam Mandir"
                  />
                </div>
              </div>
              <p className="off-p">Shree Lakshmi Narayan Mandir</p>
            </div>
            <div className="off-slide item">
              <div className="off-temple-card off-text-shadow">
                <span className="off-temple-name"></span>
                <div className="off-featureCard2">
                  <img
                    className="off-feature2Img"
                    src={center3}
                    alt="Lakshmi Narayan Mandir"
                  />
                </div>
              </div>
              <p className="off-p">Shree Sanatan Dharam Mandir</p>
            </div>
          </OwlCarousel>

          <div className="d-flex justify-content-center">
            <button
              className="off-button"
              routerLink="/viewCentres"
              routerLinkActive="router-link-active"
            >
              Show More
            </button>
          </div>
        </div>

        <div className="brand-images-div">
          {/* <img
          className="financial-express-logo"
          src={financialexpresslogo}
          alt="financialexpresslogo"
          style={{ marginBottom: "60px" }}
        /> */}
          {/* <img
          className="your-story-logo"
          src={yourstorylogo}
          alt="yourstorylogo"
          style={{ marginBottom: "60px" }}
        /> */}
          {/* <img
          className="economic-times-logo"
          src={economictimeslogo}
          alt="economictimeslogo"
          style={{ marginBottom: "60px" }}
        /> */}
          {/* <img
          className="tech-circle-logo"
          src={techcirclelogo}
          alt="techcirclelogo"
          style={{ marginBottom: "36px" }}
        /> */}
        </div>

        <div className="hans-steps-div">
          <h1
            style={{
              textAlign: "center",
              fontSize: "30px",
              textShadow: "2px 1px 7px #01060ba6",
            }}
            className="h1-offline"
          >
            {" "}
            Hans Matrimony Way{" "}
          </h1>
          <div className="number-logo-with-text-con">
            <img className="imagelogo1" src={imagelogo1} alt="imagelogo1" />
            <p className="image-text-con">
              Register with us and connect with Personal Matchmaker
            </p>
          </div>
          <div className="number-logo-with-text-con">
            <img className="imagelogo2" src={imagelogo2} alt="imagelogo2" />
            <p className="image-text-con">
              Get introduced to curated profiles weekly
            </p>
          </div>
          <div className="number-logo-with-text-con">
            <img className="imagelogo3" src={imagelogo3} alt="imagelogo2" />
            <p className="image-text-con">
              Meet your Match in Person with family!
            </p>
          </div>
          <h3
            style={{
              textAlign: "center",
              fontSize: "16px",
              paddingTop: "30px",
              paddingBottom: "10px",
            }}
          >
            {" "}
            Lead a happy life with your life partner{" "}
          </h3>
          {/* <a href="#register-now-redirect" className="hire-btn-1">
          {/* {" "} */}
          {/* Hire Personal Matchmaker{" "}</a> */}
        </div>
      </div>
    );
  }
  return view;
};

export default OfflineCenter;
