
import { FETCH, API, FETCH_FROM_NODE } from '../../utils/api';

import {setError} from '../error/error.action';

import DiscoverActionTypes from './discover.types';


export const getDiscoverProfile = (data) => {
  return dispatch => {
    dispatch({ type: DiscoverActionTypes.GET_DISCOVER_PROFILE_START });
    FETCH_FROM_NODE({
      ContentType: 'application/json',
      url: API.DISCOVER_PROFILES_URL,
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {

        dispatch({
          type: DiscoverActionTypes.GET_DISCOVER_PROFILE_SUCCUSS,
          payload: resData,
        });
      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: DiscoverActionTypes.GET_DISCOVER_PROFILE_FAILURE });
      });
  };

}

export const getDiscoverPremiumProfile = (data) => {
  return dispatch => {
    dispatch({ type: DiscoverActionTypes.GET_DISCOVER_PREMIUM_PROFILE_START });
    FETCH({
      url: API.DISCOVER_PREMIUM_PROFILES_URL,
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {

        dispatch({
          type: DiscoverActionTypes.GET_DISCOVER_PREMIUM_PROFILE_SUCCUSS,
          payload: resData.message,
        });
      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: DiscoverActionTypes.GET_DISCOVER_PREMIUM_PROFILE_FAILURE });
      });
  };
}