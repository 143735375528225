import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import ill1 from "../../../components/assets/featuresIllustrations/ill1.jpg";
import image1 from "../../../components/assets/featuresIllustrations/image1.png";
import image2 from "../../../components/assets/featuresIllustrations/image2.png";
import overlayImage from "../../../components/assets/featuresIllustrations/Frame.png";
import ill2 from "../../../components/assets/featuresIllustrations/ill2.jpg";
import ill3 from "../../../components/assets/featuresIllustrations/ill3.jpg";
import Frame3 from "../../../components/assets/hero/Frame3.svg";
import Frame4 from "../../../components/assets/hero/Frame4.svg";
import Frame5 from "../../../components/assets/hero/Frame5.svg";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import "./features.styles.scss";
const Feature = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const phoneNumber = "+919697989697"; // Your phone number here
  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    border: "none",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 1)",
    maxWidth: "400px",
    width: "100%",
    textAlign: "center",
    zIndex: "9999",
  };

  const overlayStyle = {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "9999",
  };
  const openModal = () => {
    console.log("inside openModal");
    setModalIsOpen(true);
    console.log(modalIsOpen);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  let view;

  if (!isMobile) {
    view = (
      <>
        <div className="features">
          <div className="overlay-content">
            <div className="text-container">
              <h2 className="overlay-heading">
                Hire your Personal Matchmaker today!
              </h2>
              <p className="overlay-text">
                Finding your perfect match just became easier with Hans
                Matrimony.
              </p>
            </div>
            <button className="hire-btn" onClick={openModal}>
              Hire Personal Matchmaker
            </button>
          </div>
          <div className="feature-row">
            <div className="feature-img-container">
              <img
                className="feature-img"
                src={image1}
                alt="hansmatrimony-features-illustration1"
              />
            </div>
            <div className="feature-img-container">
              <img
                className="feature-img"
                src={image2}
                alt="hansmatrimony-features-illustration2"
              />
            </div>
          </div>
          <div className="overlay-img-container">
            <img className="overlay-img" src={Frame4} alt="overlay-image" />
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Call Modal"
          style={{
            content: modalStyle,
            overlay: overlayStyle,
          }}
        >
          <h2 style={{}}>Please give us a call at:</h2>
          <p style={{ fontSize: "20px" }}>{phoneNumber}</p>
          <button
            onClick={closeModal}
            style={{
              backgroundColor: "#FF9A9E",
              border: "none",
              borderRadius: "5px",
              color: "black",
              width: "100px",
              marginTop: "20px",
            }}
          >
            Close
          </button>
        </Modal>
      </>
    );
  } else {
    view = (
      <div className="container-div">
        <div ngIf="mobileScreen" className="carousel-div">
          <OwlCarousel
            items={1}
            className="owl-theme"
            margin={8}
            autoplay={true}
          >
            <div className="feature-slide item">
              <div className="feature-temple-card feature-text-shadow">
                <div className=" feature-featureCard2">
                  <img
                    className="feature-feature2Img"
                    src={ill1}
                    alt="hansmatrimony-features-illustration2"
                  />
                </div>
              </div>
              <p className="feature-p">
                Get Your{" "}
                <span style={{ fontWeight: "bolder" }}>
                  Personal Matchmaker
                </span>
                <br />
                from 1000+Matchmakers{" "}
              </p>
            </div>
            <div className="feature-slide item">
              <div className="feature-temple-card feature-text-shadow">
                <div className=" feature-featureCard2">
                  <img
                    className="feature-feature2Img"
                    src={ill2}
                    alt="hansmatrimony-features-illustration2"
                  />
                </div>
              </div>
              <p className="feature-p">
                More than <span style={{ fontWeight: "bolder" }}>50,000 </span>
                <br />
                suitable matches{" "}
              </p>
            </div>
            <div className="feature-slide item">
              <div className="feature-temple-card feature-text-shadow">
                <div className=" feature-featureCard2">
                  <img
                    className="feature-feature2Img"
                    src={ill3}
                    alt="hansmatrimony-features-illustration2"
                  />
                </div>
              </div>
              <p className="feature-p">
                Verified matches from <br />
                <span style={{ fontWeight: "bolder" }}>50+ temples</span> in
                india{" "}
              </p>
            </div>
          </OwlCarousel>
        </div>

        <div className="mobile-responsive-div">
          {/* <img className='frame-image-3' src={Frame3} alt="Frame3" /> */}
          <div className="mob-responsive-img-div">
            <img className="frame-image-5" src={Frame5} alt="Frame5" />
            <img className="frame-image-4" src={Frame4} alt="Frame4" />
          </div>

          <div className="mob-responsove-text-div">
            <p className="text-1">
              {/* Hire Your Personal Matchmaker Today */}
            </p>
            <p className="text-2">
              {" "}
              {/* Finding you perfect match just became easier with Hans Matrimony{" "} */}
            </p>
            {/* <a className="hire-btn " href="#register-now-redirect">
              Hire Personal Matchmaker{" "}
            </a> */}
            <Link to="/fourReg" className="btn hero-btn hero-register-btn">
              Hire Personal Matchmaker
            </Link>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div id="us" className="feature-whyus">
      <div className="feature-why-us-header">
        <div className="d-flex justify-content-center">
          <div className="header feature-style.header">
            <h1 className="feature-h1" style={{ fontWeight: "bold" }}>
              Why <span style={{ color: "#24ADD8" }}>Us?</span>
              <hr className="feature-one" />
              <hr className="feature-two" />
            </h1>
          </div>
        </div>
      </div>
      {view}
    </div>
  );
};

export default Feature;
