const TodaySpecialActionTypes = {
  GET_TODAY_PROFILE_START: 'GET_TODAY_PROFILE_START',
  GET_TODAY_PROFILE_SUCCESS: 'GET_TODAY_PROFILE_SUCCESS',
  GET_TODAY_PROFILE_FAILURE: 'GET_TODAY_PROFILE_FAILURE',
  
  POST_PROFILE_ACTION_START: 'POST_PROFILE_ACTION_START',
  POST_PROFILE_ACTION_SUCCESS: 'POST_PROFILE_ACTION_SUCCESS',
  POST_PROFILE_ACTION_FAILURE: 'POST_PROFILE_ACTION_FAILURE',

  GET_KUNDALI_START: 'GET_KUNDALI_START',
  GET_KUNDALI_SUCCESS: 'GET_KUNDALI_SUCCESS',
  GET_KUNDALI_FAILURE: 'GET_KUNDALI_FAILURE',
  RESET_KUNDLI: 'RESET_KUNDLI',

  REMOVE_AND_UPDATE_ITEM_FROM_TODAY_SPECIAL_LIST: 'REMOVE_AND_UPDATE_ITEM_FROM_TODAY_SPECIAL_LIST',
}

export default TodaySpecialActionTypes;