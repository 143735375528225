import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { connect,useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import CasteSelect from '../../../components/forms-elements/caste-select/caste-select'
import FoodChoiceSelect from '../../../components/forms-elements/food-choice-select/food-choice-select'
import LocationSelect from '../../../components/forms-elements/location-select/location-select'
import MaritalStatusSelect from '../../../components/forms-elements/marital-status-select/marital-status-select'
import { selectCasteMapping, selectFoodChoiceMapping, selectMaritalMapping, selectReligionMapping } from '../../../redux/mappedData/mappedData.selector'
import { selectId } from '../../../redux/auth/auth.selector'
import { updatePersonalDetails } from '../../../redux/my-profile/my-profile.action'
import {  selectHeights2 } from '../../../redux/register/register.selector'
import { getCasteId, getFoodChoiceInt, getMaritalInt, getReligionInt, setHeightInt2 } from '../../../utils/display.utils'

function PersonalDetailsForm({heights,religionList,personalData,maritalStatusMapping,submitForm,
    updatePersonalDetails,close,allCaste,foodChoiceList}) {

      
      const id = useSelector(selectId)
    console.log('this is PD',personalData)
    const [formDetails, setFormDetails] = useState({
        name: personalData.name,
        age: personalData.age,
        weight: personalData.weight ? parseInt(personalData.weight.split(" ")[0]) : 0,
        height: personalData.height,
        // height_int: setHeightInt2(personalData.height),
        height_int: 68,
        religion: personalData.religion,
        religionCode: getReligionInt(personalData.religion,religionList)[0],
        caste: personalData.caste,
        casteCode_user: getCasteId(personalData.caste,allCaste),
        food_choice: personalData.foodChoice,
        userId : personalData.userId, 
        food_choiceCode: getFoodChoiceInt(personalData.foodChoice,foodChoiceList),
        marital_status: personalData.maritalStatus,
        maritalStatusCode: getMaritalInt(personalData.maritalStatus,maritalStatusMapping),
        locality: personalData.locality
    })

    // useEffect(()=> {
    //   setFormDetails({
    //     name: personalData.name,
    //     age: personalData.age,
    //     weight: personalData.weight ? parseInt(personalData.weight.split(" ")[0]) : 0,
    //     height: personalData.height,
    //     height_int: setHeightInt2(personalData.height),
    //     religion: personalData.religion,
    //     religionCode: getReligionInt(personalData.religion,religionList)[0],
    //     caste: personalData.caste,
    //     casteCode_user: getCasteId(personalData.caste,allCaste),
    //     food_choice: personalData.foodChoice,
    //     userId : personalData.userId, 
    //     food_choiceCode: getFoodChoiceInt(personalData.foodChoice,foodChoiceList),
    //     marital_status: personalData.maritalStatus,
    //     maritalStatusCode: getMaritalInt(personalData.maritalStatus,maritalStatusMapping),
    //     locality: personalData.locality
    // })
    // }, [personalData])


  
    // (updatePersonalDetails(formDetails))
    
    useEffect(() => {
      console.log('userId2100:', formDetails.userId ? formDetails.userId : 'not defined');

      console.log('formDetails',formDetails)
        if (submitForm && formDetails.userId) {
          let userId = personalData?.userId
          console.log("personalData ",personalData);
            (updatePersonalDetails({...formDetails,userId }))
            close()
            console.log('formDetails',formDetails)
            console.log('userId21000:', formDetails.userId ? formDetails.userId : 'not defined');
        }
    },[submitForm,formDetails]) 
   
    const handleChangeTextFields = (e) => {

        if (e.target.name) {
            setFormDetails({
              ...formDetails,
              [e.target.name] : e.target.value
            })
          } 
    }

    const handleHeightChange = (e,value) => {
        if(value) {
            setFormDetails({
              ...formDetails,
              height_int: setHeightInt2(value),
              height: value
            })
           }
    }

    const handleReligionChange = (e,value) => {
        if (value) {
            setFormDetails({
                ...formDetails,
                religion: value,
                religionCode: getReligionInt(value,religionList)[0]
              })
        }
    }
    
    const handleCasteChange = (e,value) => {
        if(value) {
            setFormDetails({
              ...formDetails,
              caste: value,
              casteCode_user: getCasteId(value,allCaste)
            })
          }
    }

    const handleFoodChoiceChange = (e,value) => {
        if(value) {
            setFormDetails({
              ...formDetails,
              food_choice: value,
              food_choiceCode: getFoodChoiceInt(value,foodChoiceList)
            })
          }
    }
    
    const handleMaritalStatusChange = (e,value) => {
        if(value) {
            setFormDetails({
              ...formDetails,
              marital_status: value,
              maritalStatusCode: getMaritalInt(value,maritalStatusMapping)
            })
          }
    }

    const handleLocalityChange = (e,value) => {
        if(value) {
            setFormDetails({
              ...formDetails,
              locality: value,
            })
          }
    }
    

  return (
    <form className='personal-details-form-container'  >

         <TextField  
         className='input-field w-100'
              variant="outlined"
               value={formDetails.name}
               type="text"
               name='name'
               disabled
                />

            <TextField 
             className='input-field w-100 mt-3'
              variant="outlined"
               value={formDetails.age}
               type="text"
               name='age'
               disabled
                />

            <div className="filter-field">

            <TextField  label="Weight" 
             className='input-field'
              variant="outlined"
               value={formDetails.weight}
               type="number"
               name='weight'
               onChange={handleChangeTextFields}
                />

                 <Autocomplete
                      className='input-field ml-3'
                      disablePortal
                      id="height"
                      options={heights}
                      name="height"
                      sx={{ width: 300 }}
                      value={formDetails.height}
                      onChange={handleHeightChange}
                      renderInput={(params) => <TextField {...params} label="Height" />}
                    />

            </div>

            <div className="filter-field">
            <Autocomplete
                      className='input-field'
                      id="religion"
                      name="religion"
                      options={religionList.map(it => it.religion)}
                      sx={{ width: 300 }}
                      defaultValue={formDetails.religion}
                      onChange={handleReligionChange}
                      renderInput={(params) => <TextField {...params} label="Religion" />}
                    />

                    <CasteSelect value={formDetails.caste} handler={handleCasteChange} styles="input-field ml-3"
                    multiple={false} />
                </div>

                <div className="filter-field">
                    <FoodChoiceSelect value={formDetails.food_choice} handler={handleFoodChoiceChange} styles="input-field"/>

                    <MaritalStatusSelect value={formDetails.marital_status} handler={handleMaritalStatusChange} styles="input-field ml-3"/>
                </div>

                <div className="filter-field">
                    <LocationSelect value={formDetails.locality} handler={handleLocalityChange} styles="input-field"/>
                </div>

    </form>
  )
}

const mapStateToProps = createStructuredSelector({
    id :selectId,
    heights: selectHeights2,
    religionList: selectReligionMapping,
    maritalStatusMapping: selectMaritalMapping,
    allCaste: selectCasteMapping,
    foodChoiceList: selectFoodChoiceMapping
})

const mapDispatchToProps = dispatch => ({
    updatePersonalDetails: (data) => dispatch(updatePersonalDetails(data),)
  })

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetailsForm)