
const HistoryActionTypes = {
  GET_HISTORY_START: 'GET_HISTORY_START',
  GET_HISTORY_SUCCESS: 'GET_HISTORY_SUCCESS',
  GET_HISTORY_FAILURE: 'GET_HISTORY_FAILURE',
  POST_REPELY_START: 'POST_REPELY_START',
  POST_REPLY_SUCCESS: 'POST_REPLY_SUCCESS',
  POST_REPELY_FAILURE: 'POST_REPELY_FAILURE',
  GET_PROFILE_START: 'GET_PROFILE_START',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',
  REMOVE_PROFILE: 'REMOVE_PROFILE',
  GET_CREDITS_COUNT_START: 'GET_CREDITS_COUNT_START',
  GET_CREDITS_COUNT_SUCCESS: 'GET_CREDITS_COUNT_SUCCESS',
  GET_CREDITS_COUNT_FAILURE: 'GET_CREDITS_COUNT_FAILURE',

  POST_PROFILE_ACTION_START: 'POST_PROFILE_ACTION_START',
  POST_PROFILE_ACTION_SUCCESS: 'POST_PROFILE_ACTION_SUCCESS',
  POST_PROFILE_ACTION_FAILURE: 'POST_PROFILE_ACTION_FAILURE',
}

export default HistoryActionTypes;