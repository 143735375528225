
import { FETCH, API, FETCH_FROM_NODE, UPLOAD_FROM_NODE } from '../../utils/api'
import { sectionTypes } from '../../utils/display.utils';
import {updatePhotoList} from '../auth/auth.action';
import { getDiscoverProfile } from '../discover/discover.action';
import {setError} from '../error/error.action';
import MyProfileActionTypes from './my-profile.types';

export const getMyProfile = (data) => {
  console.log("my profile" , data)
  return (dispatch,getState) => {
    dispatch({ type: MyProfileActionTypes.GET_MY_PROFILE_START })
    data.userId = getState().auth.auth.id
    FETCH_FROM_NODE({
      method: 'GET',
      url: API.GET_USER_PROFILE_URL+`?userId=${data.userId}&viewing_mine=${data.view ?? 0}`, // data.id
      header: data.header,
      ContentType:'application/json; charset=utf-8'
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {

        dispatch({
          type: MyProfileActionTypes.GET_MY_PROFILE_SUCCESS,
          payload: resData,
        });

        dispatch(updatePhotoList(resData.userProfileDetail[0].photo_url))
      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: MyProfileActionTypes.GET_MY_PROFILE_FAILURE });
      });
  }
}

export const uploadProfilePicture = (id,data) => {

  return dispatch => {
    dispatch({ type: MyProfileActionTypes.UPLOAD_PROFILE_PICTURE_START })
    UPLOAD_FROM_NODE({
      url: `${API.UPLOAD_PHOTO_URL}?userId=${id}`,
      method: 'POST',
      ContentType: 'application/json; charset=utf-8',
      body: data,
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {

        dispatch({
          type: MyProfileActionTypes.UPLOAD_PROFILE_PICTURE_SUCCESS,
          payload: resData,
          
        });

        dispatch(getMyProfile({id: id,view: 0}));
      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: MyProfileActionTypes.UPLOAD_PROFILE_PICTURE_FAILURE });
      });
  }
}

export const updatePersonalDetails = (data) => {
  return (dispatch,getState) => {
    dispatch({ type: MyProfileActionTypes.UPDATE_PERSONAL_DETAILS_START })
    data.userId = getState().auth.auth.id
    FETCH_FROM_NODE({
      ContentType: 'application/json',
      url: API.EDIT_PERSONAL_DETAILS_URL,
      method: 'PUT',
      body: JSON.stringify(data)
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {

        dispatch({
          type: MyProfileActionTypes.UPDATE_PERSONAL_DETAILS_SUCCESS,
          payload: resData,
        });

        setTimeout(() => {
          dispatch(getMyProfile({ userId: data.userId ?? data.d,view: 0}))
        }, 500)
 
      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: MyProfileActionTypes.UPDATE_PERSONAL_DETAILS_FAILURE });
      });
  }
}

export const updateFamilyDetails = (data) => {
  return dispatch => {
    dispatch({ type: MyProfileActionTypes.UPDATE_FAMILY_DETAILS_START })
    FETCH_FROM_NODE({
      ContentType: 'application/json',
      url: API.EDIT_FAMILY_URL,
      method: 'PUT',
      body: JSON.stringify(data)
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {

        dispatch({
          type: MyProfileActionTypes.UPDATE_FAMILY_DETAILS_SUCCESS,
          payload: resData,
        });

        setTimeout(() => {
          dispatch(getMyProfile({ userId: data.id, view:0 }))
        }, 500)

      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: MyProfileActionTypes.UPDATE_FAMILY_DETAILS_FAILURE });
      });
  }
}


export const updatePreferencesDetails = (data,section) => {
  return dispatch => {
    dispatch({ type: MyProfileActionTypes.UPDATE_PREFERENCES_START })
    FETCH_FROM_NODE({
      ContentType: 'application/json',
      url: API.EDIT_PREFERENCE_URL,
      method: 'PUT',
      body: JSON.stringify(data)
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {

        dispatch({
          type: MyProfileActionTypes.UPDATE_PREFERENCES_SUCCESS,
          payload: resData,
        });

        setTimeout(() => {
          dispatch(getMyProfile({ userId: data.userId,view: 0}))
        }, 500)

       switch (section) {
         case sectionTypes.SEE_MORE:
           data.index_no = 0
           data.dynamicPref = 1
            dispatch(getDiscoverProfile(data))
           break;
       
         default:
           break;
       }

      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: MyProfileActionTypes.UPDATE_PREFERENCES_FAILURE });
      });
  }
}

export const getContactedProfile = (data) => {
  return dispatch => {
    dispatch({ type: MyProfileActionTypes.GET_CONTACTED_PROFILES_START })
    FETCH_FROM_NODE({
      ContentType: "application/json",
      url: API.GET_CONTACTED_PROFILES_URL + "?userId=" + data.id,
      method: 'GET'
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {

        dispatch({
          type: MyProfileActionTypes.GET_CONTACTED_PROFILES_SUCCESS,
          payload: resData.contactedProfiles,
        });

      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: MyProfileActionTypes.GET_CONTACTED_PROFILES_FAILURE });
      });
  }
}

export const getRishteyCount = (data) => {
  return dispatch => {
    dispatch({ type: MyProfileActionTypes.GET_RISHTEY_COUNT_START })
    FETCH_FROM_NODE({
      ContentType: "application/json",
      url: API.GET_RISHTEY_COUNT,
      method: 'POST',
      body: JSON.stringify(data)
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {
        dispatch({
          type: MyProfileActionTypes.GET_RISHTEY_COUNT_SUCCESS,
          payload: resData.count,
        });

        dispatch(getRecommendedPreferences(data.userId))

      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: MyProfileActionTypes.GET_RISHTEY_COUNT_FAILURE });
      });
  }
}

export const getRecommendedPreferences = (userId) => {
  return dispatch => {
    dispatch({ type: MyProfileActionTypes.GET_RECOMMENDED_PREFERENCE_START })
    FETCH_FROM_NODE({
      ContentType: "application/json",
      url: `${API.GET_RECOMMENDED_PREFERENCE}?userId=${userId}`,
      method: 'GET'
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {

        dispatch({
          type: MyProfileActionTypes.GET_RECOMMENDED_PREFERENCE_SUCCESS,
          payload: resData.recommendedPreferences,
        });

      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: MyProfileActionTypes.GET_RECOMMENDED_PREFERENCE_FAILURE });
      });
  }
}

export const uploadProfileAudio = (id,data) => {

  return dispatch => {
    dispatch({ type: MyProfileActionTypes.UPLOAD_PROFILE_AUDIO_START })
    UPLOAD_FROM_NODE({
      method: 'POST',
      url: `${API.UPLOAD_PROFILE_AUDIO}?user_id=${id}`,
      body: data,
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {

        dispatch({
          type: MyProfileActionTypes.UPLOAD_PROFILE_AUDIO_SUCCESS,
          payload: resData,
        });

        dispatch(getMyProfile({id: id,view: 1}))
      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: MyProfileActionTypes.UPLOAD_PROFILE_AUDIO_FAILURE });
      });
  }
}