import React, { useState } from "react";
import { connect } from "react-redux";
// import { createStructuredSelector } from 'reselect';
// import { selectCarousel, selectSubTitle } from '../../../redux/today-special/today-special.selector';

import FullScreenImage from "../../full-screen-image/full-screen-image.component";

import check from "../../assets/chat/check.svg";

import "./profile-item.styles.scss";

import About from "./about.component";
import Personal from "./personal.component";
import Family from "./family.component";
import Carousel from "./carousel.component";

const ProfileItem = ({ user, isContacted }) => {
  console.log("isContacted", isContacted);
  console.log("this is the user", user);
  const [modalShow, setModalShow] = useState({
    active: false,
    url: null,
  });
  const [active, setActive] = useState(0);
  const [menu] = useState(["About", "Personal", "Family"]);
  console.log("the user is ", user);
  const listenScrollElement = (e) => {
    let top = e.target.scrollTop;
    console.log(top);
    if (top >= 0 && top < 140 && active !== 0) {
      setActive(0);
    } else if (top >= 140 && top < 250 && active !== 1) {
      setActive(1);
    } else if (top >= 250 && active !== 2) setActive(2);
  };

  const handleClick = (i) => {
    const element = document.getElementById(menu[i]);

    element.scrollIntoView({ behavior: "smooth" });
  };
  // console.log(user)
  return (
    <div className="profile">
      <div className="profile-header">
        <Carousel
          heading={user.heading}
          urls={[user.carousel]}
          setModalShow={setModalShow}
        />
        <div className="profile-subTitle">
          <p style={{ fontWeight: "bolder" }}>
            {user.heading.title} <img src={check} alt="" />
          </p>
          <p>{user.heading.subTitle1}</p>
          <p>{user.heading.subTitle2}</p>
          <p>{user.heading.subTitle3}</p>
        </div>
      </div>
      <div className="profile-nav">
        {menu.map((item, i) => (
          <div
            className="profile-nav-items pl-1 pr-1"
            onClick={() => handleClick(i)}
            key={i}
          >
            <span
              className={`profile-nav-item ${
                active === i ? "profile-active" : ""
              }`}
            >
              {item}
            </span>
            {active === i && <hr className="active1" />}
          </div>
        ))}
      </div>
      <hr className="profile-hr" />
      <div
        onScroll={listenScrollElement}
        className="profile-details"
        id="profile-item"
      >
        <About {...user.about} />
        <Personal personal={user.personal} isContacted={isContacted} />
        <Family {...user.family} />
      </div>
      <FullScreenImage
        show={modalShow.active}
        url={modalShow.url}
        onHide={() => setModalShow({ active: false, url: null })}
      />
    </div>
  );
};

// const mapStateToProps = (props) => {
//   const carousel = selectCarousel();
//   const heading = selectSubTitle();
//   return createStructuredSelector({
//     carousel,
//     heading,
//   })
// }

export default connect(null, null)(ProfileItem);
