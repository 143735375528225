import { createSelector } from 'reselect';

import { PAYTM_DEV_KEY } from '../../utils/api';

const payment = state => state.payment;

export const selectPaymentPlans = createSelector(
  [payment],
  payment => payment.paymentPlans
)

export const selectShowPayment = createSelector(
  [payment],
  payment => payment.showPayment
)
export const selectPaytmOrder = createSelector(
  [payment],
  payment => ({
    orderId: payment.paytmOrder ? payment.paytmOrder.order_id : null,
    mid: PAYTM_DEV_KEY,
    txnToken: payment.paytmOrder ? JSON.parse(payment.paytmOrder.response).body.txnToken : null,
  })
)