import DiscoverActionTypes from "./discover.types"


const INITIAL_STATE = {
  profile: [],
  hasMore: true,
}

const discoverReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case DiscoverActionTypes.GET_DISCOVER_PREMIUM_PROFILE_SUCCUSS:
      return {
        ...state,
        profile: [...state.profile, ...action.payload],
      }
    case DiscoverActionTypes.GET_DISCOVER_PROFILE_SUCCUSS:
      return {
        ...state,
        profile: state.profile.length ? state.profile.concat(action.payload.data) : [ ...action.payload.data],
        hasMore: state.profile.length ? state.profile.length < action.payload.total_records ? true : false : true
      }
    default:
      return {
        ...state
      }
  }
}

export default discoverReducer