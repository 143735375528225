import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getTodayProfiles } from "../../redux/today-special/today-special.action";
import { selectIsLead, selectId } from "../../redux/auth/auth.selector";
import "./today-profile.styles.scss";
import ProfileOverview from "../profile-overview/profile-overview.component";
import { sectionTypes } from "../../utils/display.utils";
import {
  selectData,
  selectStatus,
} from "../../redux/today-special/today-special.selector";
import axios from "axios";
import CountdownTimer from "./CountdownTimer"; // Assuming you've created CountdownTimer in the same directory

const TodayProfile = ({ getTodayProfiles, id, status, data }) => {
  const [isData, setIsData] = useState([]);
  const [countdown, setCountdown] = useState("");
  const [message, setMessage] = useState("");

  const expire_at = useSelector((state) => state.today.expire_at);
  const expiryMessage = useSelector((state) => state.today.message);

  useEffect(() => {
    getTodayProfiles({ userId: `${id}`, pref_changed: "0" });
  }, [getTodayProfiles, id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          // `https://hansmatrimony.com/api/v1/getTodaysProfiles?userId=${id}&pref_changed=0`,
          `https://api.hansmatrimony.com/api/v1/getTodaysProfiles?userId=${id}&pref_changed=0`,

          {
            headers: {
              "X-HANS-KEY":
                "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJoYW5zIiwiaWF0IjoyMDIxMjQwNjAzNTk4NDgsInVzZXIiOnsibW9iaWxlIjoiOTcxOTcxODkwMCJ9LCJzdXBlciI6ImhhbnNub3cifQ.Li5DVVJv42fo7VynFjwyJWhFpDAcq4UGjpcpJ6JD4BwmwIhK_X6ZyGMAVnLGUMP0jsUYKkRarAFlgrZOhUPHiA", // Replace with your actual API key
            },
          }
        );

        setIsData(response.data.data);
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  const countdownIntervalRef = useRef();

  useEffect(() => {
    const calculateCountdown = () => {
      const now = new Date().getTime();
      const expireTime = new Date(expire_at).getTime();
      const istOffset = 5.5 * 60 * 60 * 1000;
      const distance = expireTime + istOffset - now;

      if (distance > 0) {
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setMessage(
          `New profiles will appear on ${formatDateToIST(expire_at)} in`
        );
        setCountdown(
          `${hours.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
        );
      } else {
        clearInterval(countdownIntervalRef.current);
        setCountdown("");
        setMessage("");
      }
    };

    calculateCountdown();

    const countdownInterval = setInterval(calculateCountdown, 1000);
    countdownIntervalRef.current = countdownInterval;

    return () => {
      clearInterval(countdownIntervalRef.current);
    };
  }, [expire_at]);

  const formatDateToIST = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "Asia/Kolkata",
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <div className="today-special animate__animated animate__slideInLeft">
      <div className="container">
        {expiryMessage === "we already shared profiles with u" && message && (
          <p>
            {message}{" "}
            <CountdownTimer expireTime={new Date(expire_at).getTime()} />
          </p>
        )}

        {!status ? (
          <div className="no-profiles">
            {/* Render your no-profiles content */}
          </div>
        ) : (
          <ProfileOverview section={sectionTypes.TODAY_SPECIAL} items={data} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  is_lead: selectIsLead,
  id: selectId,
  status: selectStatus,
  data: selectData,
});

const mapDispatchToProps = (dispatch) => ({
  getTodayProfiles: (data) => dispatch(getTodayProfiles(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TodayProfile);
