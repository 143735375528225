import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import LocationSelect from "../../../components/forms-elements/location-select/location-select";
import {
  selectEducationMapping,
  selectUserOccupationMapping,
} from "../../../redux/mappedData/mappedData.selector";
import { updatePersonalDetails } from "../../../redux/my-profile/my-profile.action";
import {
  getDegreeInt,
  getUserOccupationInt,
} from "../../../utils/display.utils";

function EducationCareerForm({
  close,
  personalData,
  educationList,
  updatePersonalDetails,
  submitForm,
  occupationList,
}) {
  const [formDetails, setFormDetails] = useState({
    education: personalData.degree,
    educationCode_user: getDegreeInt(personalData.degree, educationList),
    college: personalData.college,
    user_working_city: "",
    occupation: personalData.occupation,
    occupationCode_user: getUserOccupationInt(
      personalData.occupation,
      occupationList
    ),
    company: personalData.company,
    annual_income: personalData.income,
    additional_qualification: personalData.additional,
  });

  useEffect(() => {
    if (submitForm) {
      updatePersonalDetails(formDetails);
      close();
    }
  }, [submitForm]);

  const handleEducationChange = (e, value) => {
    if (value) {
      setFormDetails({
        ...formDetails,
        education: value,
        educationCode_user: getDegreeInt(value, educationList),
      });
    }
  };

  const handleOccupationChange = (e, value) => {
    if (value) {
      setFormDetails({
        ...formDetails,
        occupation: value,
        occupationCode_user: getUserOccupationInt(value, occupationList),
      });
    }
  };

  const handleTextFields = (e) => {
    if (e.target.name) {
      setFormDetails({
        ...formDetails,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleWorkingChange = (e, value) => {
    if (value) {
      setFormDetails({
        ...formDetails,
        working_city: value,
      });
    }
  };

  return (
    <form className="education-career-form-container">
      <Autocomplete
        className="input-field mt-3 w-100"
        disablePortal
        id="education"
        options={educationList.map((it) => it.degree_name)}
        name="education"
        sx={{ width: 300 }}
        defaultValue={formDetails.education}
        onChange={handleEducationChange}
        renderInput={(params) => (
          <TextField {...params} label="Qualification/Degree" />
        )}
      />

      <TextField
        label="Last College"
        className="input-field w-100 mt-3"
        variant="outlined"
        value={formDetails.college}
        type="text"
        onChange={handleTextFields}
        name="last_college"
      />

      <TextField
        label="Additional Qualification"
        className="input-field w-100 mt-3"
        variant="outlined"
        value={formDetails.additional_qualification}
        type="text"
        onChange={handleTextFields}
        name="additional_qualification"
      />

      <div className="filter-field">
        <LocationSelect
          value={formDetails.user_working_city}
          handler={handleWorkingChange}
          styles="input-field"
          placeholder="Working City"
        />
      </div>

      <Autocomplete
        className="input-field mt-3 w-100"
        disablePortal
        id="occupation"
        options={occupationList.map((it) => it.name)}
        name="occupation"
        sx={{ width: 300 }}
        defaultValue={formDetails.occupation}
        onChange={handleOccupationChange}
        renderInput={(params) => <TextField {...params} label="Occupation" />}
      />

      <div className="filter-field">
        <TextField
          label="Company"
          className="input-field"
          variant="outlined"
          value={formDetails.company}
          type="text"
          onChange={handleTextFields}
          name="company"
        />

        <TextField
          label="Annual Income (In LPA)"
          className="input-field ml-3"
          variant="outlined"
          value={formDetails.annual_income}
          type="number"
          onChange={handleTextFields}
          name="annual_income"
        />
      </div>
    </form>
  );
}

const mapStateToProps = createStructuredSelector({
  educationList: selectEducationMapping,
  occupationList: selectUserOccupationMapping,
});

const mapDispatchToProps = (dispatch) => ({
  updatePersonalDetails: (data) => dispatch(updatePersonalDetails(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EducationCareerForm);
