import TodaySpecialActionTypes from './today-special.types';

const INITITAL_STATE = {
  status: null,
  is_rated: null,
  profile_created: null,
  data: [],
  first_time: null,
  get_status_count: null,
  profile_kundali: null,
  
}

const todaySpecialReducer = (state = INITITAL_STATE, action) => {
  switch (action.type) {
    case TodaySpecialActionTypes.GET_TODAY_PROFILE_START:
      return {
        ...state,
      }
    case TodaySpecialActionTypes.GET_TODAY_PROFILE_SUCCESS:
      return {
        ...state,
       
        ...action.payload
      }
    case TodaySpecialActionTypes.GET_TODAY_PROFILE_FAILURE:
      return {
        ...state,
      }

      case TodaySpecialActionTypes.GET_KUNDALI_SUCCESS: 
      return {
        ...state,
        profile_kundali: action.payload
      }

      case TodaySpecialActionTypes.RESET_KUNDLI:
        return {
          ...state,
          profile_kundali: null
        }

      case TodaySpecialActionTypes.REMOVE_AND_UPDATE_ITEM_FROM_TODAY_SPECIAL_LIST:
        let itemIndex = state.data.findIndex(p => p.id === action.payload)
        let list = [...state.data]
        list.splice(itemIndex,1)
  
        return {
          ...state,
          data: list
        }

    default:
      return {
        ...state,
      }
  }
}

export default todaySpecialReducer;