import RegisterActionTypes from "./register.form.two.types"


const INITIAL_STATE = {
  birthPlace: [],
  birthTime :["00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"],
  city: [],
  foodChoice : ['Vegetarian','Non-Vegetarian','No-Preference'],
  manglik: ['YES','NO'],
  gender: ['Male', 'Female'],
  lastCollege: [],
  fatherStatus: ['Dead', 'Alive'],
  date: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
  month: ['January', 'Feburary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  livingWithParents: ['Yes','No']
  ,
  designation: [
    'Owner',
    'Manager',
    'Sales Manager',
    'Accounts Manager',
    'Product Manager',
    'Software Engineer',
    'Engineer',
    'Hotel Management',
    'Operations Manager',
    'Sales Executive',
    'Operations Executive',
    'Accountant',
    'Marketing Manager',
    'Marketing Executive',
    'Chartered Accountant',
    'Owner',
    'Secretary',
    'Company Secretary',
    'Telesales Executive',
    'Teacher',
    'Clerk',
    'Office Assistant',
    'Relationship Manager',
    'Computer Operator',
    'Chief Executive Officer',
    'Chief Marketing Officer',
    'Chief Finance Officer',
    'Business Development',
    'Project Manager',
    'Program Manager',
    'Solution Architect',
    'Graphic Designer',
    'Content Writer',
    'Director',
    'Business Analyst',
    'Front Office',
    'Back office',
    'Counselor',
    'Event Manager',
    'Legal',
    'Public Relations',
    'Others'],
    familyType :['Joint','Nuclear'],
    HouseType : ['Owned','Rented'],
  state: [],
}

const registerReducerTwo = (state = INITIAL_STATE, action) => {

  switch (action.type) {

    // case RegisterActionTypes.GET_ALL_CASTE_SUCCESS:
    //   return {
    //     ...state,
    //     allCaste: action.payload,
    //   }
    case RegisterActionTypes.GET_CITY:
      return {
        ...state,
        city: action.payload
      }
    case RegisterActionTypes.GET_STATE:
      return {
        ...state,
        state: action.payload
      }
    default:
      return {
        ...state
      }
  }
}

export default registerReducerTwo