import LanguageActionTypes from './language.types';
import { hindi, english } from './data';

const INITIAL_STATE = {
  isEnglish: true,
  hindi,
  english,
}

const languageReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

    case LanguageActionTypes.CHANGE_LANGUAGE:
      return {
        ...state,
        isEnglish: !state.isEnglish
      }

    default:
      return {
        ...state
      }
  }
}

export default languageReducer;