import { Height } from '@mui/icons-material';
import { IMAGE_URL_PREFIX } from './api';

const Heights = ['4.0"', '4.1"', '4.2"', '4.3"', '4.4"', '4.5"', '4.6"', '4.7"', '4.8"', '4.9"', '4.10"', '4.11"', '5.0"', '5.1"', '5.2"', '5.3"', '5.4"', '5.5"', '5.6"', '5.7"', '5.8"', '5.9"', '5.10"', '5.11"', '6.0"', '6.1"', '6.2"', '6.3"', '6.4"', '6.5"', '6.6"', '6.7"', '6.8"', '6.9"', '6.10"', '6.11"', '7.0"'];

const Heights1 = ['48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '80', '81', '82', '83', '84'];

const HeightString = ['4 feet 0 inches', '4 feet 1 inches', '4 feet 2 inches', '4 feet 3 inches', '4 feet 4 inches', '4 feet 5 inches', '4 feet 6 inches', '4 feet 7 inches', '4 feet 8 inches', '4 feet 9 inches', '4 feet 10 inches', '4 feet 11 inches', '5 feet 0 inches', '5 feet 1 inches', '5 feet 2 inches', '5 feet 3 inches', '5 feet 4 inches', '5 feet 5 inches', '5 feet 6 inches', '5 feet 7 inches', '5 feet 8 inches', '5 feet 9 inches', '5 feet 10 inches', '5 feet 11 inches', '6 feet 0 inches', '6 feet 1 inches', '6 feet 2 inches', '6 feet 3 inches', '6 feet 4 inches', '6 feet 5 inches', '6 feet 6 inches', '6 feet 7 inches', '6 feet 8 inches', '6 feet 9 inches', '6 feet 10 inches', '6 feet 11 inches', '7 feet 0 inches'];

const designation = [
  'Owner',
  'Manager',
  'Sales Manager',
  'Accounts Manager',
  'Product Manager',
  'Software Engineer',
  'Engineer',
  'Hotel Management',
  'Operations Manager',
  'Sales Executive',
  'Operations Executive',
  'Accountant',
  'Marketing Manager',
  'Marketing Executive',
  'Chartered Accountant',
  'Owner',
  'Secretary',
  'Company Secretary',
  'Telesales Executive',
  'Teacher',
  'Clerk',
  'Office Assistant',
  'Relationship Manager',
  'Computer Operator',
  'Chief Executive Officer',
  'Chief Marketing Officer',
  'Chief Finance Officer',
  'Business Development',
  'Project Manager',
  'Program Manager',
  'Solution Architect',
  'Graphic Designer',
  'Content Writer',
  'Director',
  'Business Analyst',
  'Front Office',
  'Back office',
  'Counselor',
  'Event Manager',
  'Legal',
  'Public Relations',
  'Others']


export const sectionTypes = {
  TODAY_SPECIAL: "TODAY_SPECIAL",
  SEE_MORE: 'SEE_MORE',
  PROFILES: 'PROFILES',
  POPULAR: 'POPULAR',
  NEWLY_JOINED: 'NEWLY_JOINED',
  LIKED_ME: 'LIKED_ME',
  VIEWED_ME: 'VIEWED_ME',
  HISTORY_REJECT: 'HISTORY_REJECT',
  HISTORY_CONTACT: 'HISTORY_CONTACT',
  HISTORY_LIKE: 'HISTORY_LIKE',
  CONTACTED: 'CONTACTED'
}

export const buttonActionTypes = {
  CALL: 'CALL',
  WHATSAPP: 'WHATSAPP',
  CONTACTEDWHATSAPP: 'CONTACTEDWHATSAPP',
  LIKED: 'LIKED',
  REJECTED: 'REJECTED',
  CONTACTED: 'CONTACTED',
  CONTACTEDCHAT: 'CONTACTEDCHAT',

}

export const personalFormTypes = {
  PERSONAL_DETAILS: 'PERSONAL_DETAILS',
  ABOUT: 'ABOUT',
  EDUCATION_AND_CAREER: 'EDUCATION_AND_CAREER',
  HOROSCOPE: 'HOROSCOPE',
  CONTACT: 'CONTACT',
}

export const setVerifiedAt = (a) => {
  if (a.includes('Hans Matrimony')) {
    return 'Hans Matrimony Office';
  }
  else {
    return a;
  }
}
export const toTitleCase = (str) => {
  if (str) {
    return str.replace(
      /\w\S*/g,
      (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  } else {
    return '';
  }
}
// setting dynamic about me if users about me is null or na
export const setAbout = (item, casteList, manglikList, degreeList, occupationList) => {
  if (item) {
    const aboutObject = {
      dob: item.birth_date ? `I am ${setAge(item.birth_date)} old ` : '',
      caste: item.caste_id ? getCasteString(item.caste_id, casteList) : '',
      manglik: item.manglik ? getManglikString(item.manglik, manglikList) : '',
      gender: item.gender ? item.gender === 'Male' ? 'man' : 'woman' : '',
      locality: item.locality ? item.locality === 'Visible after Contact' ?
        '' : ` residing in ${item.locality}` : '',
      qualification: item.education ?
        `. I've completed my ${getDegreeString(item.education, degreeList)}` : item.degree ?
          `. I've completed my ${getDegreeString(item.degree, degreeList)}` : '',
      occupation: item.occupation ?
        item.occupation === 'Business/Self-Employed' ?
          ' and Self-Employed' : item.occupation === 'Not Working' ? 'currently not working'
            : item.occupation === 'Doctor' ||
              item.occupation === 'Teacher'
              ? ` currently working as ${getUserOccupationString(item.occupation, occupationList)}` :
              ` currently working in ${getUserOccupationString(item.occupation, occupationList)}` : '',
      working: item.working_city ? item.working_city !== 'Not Working'
        ? item.working_city !== 'na' ? `in ${item.working_city}` : '' : '' : '',
      designation: item.profession ?
        item.occupation !== 'Not Working' ?
          item.profession !== 'n/a' ? item.profession !== 'na' ?
            ` as ${getProfessionString(item.profession)}` : '' : '' : '' : '',
    };
    // tslint:disable-next-line: max-line-length
    return `${aboutObject.dob} ${aboutObject.caste} ${aboutObject.manglik} ${aboutObject.gender} ${aboutObject.locality} ${aboutObject.qualification} ${aboutObject.occupation} ${aboutObject.designation} ${aboutObject.working}.`;
  }
}

export const setIncome = (value) => {
  if (value != null) {
    if (Number(value) > 1000) {
      return String((Number(value) / 1));
    } else {
      return value;
    }

  } else {
    return '';
  }
}
export const setManglik = (value) => {
  if (value === 'No') {
    return 'Non Manglik';
  } else {
    return value;
  }
}
export const setMarriageBrothers = (value1, value2) => {
  if (value1 != null && value1 !== '' && value1 !== 0) {
    if (value2 != null && value2 !== '' && value2 !== 0) {
      return String(Number(value1) + Number(value2)) + '| ' + value1 + ' Married';
    } else {
      return String(Number(value1) + Number(value2)) + ' Brothers';
    }
  } else {
    if (value2 != null && value2 !== '' && value2 !== 0) {
      return String(Number(value1) + Number(value2)) + ' Brothers';
    } else {
      return 'No Brothers';
    }
  }
}
export const setMarriageSisters = (value1, value2) => {
  if (value1 != null && value1 !== '' && value1 !== 0) {
    if (value2 != null && value2 !== '' && value2 !== 0) {
      return String(Number(value1) + Number(value2)) + '| ' + value1 + ' Married';
    } else {
      return String(Number(value1) + Number(value2)) + ' Sisters';
    }
  } else {
    if (value2 != null && value2 !== '' && value2 !== 0) {
      return String(Number(value1) + Number(value2)) + ' Sisters';
    } else {
      return 'No Sisters';
    }
  }
}
export const LifeStatus = (person, work, type) => {
  if (person != null && person !== '') {
    if (convertLifeStatusToString(person).match('Alive')) {
      if (work) {
        return ` Alive | ` + work;
      } else {
        return "Alive";
      }
    } else {
      return `Not alive`;
    }
  }
}

const convertLifeStatusToString = (statusInInteger) => {
  switch (statusInInteger) {
    case 1:
      return "Alive"

    default:
      return "Not Alive"
  }
}

export const houseStatus = (item) => {
  if (item.house_type && item.house_type === 1) {
    return 'Owned';
  } else {
    return 'Rented';
  }
}
export const familyType = (item) => {
  if (item.family_type && item.family_type === 1) {
    return 'Nuclear';
  } else {
    return 'Joint';
  }
}
export const setHeight = (height) => {
  if (height && height !== '') {
    return Heights[Heights1.indexOf(String(height))];
  } else {
    return '';
  }
}
export const setHeightInt = (height) => {
  if (height) {
    return parseInt(Heights1[HeightString.indexOf(String(height))]);
  } else {
    return '';
  }
}

export const setHeightInt2 = (height) => {
  if (height) {
    return parseInt(Heights1[Heights.indexOf(String(height))]);
  } else {
    return '';
  }
}

export const setHeightInString = (height) => {
  if (height && height !== '') {
    return HeightString[Heights1.indexOf(String(height))];
  } else {
    return '';
  }
}


export const setName = (name, isPremium) => {
  if (!isPremium && name && name.includes(' ')) {
    let a = name.split(' ');
    if (a[0] && a[1]) {
      return a[0][0] + ' ' + a[1];
    } else if (a[0]) {
      return a[0][0];
    }
  } else {
    return name
  }
}
export const setAge = (birthDate) => {
  if (birthDate != null) {
    return `${Math.abs(new Date(Date.now() - new Date(birthDate.birth_date ?? birthDate).getTime()).getUTCFullYear() - 1970)}` + ' Yrs';
  } else {
    return '';
  }
}

export const getHeading = (item) => ({
  title: `${setName(item.name)}, ${item.age}`,
  subTitle1: `${item.religion} | ${item.caste}`,
  subTitle2: `${item.working_city} | ${setHeight(item.height)}`,
  subTitle3: `${item.monthly_income} Lakh Per Year`,
  photo: item.photo,
  userId: item.id,
  mobile: item.user_mobile
})

export const convertItem = item => {
  console.log("convertItem item", item)


  return {
    carousel: item.user_photos[0].photo_url,
    // photo: item.user_photos[0].photo_url,
    mobile: item.user_mobile,
    about: {
      verifiedAt: item.verified_by ? item.verified_by : 'Hans Matrimony Office',
      managedBy: item.relation ? item.relation : item.gender === 'Male' ? 'Mother' : 'Father',
      about: item.about ? item.about.length < 30 ? toTitleCase(setAbout(item)) : toTitleCase(item.about) : toTitleCase(setAbout(item)),

    },
    personal: {
      mobile: item.user_mobile ?? item.mobile_family,
      locality: item.locality ? item.locality : item.city ? item.city : '',
      religion: `${item.religion} ${item.religion ? '|' : ''} ${item.caste}`,
      maritalStatus: item.marital_status,
      foodChoice: item.food_choice,
      weight: `${item.weight} Kg`,
      children: item.children,
      occupation: item.occupation,
      profession: item.profession,
      monthlyIncome: `${setIncome(item.monthly_income)} Lakh per year`,
      education: item.degree ? item.degree : item.education,
      college: toTitleCase(item.college),
      workingCity: item.working_city,
      birthDate: item.birth_date,
      birthTime: item.birth_time,
      birthPlace: item.birth_place,
      manglik: setManglik(item.manglik),

    },
    family: {
      sister: setMarriageSisters(item.unmarried_sisters, item.married_sisters),
      brother: setMarriageBrothers(item.unmarried_brothers, item.married_brothers),
      mother: LifeStatus(item.mother_status, item.occupation_mother, 'Mother'),
      father: LifeStatus(item.father_status, item.occupation_father, 'Father'),
      gotra: item.gotra,
      familyType: item.family_type,
      familyLivingIn: item.city ? item.city : item.locality ? item.locality : '',
      houseStatus: item.house_type,
      familyIncome: setIncome(item.family_income),
      livingWithParents: item.livingWithParents
    },
    heading: {
      title: `${setName(toTitleCase(item.name), item.is_free_profile)}, ${getAge(item.birth_date)}`,
      subTitle1: `${item.religion} ${item.religion ? '|' : ''} ${item.caste}`,
      subTitle2: `${item.working_city} | ${setHeight(item.height)}
        `,
      subTitle3: `${item.occupation} | ${setIncome(item.monthly_income)} Lpa`,
    },
    userId: item.id
  }
}

const getAge = (date) => {
  //birth_date => 1989-06-10 yyyy-mm-dd
  return Math.abs(new Date(Date.now() - new Date(date).getTime()).getUTCFullYear() - 1970)
}

export const getCasteString = (casteId, casteList) => {
  if (!casteList || !casteId)
    return ''

  const casteString = casteList.find(
    item => item.id === casteId
  )

  return casteString ? casteString.value : ''
}

export const getCasteId = (caste, casteList) => {
  if (!casteList || !caste)
    return ''

  const casteString = casteList.find(
    item => item.value === caste
  )

  return casteString ? casteString.id : 0
}

export const getReligionString = (religionId, religionList) => {

  if (!religionId || !religionList?.length) {
    return ''
  }


  let religionString = []
  religionList.forEach(item => {
    if (item.mapping_id === religionId) {
      religionString.push(item.religion)
    }
  }
  )

  return religionString.join(", ")
}

export const getReligionInt = (religionId, religionList) => {

  if (!religionId || !religionList?.length) {
    return 0
  }


  let religionInt = []
  religionList.forEach(item => {
    if (item.religion === religionId) {
      religionInt.push(item.mapping_id)
    }
  }
  )

  return religionInt
}

export const getFoodChoiceString = (foodChoiceId, foodChoiceList) => {
  if (foodChoiceId == null || !foodChoiceList)
    return ''

  const foodChoiceString = foodChoiceList.find(
    item => item.mapping_id === foodChoiceId
  )
  return foodChoiceString ? foodChoiceString.name : ''
}

export const getFoodChoiceInt = (foodChoice, foodChoiceList) => {
  if (foodChoice == null || !foodChoice)
    return 0

  const foodChoiceString = foodChoiceList.find(
    item => item.name === foodChoice
  )
  return foodChoiceString ? foodChoiceString.mapping_id : 0
}

export const getMaritalString = (maritalId, maritalList) => {
  if (!maritalId || !maritalList)
    return ''

  const maritalString = maritalList.find(
    item => item.marital_status_id === maritalId
  )
  return maritalString ? maritalString.name : ''
}

export const getMaritalInt = (maritalId, maritalList) => {
  if (!maritalId || !maritalList)
    return 0

  const maritalString = maritalList.find(
    item => item.name === maritalId
  )
  return maritalString ? maritalString.marital_status_id : 0
}

export const getManglikString = (manglikId, manglikList) => {
  if (!manglikId || !manglikList)
    return ''

  const manglikString = manglikList.find(
    item => item.mapping_id === manglikId
  )
  return manglikString ? manglikString.name : ''
}

export const getManglikInt = (manglikId, manglikList) => {
  if (!manglikId || !manglikList)
    return ''

  const manglikString = manglikList.find(
    item => item.name === manglikId
  )
  return manglikString.mapping_id ?? 0
}

export const getDegreeString = (degreeId, degreeList) => {
  if (!degreeId || !degreeList)
    return ''

  const degreeString = degreeList.find(
    item => item.id === degreeId
  )
  return degreeString ? degreeString.degree_name : ''
}

export const getDegreeInt = (degreeId, degreeList) => {
  if (!degreeId || !degreeList)
    return 0

  const degreeString = degreeList.find(
    item => item.degree_name === degreeId
  )
  return degreeString ? degreeString.id : 0
}

export const getUserOccupationString = (occupationId, occupationList) => {
  if (!occupationId || !occupationList)
    return ''

  const occupationString = occupationList.find(
    item => item.mapping_id === occupationId
  )
  return occupationString ? occupationString.degree_name : ''
}

export const getUserOccupationInt = (occupationId, occupationList) => {
  if (!occupationId || !occupationList)
    return 0

  const occupationString = occupationList.find(
    item => item.name === occupationId
  )
  return occupationString ? occupationString.mapping_id : 0
}

export const getProfessionString = (professionId) => {
  if (!professionId)
    return ''

  const occupationString = designation[professionId]

  return occupationString ? occupationString : ''
}

