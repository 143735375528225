import React, { useState,useEffect }  from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom';
import TodayProfile from '../today-profile/today-profile.component';
import Popular from '../popular/popular.component';
import NewlyJoined from '../newly-joined/newly-joined.component';
import './profiles.styles.scss';

function Profiles() {
    const location = useLocation()

    
    const TabTypes = {
        TODAY_SPECIAL: 'TODAY_SPECIAL',
        POPULAR: 'POPULAR',
        NEWLY_JOINED: 'NEWLY_JOINED',
    }

    const [active,setActive] = useState(TabTypes.TODAY_SPECIAL)

    useEffect(() => {
        document.getElementById('profiles-pages').scrollTo(0,0)
    },[active])

    useEffect(() =>{
        if (location.pathname.includes("new")) {
                setActive(TabTypes.NEWLY_JOINED)
        } else if(location.pathname.includes("popular")) {
            setActive(TabTypes.POPULAR)
        } else {
            if (active !== TabTypes.TODAY_SPECIAL) {
                setActive(TabTypes.TODAY_SPECIAL)
            }
        }
    },[location.pathname])

    function handleActive(e) {
            setActive(e.target.id)
    }

  return (
    <div className="profiles-container">
        <div className='profiles-tab-container'>
        <Link to="" 
        id={TabTypes.TODAY_SPECIAL}
        className={`profiles-nav-items ${active === TabTypes.TODAY_SPECIAL ? 'active' : ''}`}
        onClick={handleActive}>
            Today's Special
        </Link>

        <Link to="popular" 
        id={TabTypes.POPULAR}
        className={`profiles-nav-items ${active === TabTypes.POPULAR ? 'active' : ''}`}
        onClick={handleActive}>
            Popular
        </Link>

        <Link to="new" 
        id={TabTypes.NEWLY_JOINED}
        className={`profiles-nav-items ${active === TabTypes.NEWLY_JOINED ? 'active' : ''}`}
        onClick={handleActive}>
            Newly Joined
        </Link>
        </div>

        <div className='mt-3 profiles-pages' id='profiles-pages'>
        <Outlet />
        </div>
    </div>
  )
}

export default Profiles