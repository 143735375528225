import { createSelector } from "reselect";

export const auth = state => state.auth;

export const selectIsAuth = createSelector(
  [auth],
  auth => auth.isAuth
)
export const selectAuthLoading = createSelector(
  [auth],
  auth => auth.authLoading
)
export const selectIsLead = createSelector(
  [auth],
  auth => auth.is_lead
)
export const selectHeader = createSelector(
  [auth],
  auth => auth.header
)
export const selectId = createSelector(
  [auth],
  auth => auth.userId
)
export const selectMobile = createSelector(
  [auth],
  auth => auth.mobile ?? auth.auth.user_mobile
)
export const selectShowOtp = createSelector(
  [auth],
  auth => auth.showOtp
)
export const selectPhotoUrl = createSelector(
  [auth],
  auth => auth.photoUrl
)
export const selectName = createSelector(
  [auth],
  auth => auth.name
)
export const selectGender = createSelector(
  [auth],
  auth => auth.gender ? auth.gender === 1 ? 'Male' : 'Female' : 'NA'
)
export const selectCredits = createSelector(
  [auth],
  auth => auth.credits
)
export const selectCount = createSelector(
  [auth],
  auth => auth.count ? auth.count : ({ TPL: '0', VPL: '0', SL: '0', S: '0', C: '0', R: '0' })
)

export const selectIsPremium = createSelector(
  [auth],
  auth => auth.auth.is_premium === 1 ? true : false
)