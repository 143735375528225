
import React from 'react';


const IsDisplay = ({ image, title }) => {

  if (!title || title === 'NA' || title === 'na' || title === 'NaN' ||
    title === 'Nan' || title === 'nan' || title === 'N.A' ||
    title === 'null' || title === 'Null' || title === 'Na'
  ) return <div></div>;

  return (
    <div className="col-6 profile-col-6 profile-field">
      <img className="profile-titleImage" src={image} alt="location" />
      <span
        className="profile-title">{title}</span>
    </div>
  )
}

export default IsDisplay;