
import { useState } from 'react';

const useFormInput = (initialValue) => {

  const [state, setState] = useState(initialValue);

  const handleChange = (e, v, target) => {
    if (e && e.target) {
      console.log(e.target.name, e.target.value, v, target);
      if (target) {
        // autocomplete entry
        setState(prevState => ({
          ...prevState,
          [target]: v.inputValue ?? v
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          [e.target.name]: e.target.value
        }));
      }
    }
  };
  

  const handleSubmit = (e) => {
    if(e)
    e.preventDefault();

  }

  const reset = intialValue => setState(intialValue)

  const handleUpdate = (name, value) => {
    setState(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  return [state, handleChange, handleSubmit, reset, handleUpdate]

}

export default useFormInput;