import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Modal from "react-modal";

import { selectIsAuth } from "../../redux/auth/auth.selector";

import logoOld from "../../components/assets/hero/logoOld.png";
import text from "../../components/assets/hero/text.svg";
import googleplay from "../../components/assets/newAppIllustrations/googleplay.jpg";
import sidemenubtn from "../../components/assets/hero/mobile-menu-side-opener-btn.svg";
import navbarimage from "../../components/assets/hero/navbarimage.svg";
import logo1 from "../../components/assets/logo1.png";
import heroimage2 from "../../components/assets/hero/heroimage2.png";
import illsvg1 from "../../components/assets/hero/illsvg1.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./landing-page.styles.scss";
import VideoCarousel from "./video-carousel/video-carousel";
import ToggleButton from "./togglebutton";
import Drawer from "./drawer/drawer.component";
import Hero from "./hero/hero.component";
import Feature from "./features/features.component";
import HowItWork from "./how-it-work/how-it-work.component";
import OfflineCenter from "./offline-center/offline-center.component";
import MatchMaker from "./match-makers/match-makers.component";
import Footer from "./footer/footer.component";
import Testimonials from "./testimonials/testimonials.component";
import FreeKundaliMatching from "./free-kundali-matching/free-kundali-matching.component";
import Sidebar from "./drawer/sidebar";
import VideoCarouselTestimonial from "./video-carousel/video-carouselTestimonial";
import App2 from "../subscription/waste";
import axios from "axios";
import { Nav } from "react-bootstrap";
import zIndex from "@mui/material/styles/zIndex";

const LandingPage = ({ isAuth }) => {
  let location = "kundali";
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const phoneNum = "+919697989697"; // Your phone number here
  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#white",
    border: "none",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 1)",
    maxWidth: "400px",
    width: "100%",
    textAlign: "center",
    zIndex: "9999999",
  };

  const overlayStyle = {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "9999999",
  };
  const openModal = () => {
    console.log("inside openModal");
    setModalIsOpen(true);
    console.log(modalIsOpen);
  };
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [lookingFor, setLookingFor] = useState("Myself");
  //

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const [registerModalIsOpen, setRegisterModalIsOpen] = useState(false);

  const openRegisterModal = () => {
    setRegisterModalIsOpen(true);
  };

  const closeRegisterModal = () => {
    setRegisterModalIsOpen(false);
  };

  const [sidebar, setSidebar] = useState(false);
  const [navbar, setNavbar] = useState(false);

  // const changeBackground  = () => {
  //   if (window.scrollY >= 120 )
  //   {
  //     setNavbar(true)
  //   }
  //   else {
  //     setNavbar(false)
  //   }
  // };

  const showSidebar = (e) => {
    console.log("hamburger clicked");
    e.preventDefault();
    sidebar === true ? setSidebar(false) : setSidebar(true);
  };

  // window.addEventListener("scroll", changeBackground);

  const [auth, setAuth] = useState();
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!fullName || !phoneNumber || !lookingFor) {
      alert("Please fill in all fields.");
      return;
    }
    try {
      const response = await fetch(
        "https://partner.hansmatrimony.com/api/save-incomplete-leads",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_phone: phoneNumber,
            for: lookingFor,
            cname: fullName,
            channel: "google_ads",
          }),
        }
      );

      if (response.ok) {
        console.log("Lead saved successfully");
        toast.success(
          "Your data has been recieved our team will contact you soon!"
        );
        setFullName("");
        setPhoneNumber("");
        setLookingFor("myself");
        closeRegisterModal();
      } else {
        console.error("Failed to save lead");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(`${location.hash}`);
      if (!element) {
        return;
      }
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);
  return (
    <>
      <Modal
        isOpen={registerModalIsOpen}
        onRequestClose={closeRegisterModal}
        contentLabel="Register Modal"
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999999999, // Ensure the modal appears on top of other content
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#ffffff",
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: "20px",
            maxWidth: "500px",
            height: "380px",
            width: "100%", // Adjust the width as needed
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
            zIndex: 999999999, // Ensure the modal content appears on top of the overlay
          },
        }}
      >
        <form
          onSubmit={handleFormSubmit}
          style={{ zIndex: "999999999999", height: "100%", width: "100%" }}
        >
          {/* <div className="form-heading"> */}
          <h6 className="Heading">Upper/Middle Class Family’s Rishtey!</h6>
          {/* </div> */}
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter your name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone:</label>
            <input
              type="text"
              className="form-control"
              id="phone"
              placeholder="Enter your phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="select">Looking For:</label>

            <select
              className="form-control"
              id="select"
              onChange={(e) => setLookingFor(e.target.value)}
            >
              <option value="myself">Myself</option>
              <option value="son">Son</option>
              <option value="daughter">Daughter</option>
              <option value="brother">Brother</option>
              <option value="sister">Sister</option>
              <option value="other">Other</option>
            </select>
          </div>
          <button type="submit" className="btn btn-primary">
            Register Now
          </button>
        </form>
      </Modal>
      <div className="mat-sidenav-container">
        <div>
          <nav className={navbar ? "navbar colorChange" : "originalColor"}>
            <div className="land-nav-hamburger">
              <nav className="Nav">
                <div className="land-logo-holder">
                  <a href="/">
                    <img
                      className="land-logo-img-fluid"
                      src={logo1}
                      alt="hansmatrimony-logo"
                      style={{ width: "130px", height: "43px" }}
                    />
                  </a>

                  {/* <div className="d-flex"> */}
                  <ul className="land-nav-links">
                    {/* <ul > */}
                    <li>
                      <a
                        mdbPageScroll
                        href="/"
                        style={{ fontWeight: "bold", color: "#265682" }}
                      >
                        Home
                      </a>
                    </li>
                    <li>
                      <Link
                        to="/subscription"
                        routerLinkActive="router-link-active"
                      >
                        Subscription
                      </Link>
                    </li>
                    <li>
                      <a onClick={openModal}>Contact Us</a>
                    </li>
                    {/* {isAuth ? (
                    <li>
                      <Link
                        to="/chat"
                        routerLinkActive="router-link-active"
                        href=""
                      >
                        See Profiles
                      </Link>
                    </li> */}
                    {/* ) : */}
                    {/* ( */}
                    <li>
                      <Link
                        // to="/phone-number"
                        onClick={openRegisterModal}
                        routerLinkActive="router-link-active"
                      >
                        Login/Register
                      </Link>
                    </li>
                    {/* )} */}
                  </ul>
                </div>
                <button onClick={showSidebar} className="land-hamburger1">
                  <div className="land-line1"></div>
                  <div className="land-line1"></div>
                  <div className="land-line1"></div>
                  {sidebar && <Sidebar sidebar={true} />}
                </button>
                {/* </div> */}
              </nav>
            </div>
          </nav>
          <div>
            {/* <App2 /> */}
            <Hero />
            <Feature />
            <OfflineCenter />
            <HowItWork />
            <MatchMaker />
            <FreeKundaliMatching />
            <VideoCarousel />
            {/* <Testimonials /> */}
            <VideoCarouselTestimonial />
            <Footer />
            <ToggleButton />
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Call Modal"
        style={{
          content: modalStyle,
          overlay: overlayStyle,
        }}
      >
        <h2 style={{}}>Please give us a call at:</h2>
        <p style={{ fontSize: "20px" }}>{phoneNum}</p>
        <button
          onClick={closeModal}
          style={{
            backgroundColor: "#FF9A9E",
            border: "none",
            borderRadius: "5px",
            color: "black",
            width: "100px",
            marginTop: "20px",
          }}
        >
          Close
        </button>
      </Modal>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  isAuth: selectIsAuth,
});

export default connect(mapStateToProps, null)(LandingPage);
