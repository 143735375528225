import InterestedActionTypes from "./interested.types";


const INITIAL_STATE = {
  viewed: [],
  liked: [],
}

const interestedReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

    case InterestedActionTypes.GET_VIEWED_ME_SUCCESS:
      return {
        ...state,
        [action.payload.name]: action.payload.data
      }
    case InterestedActionTypes.GET_LIKED_ME_SUCCESS:
      return {
        ...state,
        [action.payload.name]: action.payload.data
      }
    default:
      return {
        ...state
      }
  }
}

export default interestedReducer;