import React from 'react';

import './match-makers.styles.scss';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import image1 from "../../../components/assets/carousel/image1.png";
import image2 from "../../../components/assets/carousel/image2.png";
import image3 from "../../../components/assets/carousel/image3.png";
import image4 from "../../../components/assets/carousel/image4.png";

import prev from '../../../components/assets/offlineCenters/arrowLeft.svg';
import next from '../../../components/assets/offlineCenters/arrowRight.svg';

const MatchMaker = (props) => {

  return (
    <div className="match-makers">
      <div className="d-flex justify-content-center">
        <h1 className='match-h1'>Matchmakers
            <hr className="match-one" />
          <hr className="match-two" />
        </h1>
      </div>
      <OwlCarousel
        className="owl-theme"
        nav={true}
        navText={[`<img src=${prev}>`, `<img src=${next}>`]}
        dots={false}
        items={1}
        margin={8}
        loop={true}
        autoplay={true}
      >
        <div className="match-slide item">
          <div className="match-temple-card off-text-shadow">
            <div className="match-featureCard2">
              <img className="match-img" src={image1}
                alt="hansmatrimony-matchmakers-image1" />
            </div>
          </div>
          <p className='match-p'>Neelam, 15 years experience</p>
        </div>
        <div className="match-slide item">
          <div className="match-temple-card off-text-shadow">
            <div className="match-featureCard2">
              <img className="match-img" src={image2}
                alt="hansmatrimony-matchmakers-image2" />
            </div>
          </div>
          <p className='match-p'>Aprajita, 5 years experience</p>
        </div>
        <div className="match-slide item">
          <div className="match-temple-card off-text-shadow">
            <div className="match-featureCard2">
              <img className="match-img" src={image3}
                alt="hansmatrimony-matchmakers-image3" />
            </div>
          </div>
          <p className='match-p'>Satwinder, 9 years experience</p>
        </div>
        <div className="match-slide item">
          <div className="match-temple-card off-text-shadow">
            <div className="match-featureCard2">
              <img className="match-img" src={image4}
                alt="hansmatrimony-matchmakers-image4" />
            </div>
          </div>
          <p className='match-p'>Shweta, 4 years experience</p>
        </div>
      </OwlCarousel>
    </div>
  )
}

export default MatchMaker;