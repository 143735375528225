
import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyBCyQqHIrtO5BWPopMQXUhk9h1K9j0JskA',
  authDomain: "hanspartner-twango..firebaseapp.com",
    databaseURL: "https://hanspartner-twango.firebaseio.com",
    projectId: "hanspartner-twango",
    storageBucket: "",
    messagingSenderId: "104569280413",
    appId: "1:104569280413:android:564d3ce99e9653ae76c174"
}
const secondaryConfig = {
    apiKey: "AIzaSyCU-ZHkKEBJgfbcotGjEqrsU6C2aB9tDj4",
    authDomain: "hanswebapp-b30da.firebaseapp.com",
    databaseURL: "https://hanswebapp-b30da.firebaseio.com",
    projectId: "hanswebapp-b30da",
    storageBucket: "hanswebapp-b30da.appspot.com",
    messagingSenderId: "485274545846",
    appId: "1:485274545846:web:2574d9fbff3d2b0bec4017",
    measurementId: "G-6Y2VQMSDV5",
    vapidKey: 'BM-CiqqzLOGPQ0C53Dj4p-5qvmIjTOzyjWNBqPUVHNwxR4JNMWurhJU2nqJi3RjZH1qr1Vn4ue3E5g8Gzz2nP0A'
  };

  firebase.initializeApp(config);
  export default firebase;