
const MyProfileActionTypes = {
  GET_MY_PROFILE_START: 'GET_MY_PROFILE_START',
  GET_MY_PROFILE_SUCCESS: 'GET_MY_PROFILE_SUCCESS',
  GET_MY_PROFILE_FAILURE: 'GET_MY_PROFILE_FAILURE',
  UPLOAD_PROFILE_PICTURE_START: 'UPLOAD_PROFILE_PICTURE_START',
  UPLOAD_PROFILE_PICTURE_SUCCESS: 'UPLOAD_PROFILE_PICTURE_SUCCESS',
  UPLOAD_PROFILE_PICTURE_FAILURE: 'UPLOAD_PROFILE_PICTURE_FAILURE',
  UPDATE_PERSONAL_DETAILS_START: 'UPDATE_PERSONAL_DETAILS_START',
  UPDATE_PERSONAL_DETAILS_SUCCESS: 'UPDATE_PERSONAL_DETAILS_SUCCESS',
  UPDATE_PERSONAL_DETAILS_FAILURE: 'UPDATE_PERSONAL_DETAILS_FAILURE',
  UPDATE_FAMILY_DETAILS_START: 'UPDATE_FAMILY_DETAILS_START',
  UPDATE_FAMILY_DETAILS_SUCCESS: 'UPDATE_FAMILY_DETAILS_SUCCESS',
  UPDATE_FAMILY_DETAILS_FAILURE: 'UPDATE_FAMILY_DETAILS_FAILURE',
  UPDATE_PREFERENCES_START: 'UPDATE_PREFERENCES_START',
  UPDATE_PREFERENCES_SUCCESS: 'UPDATE_PREFERENCES_SUCCESS',
  UPDATE_PREFERENCES_FAILURE: 'UPDATE_PREFERENCES_FAILURE',
  GET_CONTACTED_PROFILES_START: 'GET_CONTACTED_PROFILES_START',
  GET_CONTACTED_PROFILES_SUCCESS: 'GET_CONTACTED_PROFILES_SUCCESS',
  GET_CONTACTED_PROFILES_FAILURE: 'GET_CONTACTED_PROFILES_FAILURE',

  GET_RISHTEY_COUNT_START: 'GET_RISHTEY_COUNT_START',
  GET_RISHTEY_COUNT_SUCCESS: 'GET_RISHTEY_COUNT_SUCCESS',
  GET_RISHTEY_COUNT_FAILURE: 'GET_RISHTEY_COUNT_FAILURE',

  GET_RECOMMENDED_PREFERENCE_START: 'GET_RECOMMENDED_PREFERENCE_START',
  GET_RECOMMENDED_PREFERENCE_SUCCESS: 'GET_RECOMMENDED_PREFERENCE_SUCCESS',
  GET_RECOMMENDED_PREFERENCE_FAILURE: 'GET_RECOMMENDED_PREFERENCE_FAILURE',

  UPLOAD_PROFILE_AUDIO_START: 'UPLOAD_PROFILE_AUDIO_START',
  UPLOAD_PROFILE_AUDIO_SUCCESS: 'UPLOAD_PROFILE_AUDIO_SUCCESS',
  UPLOAD_PROFILE_AUDIO_FAILURE: 'UPLOAD_PROFILE_AUDIO_FAILURE',
}

export default MyProfileActionTypes