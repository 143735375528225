import React from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectMyProfile } from '../../../redux/my-profile/my-profile.selector'
import { matchKundali, resetKundli } from '../../../redux/today-special/today-special.action'
import { selectProfileKundli } from '../../../redux/today-special/today-special.selector'
import crossImg from '../../assets/profile/cross-dark.svg';
import './profile-kundli.styles.scss'

function ProfileKundli({matchingWith,profileKundali,matchKundali,userProfile,close,resetKundli}) {

    useEffect(() => {
        
        let male = null
        let female = null

        if (matchingWith.item.gender.toLowerCase() === 'male') {
            male = matchingWith.item
            female = userProfile.personal
        } else {
            male = userProfile.personal
            female = matchingWith.item
        }

         let data = {
            femaleBirthDate: female.birth_date,
            femaleBirthPlace: female.birth_place,
            femaleBirthTime: female.birth_time,
            maleBirthDate: male.birth_date,
            maleBirthPlace: male.birth_place,
            maleBirthTime: male.birth_time
        }

       
        matchKundali(data)

        return () => resetKundli()
    },[])

    const closeKundli = () => close()

  return (
    <div className='kundali-container'>
      <div className='d-flex kundli-header-actions'>
        <img src={crossImg} 
        onClick={closeKundli} 
        alt="close"/>
        <div></div>
      </div>
      
      <h4>
        Kundli Score
      </h4>

      <p className='mt-2'>
        Your Kundli Score is : {profileKundali?.total?.received_points}
      </p>
      <p >
      {profileKundali?.conclusion?.report}
      </p>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
    profileKundali: selectProfileKundli,
    userProfile: selectMyProfile
  })
  
  const mapDispatchToProps = dispatch => ({
    matchKundali: data => (dispatch(matchKundali(data))),
    resetKundli: () => (dispatch(resetKundli()))
  })

export default connect(mapStateToProps,mapDispatchToProps)(ProfileKundli)
