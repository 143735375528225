import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Modal } from "react-bootstrap";
import "../my-profile-form.styles.scss";
import useFromInput from "../../../hooks/useFormInput";
import axios from "axios";
import Input from "../../../components/input/input.component";
import Button from "../../../components/button/button.component";
import { Select, MenuItem, MenuList } from "@mui/material";
import LocationSelect from "../../../components/forms-elements/location-select/location-select";
import { getCity } from "../../../redux/register/register.action";
import {
  selectOccupation,
  selectCity,
} from "../../../redux/register/register.selector";
import { updateFamilyDetails } from "../../../redux/my-profile/my-profile.action";
import { selectId, selectIsLead } from "../../../redux/auth/auth.selector";
import {
  selectIdentityNumber,
  selectTempleId,
} from "../../../redux/my-profile/my-profile.selector";
import { TextField } from "@mui/material";
const FamilyForm = ({
  id,
  isLead,
  templeId,
  identityNumber,
  updateFamilyDetails,
  show,
  onHide,
  city,
  getCity,
  occupation,
  familyData,
}) => {
  const [state, changeState, , reset] = useFromInput(familyData);
  const [invalidInput, setInvalidInput] = useState({ valid: false, data: [] });
  const [suggestions, setSuggestions] = useState([]);
  const [cityId, setCityId] = useState();
  const [inputValue, setInputValue] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [sister, setSister] = useState("");
  const [brother, setBrother] = useState("");
  const [aboutFamily, setAboutFamily] = useState("");
  const [familyincome, setFamilyIncome] = useState("");
  console.log("familyIncome", familyincome);
  useEffect(() => {
    state.familyLivingIn && getCity(`?search_city=${state.familyLivingIn}`);
  }, [state.familyLivingIn]);

  const handleFocusOut = (e) => {
    if (!e.target.value || e.target.value === "Select One") {
      setInvalidInput((prevState) => ({
        prevState,
        valid: false,
        data: [...prevState.data, e.target.name],
      }));
    }
  };

  const handleFocusIn = (e) => {
    setInvalidInput((prevState) => ({
      valid: false,
      data: prevState.data.filter((key) => key !== e.target.name),
    }));
  };

  const handleInputChangeCity = (event) => {
    const value = event.target.value;
    setInputValue(value);
    fetchSuggestions(value);
    setShowSuggestions(true);
  };
  const handleSuggestionSelect = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setShowSuggestions(false);
  };

  //city api call
  const fetchSuggestions = (value) => {
    // const apiUrl = `https://hansmatrimony.com/api/v1/suggestCities?searchCity=${value}`;
    const apiUrl = `https://api.hansmatrimony.com/api/v1/suggestCities?searchCity=${value}`;

    axios
      .get(apiUrl)
      .then((response) => {
        setSuggestions(response.data.citiesFound);
        setCityId(response.data.citiesFound.id);
      })
      .catch((error) => {
        console.error("Error fetching city suggestions:", error);
      });
  };
  console.log("family about", familyData.about);

  const shouldUpdateData = (state) => {
    let familytypecode;
    let housetypecode;
    let father_occupationCode;
    let mother_occupationCode;
    let fatherstatuscode;
    let is_livingWithParentscode;

    if (state.familyType === "Nuclear") {
      familytypecode = 1;
    } else if (state.familyType === "Joint") {
      familytypecode = 2;
    }
    if (state.houseType === "Owned") {
      housetypecode = 1;
    } else if (state.houseType === "Rented") {
      housetypecode = 2;
    } else if (state.houseType === "Leased") {
      housetypecode = 2;
    }
    if (state.father_occupation === "Not Alive") {
      father_occupationCode = 0;
    } else if (state.father_occupation === "Business/Self-Employed") {
      father_occupationCode = 1;
    }
    if (state.father_occupation === "Doctor") {
      father_occupationCode = 2;
    } else if (state.father_occupation === "Govt. Job") {
      father_occupationCode = 3;
    } else if (state.father_occupation === "Teacher") {
      father_occupationCode = 4;
    } else if (state.father_occupation === "Private Job") {
      father_occupationCode = 5;
    } else if (state.father_occupation === "Not Working") {
      father_occupationCode = 6;
    } else if (state.father_occupation === "Other") {
      father_occupationCode = 7;
    } else if (state.father_occupation === "Retired") {
      father_occupationCode = 8;
    }
    if (state.mother_occupation === "Not Alive") {
      mother_occupationCode = 0;
    } else if (state.mother_occupation === "Business/Self-Employed") {
      mother_occupationCode = 1;
    } else if (state.mother_occupation === "Doctor") {
      mother_occupationCode = 2;
    } else if (state.mother_occupation === "Govt. Job") {
      mother_occupationCode = 3;
    } else if (state.mother_occupation === "Teacher") {
      mother_occupationCode = 4;
    } else if (state.mother_occupation === "Private Job") {
      mother_occupationCode = 5;
    } else if (state.mother_occupation === "Not Working") {
      mother_occupationCode = 6;
    } else if (state.mother_occupation === "Other") {
      mother_occupationCode = 7;
    } else if (state.mother_occupation === "Retired") {
      mother_occupationCode = 8;
    }
    const cityData = suggestions.find(
      (item) => item.cityName === state.familyLivingIn
    );
    const cityCode = cityData ? cityData.id : "102";
    if (state.livingWithParents === "Yes") {
      is_livingWithParentscode = 1;
    } else if (state.livingWithParents === "No") {
      is_livingWithParentscode = 2;
    }
    return {
      userId: id,
      family_type: state.familyType,
      house_type: state.houseType,
      // about_family: aboutFamily,
      father_status: parseInt(state.father_occupation),
      mother_status: parseInt(state.mother_occupation),
      gotra: state.gotra,
      // family_income: state.familyIncome,
      // city: state.familyLivingIn,
      livingWithParents: state.LivingWithParents,
      // about_family: "about family",
      city_family: state.familyLivingIn,
      family_income: familyincome,
      familyTypeCode: familytypecode,
      occupation_father_code: parseInt(father_occupationCode),
      houseTypeCode: housetypecode,
      married_brothers: parseInt(brother),
      married_sisters: parseInt(sister),
      occupation_mother_code: parseInt(mother_occupationCode),
      mother_statusCode: 1,
      unmarried_brothers: state.unmarriedBrothers,
      unmarried_sisters: state.unmarriedSisters,
      father_statusCode: 0,
      // is_livingWithParents:is_livingWithParentscode,
      // livingWithParents:state.livingWithParents

      // about_family: "Add  about family",
      // city_family: "Delhi,Delhi,India",
      // family_income: 32,
      // familyTypeCode: 1,
      // occupation_father_code: 5,
      // father_statusCode: 1,
      // houseTypeCode: 2,
      // married_brothers: 1,
      // married_sisters: 1,
      // occupation_mother_code: 2,
      // mother_statusCode: 1,
      // unmarried_brothers: 0,
      // unmarried_sisters: 0,
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateFamilyDetails({
      ...shouldUpdateData(state),
      about_family: aboutFamily,
      livingWithParents: "Yes",
      is_livingWithParents: 1,
    });
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <div className="my-profile-form">
        <form onSubmit={handleSubmit}>
          <header className="d-flex justify-content-between align-items-center">
            <div onClick={onHide}>
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.75 11.9167H7.39917L11.2775 8.0275L9.75 6.5L3.25 13L9.75 19.5L11.2775 17.9725L7.39917 14.0833H22.75V11.9167Z"
                  fill="black"
                />
              </svg>
              <span className="header-text">Family</span>
            </div>
            <Button type="submit" className="btn btn-primary" title="Save" />
          </header>
          <div className="d-flex flex-wrap justify-content-center align-items-center px-3">
            <Select
              className="input-field w-50 pr-2 mt-3"
              id="familyType"
              name="familyType"
              value={state.familyType}
              onChange={(e) => {
                changeState(e);
              }}
              label="Family Type"
            >
              {" "}
              <MenuItem value="" disabled>
                Select Family Type
              </MenuItem>
              <MenuItem value="Joint">Joint</MenuItem>
              <MenuItem value="Nuclear">Nuclear</MenuItem>
            </Select>

            <Select
              className="input-field w-50 pr-2 mt-3"
              id="houseType"
              name="houseType"
              value={state.houseType}
              onChange={(e) => {
                changeState(e);
              }}
            >
              <MenuItem value="" disabled>
                House Type
              </MenuItem>
              <MenuItem value="Owned">Owned</MenuItem>
              <MenuItem value="Rented">Rented</MenuItem>
              <MenuItem value="Leased">Leased</MenuItem>
            </Select>

            <Select
              className="input-field w-50 pr-2 mt-3"
              id="father_occupation"
              name="father_occupation"
              value={state.father_occupation}
              onChange={(e) => {
                changeState(e);
              }}
              label="Father's Occupation"
              MenuProps={{
                variant: "menu",
              }}
            >
              <MenuItem disabled>Father's Occupation</MenuItem>
              {occupation.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>

            <Select
              className="input-field w-50 pl-2 mt-3"
              id="mother_occupation"
              name="mother_occupation"
              value={state.mother_occupation}
              onChange={(e) => {
                changeState(e);
              }}
              label="Mother Occupation"
            >
              <MenuItem disabled>Mother's Occupation</MenuItem>
              {occupation.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>

            <Select
              className="input-field w-50 pr-2 mt-3"
              id="livingWithParents"
              name="livingWithParents"
              value={state.livingWithParents}
              onChange={(e) => {
                changeState(e);
              }}
              label="Living with Parents"
              placeholder="Living With Parents"
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>

            <Input
              type="text"
              list="familyLivingIn"
              className="input-field w-50 pr-2 mt-3"
              name="familyLivingIn"
              value={state.familyLivingIn}
              handleChange={(e) => {
                changeState(e);
                handleInputChangeCity(e);
              }}
              onBlur={handleFocusOut}
              onFocus={handleFocusIn}
            />
            {showSuggestions && (
              <datalist name="familyLivingIn" id="familyLivingIn">
                {suggestions.map((suggestion) => (
                  <option
                    key={suggestion.id}
                    value={suggestion.cityName}
                    onClick={handleSuggestionSelect}
                  >
                    {suggestion.cityName}
                  </option>
                ))}
              </datalist>
            )}
          </div>
          <div className="P-3">
            <TextField
              label="marriedSisters"
              className="input-field w-50 pl-2 mt-3"
              variant="outlined"
              value={sister}
              onChange={(e) => {
                setSister(e.target.value);
              }}
              type="number"
              name="m"
            />
            <TextField
              label="Married Brothers"
              className="input-field w-50 pl-2 mt-3"
              variant="outlined"
              value={brother}
              onChange={(e) => {
                setBrother(e.target.value);
              }}
              type="number"
              name="marriedBrothers"
            />
          </div>

          <div className="p-3">
            {/* <Input
              label="Family’s Annual Income in LPA"
              className='input-field w-50 pr-2 mt-3'
              variant="outlined"
              value={state.familyIncome}
              inputProps={{
                max: 200,
                min: 0
              }}
              onChange={changeState}
              type="number"
              name='familyIncome'
            /> */}

            <TextField
              label="Family Income"
              className="input-field w-50 pl-2 mt-3"
              variant="outlined"
              value={familyincome}
              onChange={(e) => {
                setFamilyIncome(e.target.value);
              }}
              type="number"
              name="FAMILY INCOME"
            />

            {/* <Input
              label="Gotra"
              className='input-field w-50 pl-2 mt-3'
              variant="outlined"
              value={state.gotra}
              onChange={changeState}
              type="text"
              name='gotra'
            /> */}

            <TextField
              label="Gotra"
              className="input-field w-50 pl-2 mt-3"
              variant="outlined"
              value={state.gotra}
              onChange={changeState}
              type="text"
              name="gotra"
            />

            <div className="w-100 mt-2">
              <label>About Family:</label>
              <textarea
                className="w-100 mt-2"
                cols={50}
                rows={4}
                maxLength="300"
                value={aboutFamily}
                placeholder="Description (max 300 characters)"
                onChange={(e) => {
                  setAboutFamily(e.target.value);
                }}
              />
            </div>

            {/* <Button
              type="submit"
              className="btn btn-primary full-width mt-3"
              title="Save"
            /> */}
          </div>
        </form>
      </div>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  occupation: selectOccupation,
  city: selectCity,
  id: selectId,
  isLead: selectIsLead,
  templeId: selectTempleId,
  identityNumber: selectIdentityNumber,
});

const mapDispatchToProps = (dispatch) => ({
  getCity: (query) => dispatch(getCity(query)),
  updateFamilyDetails: (data) => dispatch(updateFamilyDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FamilyForm);
