import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import pbadge from "../../assets/chat/premium-badge.svg";
import call from "../../assets/chat/call.svg";
import liked from "../../assets/chat/liked.svg";
import reject from "../../assets/chat/reject.svg";
import comment from "../../assets/comment.png";
import whatsapp from "../../assets/chat/whatsapp_icon.png";
import check from "../../assets/chat/check.svg";

import male from "../../assets/thumbnails/male.png";
import female from "../../assets/thumbnails/female.png";

import LogoButton from "../../logo-button/logo-button.component";

import "./profile-preview.styles.scss";
import { selectUserGender } from "../../../redux/my-profile/my-profile.selector";
import { Link } from "react-router-dom";
import { buttonActionTypes, sectionTypes } from "../../../utils/display.utils";
// import { sectionTypes } from '../../../utils/display.utils';
import { selectCredits } from "../../../redux/auth/auth.selector";
import { makePayment } from "../../../redux/payment/payment.action";
import { useState } from "react";
import { useSelector } from "react-redux";
import ProfileKundli from "../profile-kundli/profile-kundli.component";
import { ConstructionOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const ProfilePreview = ({
  item,
  gender,
  openProfile,
  section,
  index,
  credits,
  makePayment,
}) => {
  const navigate = useNavigate();
  console.log("Section", section);
  // console.log("item photo",item.photo);
  const [openProfileKundli, setOpenProfileKundli] = useState(false);
  // const auth = useSelector(state =>state.auth.auth)
  // const item.id = auth.id
  // console.log(useSelector(state));
  //NEWLY_JOINED
  const newlyJoined = useSelector((state) => state.newlyJoined);
  const newlyJoinedList = newlyJoined.newly_joined_list;
  const newlyJoinedObject = newlyJoinedList.find((obj) => obj.id === item.id);
  let newUserPhoto;
  if (newlyJoinedObject != undefined) {
    // console.log(newlyJoinedObject);
    newUserPhoto = newlyJoinedObject.user_photos[0].photo_url;
  }
  console.log(item);
  // console.log(newlyJoinedObject);
  // console.log(newUserPhoto);
  //popular
  const popular = useSelector((state) => state.popular);
  const popularProfilesList = popular?.popular_profiles_list;
  const popularProfileObject = popularProfilesList?.find(
    (obj) => obj.id === item.id
  );
  // console.log(popularProfileObject);
  let popularPhoto;
  if (popularProfileObject) {
    popularPhoto = popularProfileObject.user_photos?.[0]?.photo_url;
  } //TODAYS_SPECIAL
  const today = useSelector((state) => state.today);
  const todaySpecialList = today.data;
  // console.log(todaySpecialList);
  const todaySpecialObject = todaySpecialList.find(
    (obj) => obj.id == item.item.id
  );
  // console.log(todaySpecialObject);
  const todayPhoto = todaySpecialObject
    ? todaySpecialObject.user_photos[0].photo_url
    : null;
  //Discover Profile Photo
  const discover = useSelector((state) => state.discover.profile);
  const discoverObject = discover.find((obj) => obj.id === parseInt(item.id));
  console.log(discoverObject);
  const discoverPhoto = discoverObject?.user_photos;
  //Profile photo
  // const profile = useSelector(state => state.myProfile.contact);
  // const profileObject = profile.find(obj => obj.id === item.id);
  // const profilePhoto = profileObject?.photo;

  //handling image case updated changes made for popular and newlyJoined

  let backgroundImageUrl;
  if (section === sectionTypes.POPULAR && popularPhoto) {
    // backgroundImageUrl = `https://d2v6etlhrhtken.cloudfront.net/uploads/${popularPhoto[0]?.photo_url}`;
    backgroundImageUrl = popularPhoto
      ? `https://hansmatrimony.s3.ap-south-1.amazonaws.com/uploads/${popularPhoto}`
      : "";
    // console.log(backgroundImageUrl);
  } else if (
    section === sectionTypes.NEWLY_JOINED &&
    newUserPhoto != undefined
  ) {
    // console.log();
    // backgroundImageUrl = `https://d2v6etlhrhtken.cloudfront.net/uploads/${newUserPhoto[0]?.photo_url}`;
    // backgroundImageUrl = `https://hansmatrimony.s3.ap-south-1.amazonaws.com/uploads/${newUserPhoto}`;
    backgroundImageUrl = newUserPhoto
      ? `https://hansmatrimony.s3.ap-south-1.amazonaws.com/uploads/${newUserPhoto}`
      : "";
    // console.log(backgroundImageUrl);
  } else if (
    section === sectionTypes.SEE_MORE &&
    discoverPhoto &&
    discoverPhoto.length > 0 &&
    discoverPhoto[0]?.photo_status === "active"
  ) {
    console.log("Inside See More");
    backgroundImageUrl = `https://hansmatrimony.s3.ap-south-1.amazonaws.com/uploads/${discoverPhoto[0]?.photo_url}`;
  } else if (section === sectionTypes.TODAY_SPECIAL && todayPhoto) {
    // backgroundImageUrl = `https://d2v6etlhrhtken.cloudfront.net/uploads/${todayPhoto[0]?.photo_url}`;
    backgroundImageUrl = todayPhoto
      ? `https://hansmatrimony.s3.ap-south-1.amazonaws.com/uploads/${todayPhoto}`
      : "";
    console.log(backgroundImageUrl);
  }

  // else if (section === sectionTypes.CONTACTED && profilePhoto) {
  //   backgroundImageUrl = profilePhoto ?profilePhoto:(gender?.toLowerCase() === 'male'?female:male);
  // }
  else {
    backgroundImageUrl = gender?.toLowerCase() === "male" ? male : female;
  }
  // useEffect(()=>{
  //   console.log('the array is ',todaySpecialObject)
  // },[todaySpecialObject])

  // console.log('the array 2 is ',popularProfileObject)
  /**
   * get buttons according to the section type
   * @param {*} item
   * @returns
   */
  // console.log(backgroundImageUrl);
  function getButtonsAccordingToSections(item) {
    // console.log(item);
    let button;
    switch (section) {
      case sectionTypes.SEE_MORE:
        console.log(isBlurRequired());
        return (
          <>
            {/* <LogoButton imageUrl={whatsapp} action='WHATSAPP' actionId={item.id} actionOf='1' callFrom={sectionTypes.SEE_MORE} addCircularBg={true} section={section} showName={true} /> */}
            <LogoButton
              imageUrl={whatsapp}
              action={buttonActionTypes.WHATSAPP}
              actionId={item.id}
              actionOf="1"
              callFrom={sectionTypes.SEE_MORE}
              addCircularBg="true"
              mobile={item.mobile}
            />
            <LogoButton
              imageUrl={call}
              action="CONTACTED"
              actionId={item.id}
              actionOf="1"
              callFrom="history"
              section={section}
              className="logoButtons"
            />
            <Link
              to={`/chat/openProfile/${item.id}/${section}`}
              onClick={() => !isBlurRequired() && openProfile(item.id)}
              className={`logo ${isBlurRequired() ? "blur-effect" : ""}`}
              style={{
                backgroundImage: `url(${backgroundImageUrl})`,
              }}
            ></Link>
          </>
        );

      case sectionTypes.LIKED_ME:
        return (
          <LogoButton
            imageUrl={comment}
            action="CONTACTED"
            actionId={item.id}
            actionOf="1"
            callFrom={sectionTypes.LIKED_ME}
            addCircularBg={true}
            section={section}
            onClick={async () => {
              console.log("Inside Call");
              navigate(`/chat/openProfile/${item.id}/${section}/#Contacted`);
              await openProfile(item.id);
            }}
          />
        );

      case sectionTypes.VIEWED_ME:
        return (
          <LogoButton
            imageUrl={comment}
            action="CONTACTED"
            actionId={item.id}
            actionOf="1"
            callFrom={sectionTypes.VIEWED_ME}
            addCircularBg={true}
            section={section}
            onClick={async () => {
              console.log("Inside Call");
              navigate(`/chat/openProfile/${item.id}/${section}/#Contacted`);
              await openProfile(item.id);
            }}
          />
        );

      // case sectionTypes.PROFILES || sectionTypes.POPULAR || sectionTypes.NEWLY_JOINED :
      //   if (item.item.is_premium === 1) {
      //     button = <LogoButton imageUrl={comment} action='LIKED' actionId={item.id} actionOf='1' callFrom='history' addCircularBg={true} section={section} />
      //   } else {
      //     button = <><LogoButton imageUrl={liked} action='LIKED' actionId={item.id} actionOf='1' callFrom='history' section={section} /></>
      //   }
      // return button;

      case sectionTypes.POPULAR:
        console.log("Inside Popular");
        return (
          <>
            <LogoButton
              imageUrl={liked}
              action="LIKED"
              actionId={popularProfileObject.id}
              actionOf="1"
              callFrom="history"
              section={section}
              className="logoButtons"
            />
            <LogoButton
              imageUrl={call}
              action="CONTACTED"
              actionId={popularProfileObject.id}
              actionOf="1"
              callFrom="history"
              section={section}
              onClick={async () => {
                console.log("Inside Call");
                navigate(
                  `/chat/openProfile/${popularProfileObject.id}/${section}/#Contacted`
                );
                await openProfile(popularProfileObject.id);
              }}
              className="logoButtons"
            />
            <LogoButton
              imageUrl={reject}
              action="REJECTED"
              actionId={popularProfileObject.id}
              actionOf="1"
              callFrom="history"
              section={section}
              className="logoButtons"
            />

            {/* 
        <LogoButton imageUrl={reject} action='REJECTED' actionId={item.id} actionOf='1' callFrom='history' section={section} />

        <LogoButton imageUrl={liked} action='LIKED' actionId={item.id} actionOf='1' callFrom='history' section={section} /> */}
            <div>
              <Link
                to={
                  !isBlurRequired()
                    ? `/chat/openProfile/${popularProfileObject.id}/${section}`
                    : "#"
                }
                onClick={
                  !isBlurRequired()
                    ? () => openProfile(popularProfileObject.id)
                    : makePayment
                }
                className={`logo ${isBlurRequired() ? "blur-effect" : ""} `}
                style={{ backgroundImage: `url(${backgroundImageUrl})` }}
              ></Link>
            </div>
          </>
        );
      case sectionTypes.NEWLY_JOINED:
        return (
          <>
            <LogoButton
              imageUrl={liked}
              action="LIKED"
              actionId={newlyJoinedObject.id}
              actionOf="1"
              callFrom="history"
              section={section}
              className="logoButtons"
            />
            <LogoButton
              imageUrl={call}
              action="CONTACTED"
              actionId={newlyJoinedObject.id}
              actionOf="1"
              callFrom="history"
              section={section}
              onClick={async () => {
                console.log("Inside Call");
                navigate(
                  `/chat/openProfile/${newlyJoinedObject.id}/${section}/#Contacted`
                );
                await openProfile(newlyJoinedObject.id);
              }}
              className="logoButtons"
            />
            <LogoButton
              imageUrl={reject}
              action="REJECTED"
              actionId={newlyJoinedObject.id}
              actionOf="1"
              callFrom="history"
              section={section}
              className="logoButtons"
            />

            {/* 
        <LogoButton imageUrl={reject} action='REJECTED' actionId={item.id} actionOf='1' callFrom='history' section={section} />

        <LogoButton imageUrl={liked} action='LIKED' actionId={item.id} actionOf='1' callFrom='history' section={section} /> */}
            <div>
              <Link
                to={
                  !isBlurRequired()
                    ? `/chat/openProfile/${newlyJoinedObject.id}/${section}`
                    : "#"
                }
                onClick={
                  !isBlurRequired()
                    ? () => openProfile(newlyJoinedObject.id)
                    : makePayment
                }
                className={`logo ${isBlurRequired() ? "blur-effect" : ""} `}
                style={{ backgroundImage: `url(${backgroundImageUrl})` }}
              ></Link>
            </div>
          </>
        );

      case sectionTypes.TODAY_SPECIAL:
        return (
          <>
            <LogoButton
              imageUrl={liked}
              action="LIKED"
              actionId={todaySpecialObject.id}
              actionOf="1"
              callFrom="history"
              section={section}
              className="logoButtons"
            />
            <LogoButton
              imageUrl={call}
              action="CONTACTED"
              actionId={todaySpecialObject.id}
              actionOf="1"
              callFrom="history"
              section={section}
              onClick={async () => {
                console.log("Inside Call");
                navigate(
                  `/chat/openProfile/${todaySpecialObject.id}/${section}/#Contacted`
                );
                await openProfile(todaySpecialObject.id);
              }}
              className="logoButtons"
            />
            <LogoButton
              imageUrl={reject}
              action="REJECTED"
              actionId={todaySpecialObject.id}
              actionOf="1"
              callFrom="history"
              section={section}
              className="logoButtons"
            />
            {/* 
        <LogoButton imageUrl={reject} action='REJECTED' actionId={item.id} actionOf='1' callFrom='history' section={section} />

        <LogoButton imageUrl={liked} action='LIKED' actionId={item.id} actionOf='1' callFrom='history' section={section} /> */}
            <div>
              <Link
                to={
                  !isBlurRequired()
                    ? `/chat/openProfile/${todaySpecialObject.id}/${section}`
                    : "#"
                }
                onClick={
                  !isBlurRequired()
                    ? () => openProfile(todaySpecialObject.id)
                    : makePayment
                }
                className={`logo ${isBlurRequired() ? "blur-effect" : ""} `}
                style={{ backgroundImage: `url(${backgroundImageUrl})` }}
              ></Link>
            </div>
          </>
        );

      default:
        if (item.item.is_premium === 1) {
          button = (
            <>
              <LogoButton
                imageUrl={comment}
                action="LIKED"
                actionId={item.id}
                actionOf="1"
                callFrom="history"
                addCircularBg={true}
                section={section}
              />
              <div>
                <Link
                  to={
                    !isBlurRequired()
                      ? `/chat/openProfile/${item.id}/${section}`
                      : "#"
                  }
                  onClick={
                    !isBlurRequired() ? () => openProfile(item.id) : makePayment
                  }
                  className={`logo ${isBlurRequired() ? "blur-effect" : ""} `}
                  style={{
                    backgroundImage: `url(${
                      item.photo
                        ? item.photo
                        : gender?.toLowerCase() === "male"
                        ? male
                        : female
                    })`,
                  }}
                ></Link>
              </div>
            </>
          );
        } else {
          button = (
            <>
              <LogoButton
                imageUrl={reject}
                action="REJECT"
                actionId={item.id}
                actionOf="1"
                callFrom="history"
                section={section}
              />
              <LogoButton
                imageUrl={liked}
                action="LIKED"
                actionId={item.id}
                actionOf="1"
                callFrom="history"
                section={section}
              />

              <div>
                <Link
                  to={
                    !isBlurRequired()
                      ? `/chat/openProfile/${item.id}/${section}`
                      : "#"
                  }
                  onClick={
                    !isBlurRequired() ? () => openProfile(item.id) : makePayment
                  }
                  className={`logo ${isBlurRequired() ? "blur-effect" : ""} `}
                  style={{
                    backgroundImage: `url(${
                      backgroundImageUrl
                        ? backgroundImageUrl
                        : gender?.toLowerCase() === "male"
                        ? male
                        : female
                    })`,
                  }}
                ></Link>
              </div>
            </>
          );
        }
        return button;
    }
  }
  /**
   * Blur effect is only for interested or viewed me section
   * @returns
   */
  const isBlurRequired = () => {
    let blurStatus = false;
    if (
      credits === "0" &&
      section === (sectionTypes.LIKED_ME || sectionTypes.VIEWED_ME) &&
      index > 0
    ) {
      blurStatus = true;
    }

    return blurStatus;
  };

  const showProfileKundli = () => setOpenProfileKundli(true);
  const closeProfileKundli = () => setOpenProfileKundli(false);

  return (
    <div className="pre mb-4">
      {typeof item.id == "string" ? (
        <div className="upper-body">
          <div className="prem p-4">
            <LogoButton
              imageUrl={pbadge}
              action="YES"
              actionId={item.id}
              actionOf="1"
              callFrom="history"
            />
          </div>
        </div>
      ) : null}

      <Link
        to={!isBlurRequired() ? `/chat/openProfile/${item.id}/${section}` : "#"}
        onClick={!isBlurRequired() ? () => openProfile(item.id) : makePayment}
        className={`logo ${isBlurRequired() ? "blur-effect" : ""} `}
        style={{
          backgroundImage: `url(${
            backgroundImageUrl
              ? backgroundImageUrl
              : gender?.toLowerCase() === "male"
              ? female
              : male
          })`,
        }}
        // style={{
        //   backgroundImage: `url(${backgroundImageUrl})`,
        // }}
      ></Link>
      <div className={`body ${isBlurRequired() ? "blur-effect" : ""}`}>
        <div className="content fs-12" style={{ zIndex: "3" }}>
          <span
            style={{
              fontWeight: "600",
              fontSize: "14px",
              textTransform: "capitalize",
              WebkitTextStroke: "1px black", // For WebKit browsers (e.g., Chrome, Safari)
              textShadow:
                "0.5px 0 0 black, 0 -0.5px 0 black, 0 0.5px 0 black, -0.5px 0 0 black", // Reduced shadow
            }}
          >
            {item.title} <img src={check} className="check" alt="" />
          </span>
          <br />
          <span>{item.subTitle1}</span>
          <br />
          <span>{item.subTitle2}</span>
          <br />
          <span>{item.subTitle3}</span>
          <br />
          {section === sectionTypes.TODAY_SPECIAL ? (
            <>
              <span className="kundli-button" onClick={showProfileKundli}>
                View Kundli Score
              </span>
              <br />
            </>
          ) : null}
        </div>
        <div className="call p-4">
          {getButtonsAccordingToSections(item)}

          {/* <LogoButton imageUrl={call} action='CONTACTED' actionId={item.id} actionOf='1' callFrom='history'  /> */}
        </div>
      </div>

      {openProfileKundli ? (
        <ProfileKundli matchingWith={item} close={closeProfileKundli} />
      ) : null}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  gender: selectUserGender,
  credits: selectCredits,
});

const mapDispatchToProps = (dispatch) => ({
  makePayment: () => dispatch(makePayment()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePreview);
