import { FETCH, API,APP_URL_PREFIX } from '../../utils/api';

import {setError} from '../error/error.action';
import PaymentActionTypes from './payment.types';
import axios from "axios";


export const makePayment = () => ({
  type: PaymentActionTypes.MAKE_PAYMENT
})

export const getPaymentPlans = (data) => {
  return async (dispatch) => {
      dispatch({type: PaymentActionTypes.GET_PAYMENT_PLANS_START})
     try{
      let fetch = await axios.get(`${APP_URL_PREFIX}${API.GET_PLANS_URL}?id=${data.id}&is_lead=${data.is_lead}`)

      if (fetch) {
        dispatch({
          type: PaymentActionTypes.GET_PAYMENT_PLANS_SUCCESS,
          payload: fetch.data,
        })
      }
     } catch(err) {
      dispatch({ type: PaymentActionTypes.GET_PAYMENT_PLANS_FAILED })
      dispatch(setError(err))
     }
  }
}

export const getPaytmOrderId = (data) => {

  return async (dispatch) => {
    dispatch({ type: PaymentActionTypes.GET_PAYTM_ORDERID_START })
    
    try {
      let fetch = await axios.post(`${APP_URL_PREFIX}${API.PAYTM_ORDER_URL}`,data)

    if (fetch) {
      dispatch({
        type: PaymentActionTypes.GET_PAYTM_ORDERID_SUCCESS,
        payload: fetch.data,
      })
    }
    } catch (error) {
      dispatch({ type: PaymentActionTypes.GET_PAYTM_ORDERID_FAILURE, payload: error })
        dispatch(setError(error))
    }
    

  }
}

export const payUsingPaytm = (data) => {
  return dispatch => {
    dispatch({ type: PaymentActionTypes.GET_PAYTM_ORDERID_START })
    fetch(`https://securegw-stage.paytm.in/theia/api/v1/showPaymentPage?mid=bkjPis66135619933053&orderId=${data.orderId}`,
      {
        method: 'POST',
        body: JSON.stringify(data),
      }
    )
      .then(res => {
        if (res.status !== 200) {
          throw new Error('Something went Worng!')
        }
        return res.json();
      })
      .then(resData => {

        dispatch({
          type: PaymentActionTypes.GET_PAYTM_ORDERID_SUCCESS,
          payload: resData,
        })

      })
      .catch(err => {
        dispatch({ type: PaymentActionTypes.GET_PAYTM_ORDERID_FAILURE, payload: err })
        dispatch(setError(err))
      })
  }
}

export const captureStandardPayment = (data) => {

  return async (dispatch) => {
    dispatch({ type: PaymentActionTypes.CAPTURE_PAYMENT_START })
   
    try {
      let fetch =  axios.post(`${APP_URL_PREFIX}${API.CAPTURE_PAYMENT_URL}`,data)
      if (fetch) {
        dispatch({
          type: PaymentActionTypes.CAPTURE_PAYMENT_SUCCESS,
          payload: fetch.data,
        })
      }
    } catch (error) {
      dispatch(setError(error));
        dispatch({ type: PaymentActionTypes.CAPTURE_PAYMENT_FAILURE })
    }
  
  }

}

export const getTransactionStatus = (data) => {
  return async (dispatch) => {
    dispatch({ type: PaymentActionTypes.TRANSACTION_STATUS_START })

    try {
      let fetch =  axios.post(`${APP_URL_PREFIX}${API.PAYTM_TRANSACTION_URL}`,data)
      if (fetch) {
        dispatch({
          type: PaymentActionTypes.TRANSACTION_STATUS_SUCCESS,
          payload: fetch.data,
        })
      }
    } catch (error) {
      dispatch(setError(error));
        dispatch({ type: PaymentActionTypes.TRANSACTION_STATUS_FAILURE })
    }
  }
}
