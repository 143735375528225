import React from 'react'
import './upload.photo.one.scss'
import pic from './emp.webp'
import { Link } from 'react-router-dom'
const uploadPhotoOne = () => {
 
  
  return (
    <div>
      <h1 className='img-title'><strong>What does a good profile looks like</strong></h1>
      <img className='image-upload-1' src={pic}/>
      <div className='upload-footer'>
      <h4>Upload 2 or more photos that highlight your face.It will help you get 10x more interese</h4>
      <Link to ='/fourReg2/uploadPhotoPartTwo'><button  className='btn btn-lg btn-primary upload-button'>Continue</button></Link>
    
      </div>
    </div>
  );
}

export default uploadPhotoOne
