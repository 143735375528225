import React, { useState, useEffect } from 'react'
import { connect,useSelector} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Modal } from 'react-bootstrap';

import '../my-profile-form.styles.scss';

import useFromInput from '../../../hooks/useFormInput';

import Input from '../../../components/input/input.component';
import Select from '../../../components/select/select.component';
import Button from '../../../components/button/button.component';

import { getCity } from '../../../redux/register/register.action';
import { selectQualification, selectOccupation, selectCity, selectDesignation } from '../../../redux/register/register.selector';
import { updatePersonalDetails } from '../../../redux/my-profile/my-profile.action';
import { selectId, selectIsLead } from '../../../redux/auth/auth.selector';
import { selectTempleId, selectIdentityNumber } from '../../../redux/my-profile/my-profile.selector';
import { personalFormTypes } from '../../../utils/display.utils';
import PersonalDetailsForm from './personal-details-form';
import AboutForm from './about-form';
import EducationCareerForm from './education-career-form';
import HoroscopeForm from './horoscope-form';
import ContactForm from './contact-form';
import { useSlider } from '@mui/base';

const PersonalForm = ({ id, show, onHide, getCity, personalData,formType }) => {

  const [state, changeState, handleUpdate,reset] = useFromInput(personalData)
  const [invalidInput, setInvalidInput] = useState({ valid: false, data: [] });

    const [submitForm,setSubmitForm] = useState(false)


  useEffect(() => {
    console.log(state)
    state.birthPlace && getCity(`?search_city=${state.birthPlace}`);
  }, [state.birthPlace])

  const auth = useSelector(state=>state.auth)
  const userId = auth.auth.id
  console.log('the user id is ',id)

  const handleSubmit = e => {
    console.log("handleSubmit personalData", personalData);
    e.preventDefault();
    setSubmitForm(true)
    console.log("submit", state)
    
    // updatePersonalDetails(shouldUpdateData(state))
    // reset({})
    // hideFormsModals();
  }

  const hideFormsModals = () => {
    if (submitForm) {
      setSubmitForm(false)
    }
    onHide();
  }

  const getFormAccordingToFormType = () => {
    switch (formType) {
      case personalFormTypes.PERSONAL_DETAILS:
        return (
          <PersonalDetailsForm  personalData={personalData} submitForm={submitForm} close={hideFormsModals}/>
        )

        case personalFormTypes.ABOUT:
        return (
          <AboutForm  personalData={personalData} submitForm={submitForm} close={hideFormsModals}/>
        )

        case personalFormTypes.EDUCATION_AND_CAREER :
          return (
            <EducationCareerForm  personalData={personalData} submitForm={submitForm} close={hideFormsModals}/>
          )

          case personalFormTypes.HOROSCOPE :
            return (
              <HoroscopeForm  personalData={personalData} submitForm={submitForm} close={hideFormsModals}/>
            )

            case personalFormTypes.CONTACT :
            return (
              <ContactForm  personalData={personalData} submitForm={submitForm} close={hideFormsModals}/>
            ) 
    
      default:
        break;
    }
  }



  return (
    <Modal
      show={show}
      onHide={hideFormsModals}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <div className='my-profile-form'>
        <header className='d-flex justify-content-space-between align-items-center'>
          <div onClick={hideFormsModals}>
            <svg width="30" height="30" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.75 11.9167H7.39917L11.2775 8.0275L9.75 6.5L3.25 13L9.75 19.5L11.2775 17.9725L7.39917 14.0833H22.75V11.9167Z" fill="black" />
            </svg>
            <span className="header-text">Personal</span>
          </div>
          <button className='btn btn-primary'
          onClick={handleSubmit}>
            Save
          </button>
        </header>

         {
          getFormAccordingToFormType()
         }
      </div>
    </Modal>
  )
}
const mapStateToProps = createStructuredSelector({
  qualification: selectQualification,
  occupation: selectOccupation,
  city: selectCity,
  designation: selectDesignation,
  id: selectId,
  isLead: selectIsLead,
  templeId: selectTempleId,
  identityNumber: selectIdentityNumber,
})

const mapDispatchToProps = dispatch => ({
  getCity: (query) => dispatch(getCity(query)),
  updatePersonalDetails: (data) => dispatch(updatePersonalDetails(data),)
})

export default connect(mapStateToProps, mapDispatchToProps)(PersonalForm);