// CountdownTimer.jsx

import React, { useState, useEffect } from 'react';
import './CountdownTimer.scss'; // Import the CSS file for styles

const CountdownTimer = ({ expireTime }) => {
    const [countdown, setCountdown] = useState('');
    const [borderSize, setBorderSize] = useState(100); // Initial border size (percentage)

    useEffect(() => {
        const calculateCountdown = () => {
            const now = new Date().getTime();
            const distance = expireTime - now;

            if (distance > 0) {
                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);
                setCountdown(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);

                // Calculate border size based on the remaining time
                const totalDuration = expireTime - (new Date().setSeconds(0, 0));
                const remainingPercentage = (distance / totalDuration) * 100;
                setBorderSize(remainingPercentage);
            } else {
                setCountdown('');
            }
        };

        const countdownInterval = setInterval(calculateCountdown, 1000);

        return () => {
            clearInterval(countdownInterval);
        };
    }, [expireTime]);

    return (
        <div className="countdown-timer">
            <div className="clock-border" style={{ width: `${borderSize}%`, height: `${borderSize}%` }} />
            <div className="timer">{countdown}</div>
        </div>
    );
};

export default CountdownTimer;
