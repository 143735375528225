import { createSelector } from "reselect";

import { IMAGE_URL_PREFIX } from '../../utils/api';

import {
  toTitleCase,
  setIncome,
  setHeight,
  setName,
  setAge,
} from '../../utils/display.utils';
import { selectIsPremium } from "../auth/auth.selector";

export const today = state => state.today;

export const selectStatus = createSelector(
  [today],
  today => today.status
)
export const selectIsRated = createSelector(
  [today],
  today => today.is_rated
)
export const selectIsFirstTime = createSelector(
  [today],
  today => today.first_time
)
export const selectProfileCreated = createSelector(
  [today],
  today => today.profile_created
)
export const selectStatusCount = createSelector(
  [today],
  today => today.get_status_count
)
// export const selectData = createSelector(
//   [today],
//   today => (
//     today.data ? today.data.map(item => {
//       // const carousel = JSON.parse(item.carousel);
//       // const keys = Object.keys(carousel);
//       return {
//         about: {
//           verifiedAt: item.facebook ? setVerifiedAt(item.facebook) : 'Hans Matrimony Office',
//           managedBy: item.relation ? item.relation : item.gender === 'Male' ? 'Mother' : 'Father',
//           about: item.about ? item.about.length < 30 ? toTitleCase(setAbout(item)) : toTitleCase(item.about) : toTitleCase(setAbout(item)),

//         },
//         personal: {
//           mobile: item.mobile,
//           locality: item.locality ? item.locality : item.city ? item.city : '',
//           religion: `${item.religion} ${item.religion ? '|' : ''} ${item.caste}`,
//           maritalStatus: item.marital_status,
//           foodChoice: item.food_choice,
//           weight: `${item.weight} Kg`,
//           children: item.children,
//           occupation: item.occupation,
//           profession: item.profession,
//           monthlyIncome: `${setIncome(item.monthly_income)} Lakh per year`,
//           education: item.degree ? item.degree : item.education,
//           college: toTitleCase(item.college),
//           workingCity: item.working_city,
//           birthDate: item.birth_date,
//           birthTime: item.birth_time,
//           birthPlace: item.birth_place,
//           manglik: setManglik(item.manglik)
//         },
//         family: {
//           sister: setMarriageSisters(item.unmarried_daughters, item.married_daughters),
//           brother: setMarriageBrothers(item.unmarried_sons, item.married_sons),
//           mother: LifeStatus(item.mother_status, item.occupation_mother, 'Mother'),
//           father: LifeStatus(item.father_status, item.father_occupation, 'Father'),
//           gotra: item.gotra,
//           familyType: familyType(item),
//           familyLivingIn: item.city ? item.city : item.locality ? item.locality : '',
//           houseStatus: houseStatus(item),
//           familyIncome: `${setIncome(item.family_income)} Lakh per year`,
//         },
//         carousel: [`${IMAGE_URL_PREFIX}${item.photo}`],
//         heading: {
//           title: `${setName(toTitleCase(item.name), item.is_free_profile)}, ${getAge(item.birth_date)}`,
//           subTitle1: `${item.religion} ${item.religion ? '|' : ''} ${item.caste}`,
//           subTitle2: `
//         ${item.working_city ? item.working_city === 'na' || item.working_city === 'NA' || item.working_city === 'Na' ? item.city ? item.city : '' : item.city ? item.city : '' : ''}
//         ${item.working_city ? item.working_city === 'na' || item.working_city === 'NA' || item.working_city === 'Na' ? item.city ? '|' : '' : '|' : ''}
//         ${setHeight(item.height)}
//         `,
//           subTitle3: `${setIncome(item.monthly_income)} Lakh per year`,
//         },
//         id: item.id
//       }
//     })
//       : null
//   )
// )

export const selectData = createSelector(
  [today, selectIsPremium],
  (today, isPremium) => today.data.map(it => getHeading(it, isPremium))
)


const getHeading = (item, isPremium) => {

  return {
    title: `${setName(item.name, isPremium)} | ${setAge(item.birth_date)}`,
    subTitle1: `${item.religion} | ${item.caste ?? ""}`,
    subTitle2: `${item.locality ? item.locality : item.working_city} | ${setHeight(`${item.height}`)}`,
    subTitle3: `${item.occupation ?? ""} | ${item.annual_income / 1} Lpa`,
    photo: item.photo ? IMAGE_URL_PREFIX + item.photo : null,
    userId: item.id,
    item: item,
    mobile: item.user_mobile
  }
}


export const selectCarousel = () => createSelector(
  [today, (_, props) => props.idx],
  (today, idx) => {
    if (today.data[idx].carousel) {
      const carousel = JSON.parse(today.data[idx].carousel);
      const keys = Object.keys(carousel);
      return keys.map(i => `${IMAGE_URL_PREFIX}${carousel[i]}`)
    }
    return null;
  }
)

export const selectSubTitle = () => createSelector(
  [today, (_, props) => props.idx, selectIsPremium],
  (today, idx, isPremium) => {
    if (today.data[idx]) {
      const item = today.data[idx];
      return {
        title: `${setName(toTitleCase(item.name), isPremium)}, ${getAge(item.birth_date)}`,
        subTitle1: `${item.religion} ${item.religion ? '|' : ''} ${item.caste}`,
        subTitle2: `
        ${item.working_city ? item.working_city === 'na' || item.working_city === 'NA' || item.working_city === 'Na' ? item.city ? item.city : '' : item.city ? item.city : '' : ''}
        ${item.working_city ? item.working_city === 'na' || item.working_city === 'NA' || item.working_city === 'Na' ? item.city ? '|' : '' : '|' : ''}
        ${setHeight(item.height)}
        `,
        subTitle3: `${setIncome(item.monthly_income)} Lakh per year`,
      }
    }
    return null;
  }

)

const getAge = (dob) => {
  var month_diff = Date.now() - new Date(dob).getTime();

  var age_dt = new Date(month_diff);

  var year = age_dt.getUTCFullYear();

  var age = Math.abs(year - 1970);

  return age;
}

export const selectProfileKundli = createSelector(
  [today],
  today => today.profile_kundali
)