import React, { useState, useRef } from 'react';
import axios from 'axios';
import './upload.photo.two.scss';
import { uploadProfilePicture } from '../../redux/my-profile/my-profile.action';
import { useDispatch } from 'react-redux';
import { ConstructionOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { selectShowOtp} from '../../redux/auth/auth.selector';
// import { selectIsAuth } from '../../redux/auth/auth.selector';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { textAlign } from '@mui/system';

const ImageUploader = (showOtp) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
 const isAuth = useSelector(state=>state.auth.isAuth)
  useEffect(()=>console.log('is',isAuth),[])
  console.log(showOtp)
  const [images, setImages] = useState([
    { image: null, selected: false },
    { image: null, selected: false },
    { image: null, selected: false },
    { image: null, selected: false },
  ]);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentBoxIndex, setCurrentBoxIndex] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileInputChange = (e) => {
    setCurrentImage(e.target.files[0]);
  };

  const userDetailsData = JSON.parse(localStorage.getItem('userDetailsData'));
  const userId = userDetailsData?.id;
  const gender = userDetailsData?.gender;

  const handleBoxClick = (index) => {
    setCurrentBoxIndex(index);
    const input = fileInputRef.current;
    if (input) {
      input.click();
    }
  };
  
   const handleClick = ()=>{
    if(showOtp && isAuth ){
      navigate('/chat')
    }
  }
   

  const handleBoxChange = async (index, file) => {
    const newImages = [...images];
    newImages[index] = { image: file, selected: true };
    setImages(newImages);
    setCurrentImage(file);
    const formData = new FormData();
    formData.append('profilePicture', file);
    if (currentBoxIndex !== null) {
      await dispatch(uploadProfilePicture(userId, formData));
      const newImages = [...images];
      newImages[currentBoxIndex] = { image: file, selected: true };
      setImages(newImages);
      setCurrentImage(null);
      setCurrentBoxIndex(null);
    } else {
      alert('Please select an upload box first.');
    }
  };

  const boxStyle = (index) => ({
    backgroundImage: images[index].image
      ? `url(${URL.createObjectURL(images[index].image)})`
      : gender === 'Male'
      ? 'url(https://thumbs.dreamstime.com/b/businessman-icon-vector-male-avatar-profile-image-profile-businessman-icon-vector-male-avatar-profile-image-182095609.jpg)'
      : 'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8KzFRMfVR6-4_yNQGGXg9D213g8T1cEb17Q&usqp=CAUckground.jpg)',
    width: index === 0 ? '300px' : '110px',
    height: index === 0 ? '400px' : '110px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    border: index === currentBoxIndex ? '2px solid blue' : 'none',
    margin: index === 0 ? '0 auto' : 'none',
    marginBottom: index === 0 ? '30px' : 'none',
  });

  return (
    <div>
      <h2 className='upload-header'>
        <span className='popper'>🎉</span>Win 5 free chats on completing your profile!
      </h2>
      <form>
        <div className="upload-box-container">
          {images.map((image, index) => (
            <div key={index} className='upload-box' style={boxStyle(index)} onClick={() => handleBoxClick(index)}>
              {index === currentBoxIndex && (
<div className='overlay'>
<Button  variant='contained' component='label' style = {{display:'none'}}>
{image.selected ? 'Change Photo' : 'Upload Photo'}
<input type='file' hidden ref={fileInputRef} onChange={(e) => handleBoxChange(index, e.target.files[0])} />
</Button>
</div>
)}
</div>
))}
</div>
<div style= {{textAlign:'center',marginTop:'30px'}}>
<Button onClick = {handleClick} variant = 'contained' >See Profiles</Button>
</div>

</form>
</div>
);
};

const mapStateToProps = createStructuredSelector({
  // isAuth: selectIsAuth,
  showOtp: selectShowOtp
})

const mapDispatchToProps = dispatch => ({
  // verifyOtp: (data) => dispatch(verifyOtp(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploader);
