import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { searchCity } from '../../../redux/register/register.action'
import { selectCity } from '../../../redux/register/register.selector'

function LocationSelect({ value, searchedCity, handler, styles, searchCity, placeholder, name }) {

    const [locationText, setLocationText] = useState("")

    const filterHandler = (e, v) => {
        console.log(e, v)
        if (v.inputValue !== locationText) {
            setLocationText(v.inputValue)
        }
        return e
    }

    useEffect(() => {
        searchCity(locationText)
    }, [locationText])

    return (
        <Autocomplete
            className={styles}
            id={name ?? "locality"}
            name={name ?? "locality"}
            filterOptions={filterHandler}
            options={searchedCity.map(it => it.cityName)}
            sx={{ width: 300 }}
            defaultValue={value}
            onChange={handler}
            renderInput={(params) => <TextField {...params} label={placeholder ?? "Locality"} />}
        />
    )
}

const mapStateToProps = createStructuredSelector({
    searchedCity: selectCity
})

const mapDispatchToProps = dispatch => ({
    searchCity: (city) => dispatch(searchCity(city)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LocationSelect)