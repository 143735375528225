import React from "react";
import { useState, useEffect } from "react";
import Modal from "react-modal";
import "./footer.styles.scss";

import blob1 from "../../../components/assets/footer/blob1.svg";
import blob2 from "../../../components/assets/footer/blob2.svg";
import googleplay from "../../../components/assets/newAppIllustrations/googleplay.jpg";
import facebook from "../../../components/assets/footer/facebook.svg";
import linkedin from "../../../components/assets/footer/linkedin.svg";
import twitter from "../../../components/assets/footer/twitter.svg";
import instagram from "../../../components/assets/footer/instagram.svg";
import hand from "../../../components/assets/footer/hand.svg";
import hansLogo from "../../../components/assets/footer/HansLogo.png";
import imagebackgroundblue from "../../../components/assets/footer/imagebackgroundblue.svg";
import Frame8 from "../../../components/assets/footer/Frame8.svg";
import castes from "../../../components/assets/footer/castes.png";
import Frame9 from "../../../components/assets/footer/Frame9.svg";
import googleplaypic from "../../../components/assets/footer/googleplay.png";
import rating from "../../../components/assets/footer/rating.png";
import googleplayimg from "../../../components/assets/footer/googleplayimg.svg";
import sidetagimage1 from "../../../components/assets/footer/sidetagimage1.svg";
import sidetagimage2 from "../../../components/assets/footer/sidetagimage2.svg";
import sidetagimage3 from "../../../components/assets/footer/sidetagimage3.svg";
import footerdownloadimage from "../../../components/assets/footer/footerdownloadimage.svg";
import socialmediaimage1 from "../../../components/assets/footer/socialmediaimage1.svg";
import socialmediaimage2 from "../../../components/assets/footer/socialmediaimage2.svg";
import socialmediaimage3 from "../../../components/assets/footer/socialmediaimage3.svg";
import socialmediaimage4 from "../../../components/assets/footer/socialmediaimage4.svg";
import socialmediaimage5 from "../../../components/assets/footer/socialmediaimage5.svg";
import Hansnewlogo2 from "../../../components/assets/footer/Hansnewlogo 2.svg";
import WhatsApp1 from "../../../components/assets/footer/WhatsApp1.svg";
import { WhatsApp, WindowSharp } from "@mui/icons-material";

const Footer = (props) => {
  console.log("props", props);
  console.log(props.Subscription);
  let footerClass = "download-img-div";
  let disclaimerClass = "disclaimer";
  let companyInfoClass = "footer-company-info-div";
  if (props.Subscription) {
    console.log("props.Subscription", props.Subscription);
    footerClass = "download-img-div1";
    disclaimerClass = "disclaimer1";
    companyInfoClass = "footer-company-info-div1";
  }
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const phoneNumber = "+919697989697";
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let view;
  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };
  const handlePrivacyPolicyClick = () => {
    window.location.href = "https://hansmatrimony.com/privacy";
  };
  const handltandcClick = () => {
    window.location.href = "https://hansmatrimony.com/termsCondition";
  };
  const [phone, setPhone] = useState("+919697989697");

  const handleOnClick = () => {
    const telUrl = `tel:${phone}`;
    window.location.href = telUrl;
  };
  const handleClickWhatsapp = () => {
    window.open("https://wa.me/+918178083559", "_blank");
  };
  const handleClickWhatsappDesktop = () => {
    window.open("https://web.whatsapp.com/send?phone=+917042045754", "_blank");
  };
  const handleClick = () => {
    window.open("https://play.google.com/store/apps/details?id=com.twango.me");
  };

  const handleClick1 = () => {
    window.open("https://www.facebook.com/HansMatrimony");
  };
  const handleClick2 = () => {
    window.open("https://www.instagram.com/hansmatrimony/");
  };
  const handleClick3 = () => {
    window.open("https://www.linkedin.com/company/hansmatrimony/");
  };
  const handleClick4 = () => {
    window.open("https://twitter.com/HansMatrimony");
  };
  const handleClick5 = () => {
    window.open("https://www.youtube.com/@hansmatrimony");
  };
  if (!isMobile) {
    view = (
      <div className="app-footer">
        <div className="rating-image">
          <img src={rating} alt="rating" className="imgRating" />
          {/* <div className="gplayimg"> */}
          <img
            src={googleplaypic}
            alt="gplay "
            className="gplay"
            onClick={handleClick}
          />
          {/* </div> */}
        </div>
        <div className="footer">
          <div className="footer1">
            <h4 className="company-footer-div-header1">
              Browse Matrimonial profiles by
            </h4>
            <div className="footer-header">
              <p>Religion</p>
              <p>Occupation</p>
              <p>Caste</p>
              <p>State</p>
            </div>

            <div className="footer-casts">
              <img src={castes} alt="" className="castes" />
            </div>
          </div>
          <div className="footer-bottom">
            <div className="company-footer-div">
              <h4 className="company-footer-div-header">Company</h4>
              <p>Experienced Matchmaker</p>
              <p>
                <a
                  href="https://hansmatrimony.com/blog"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  Hans Blog
                </a>
              </p>
              <p>Success Stories</p>
              <p>Kundali Matching</p>
              <p onClick={handleOpenModal}>Careers</p>
              <p onClick={handlePrivacyPolicyClick}>Privacy Policy</p>
              <p onClick={handltandcClick}>Terms & Conditions</p>
              <p>FAQ’s</p>
            </div>
            <div className="footer-contact-us-div">
              <h4 className="company-footer-div-header">Contact Us</h4>
              <p onClick={handleOnClick}>+919697989697</p>
              <p onClick={handleClickWhatsappDesktop}>
                Chat with us <img src={WhatsApp1} alt="whatsapp" />
              </p>

              <p
                onClick={() =>
                  (window.location = "mailto:987654321akbr@gmail.com")
                }
              >
                matches@hansmatrimony.com
              </p>
              <p
                onClick={() => {
                  window.open(
                    "https://www.google.com/maps/place/Hans/@28.6536055,76.985152,12z/data=!4m10!1m2!2m1!1shans+matrimony!3m6!1s0x390d031611f159c3:0x54a8dee2196eb706!8m2!3d28.6536055!4d77.1293476!15sCg5oYW5zIG1hdHJpbW9ueVoQIg5oYW5zIG1hdHJpbW9ueZIBD3dlZGRpbmdfc2VydmljZZoBJENoZERTVWhOTUc5blMwVkpRMEZuU1VOUGFHRlRMVGRCUlJBQuABAA!16s%2Fg%2F11f5lychtw"
                  );
                }}
              >
                Office : H-18, Block F, Bali Nagar, New Delhi, Delhi 110015
              </p>
            </div>
            {/* <div className="footer-information-div">
              <h4 className="company-footer-div-header">Information</h4>
              <p onClick={handlePrivacyPolicyClick}>Privacy Policy</p>
              <p onClick={handltandcClick}>Terms & Conditions</p>
              <p>FAQ’s</p>
            </div> */}

            <div className="follow-us-div">
              <h4 className="company-footer-div-header">Follow Us On :</h4>
              <div className="image-div-con">
                <p onClick={handleClick2}>
                  <img
                    className="socialmediaimage1"
                    src={socialmediaimage1}
                    alt="instagram-logo"
                    onClick={handleClick2}
                  />{" "}
                  Instagram
                </p>
                <p onClick={handleClick1}>
                  <img
                    className="socialmediaimage2"
                    src={socialmediaimage2}
                    alt="facebook-logo"
                    onClick={handleClick1}
                  />{" "}
                  Facebook
                </p>
                <p onClick={handleClick4}>
                  <img
                    className="socialmediaimage3"
                    src={socialmediaimage3}
                    alt="twitter-logo"
                    onClick={handleClick4}
                  />{" "}
                  Twitter
                </p>
                <p onClick={handleClick3}>
                  <img
                    className="socialmediaimage4"
                    src={socialmediaimage4}
                    alt="linkedin-logo"
                    onClick={handleClick3}
                  />{" "}
                  LinkedIn
                </p>
                <p onClick={handleClick5}>
                  <img
                    className="socialmediaimage5"
                    src={socialmediaimage5}
                    alt="youtube-logo"
                    onClick={handleClick5}
                  />{" "}
                  Youtube
                </p>
              </div>
            </div>
            <div className="footer-subs-div">
              <h4 className="company-footer-div-header">Subscription</h4>
              {/* <a href="/subscription"> */}
              <p href="/subscription">Personalized Services</p>
              {/* </a> */}
              {/* <p>Self Service</p> */}
            </div>
          </div>
          <div className={companyInfoClass}>
            <div className="brandimagelogo">
              <img className="Hansnewlogo2" src={hansLogo} alt="Hansnewlogo2" />
              {/* <p style={{ fontSize: "21px" }}> Hans Matrimony</p> */}
            </div>
            <p
              className="footer-p"
              style={{ textAlign: "center", marginTop: "5px" }}
            >
              © 2022, Twango Social Network Pvt. Ltd
            </p>
          </div>
          <div className={footerClass}>
            {/* <h5 style={{ fontWeight: "600" }}>Download our App</h5> */}
            <img
              className="footerdownloadimage"
              src={footerdownloadimage}
              alt="footerdownloadimage"
              onClick={handleClick}
            />
          </div>
          <p className={disclaimerClass}>
            Disclaimer: To make your experience better we might use your details
            for marketing purposes. Our wedding experts might contact to
            understand your need and help you get high quality wedding services.
          </p>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Phone Number Modal"
          style={{
            overlay: {
              zIndex: 9999, // Set z-index for the modal overlay
            },
            content: {
              height: "200px", // Adjust the height here as needed
              margin: "auto",
              maxWidth: "80%",
              background: "#fff",
              borderRadius: "10px",
              padding: "20px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
              textAlign: "center",
              zIndex: "103",
            },
          }}
        >
          <h2>Contact us :</h2>
          <p>{phoneNumber}</p>
          <button
            onClick={handleCloseModal}
            style={{
              backgroundColor: "#1E81CE",
              color: "#fff",
              borderRadius: "5px",
            }}
          >
            Close
          </button>
        </Modal>
      </div>
    );
  } else {
    view = (
      <div className="app-footer">
        <div
          className="mob-res-container "
          style={{
            background:
              "linear-gradient(324.32deg, #265682 13.09%, #2468A1 29.38%, #1F7DC6 101.07%)",
            height: " 79vh",
          }}
        >
          <div className="background-con-div">
            {/* <img className="imagebackgroundblue" src={imagebackgroundblue} alt="hansmatrimony-background" style={{width:'100vw' ,position : 'absolute'}}/> */}
            <p className="footer-text-con1">
              The only Matrimony app you’ll ever need
            </p>
            <p className="footer-text-con">
              Seamlessly connect with verified profiles & enjoy a great user
              experience. Download the App now. Start your journey to meet your
              perfect match.
            </p>
            <img
              click="downloadApp()"
              className="footer-googleplay"
              src={googleplayimg}
              alt="hansmatrimony-app-download"
              onClick={handleClick}
            />
            {/* <img className="imagebackgroundblue" src={imagebackgroundblue} alt="hansmatrimony-background" style={{width:'100vw' , position :'relative' ,top : '277px',marginBottom : '10vh'}}/> */}
          </div>
          <div>
            <div>
              <img className="Frame8" src={Frame8} alt="hansmatrimony-Frame8" />
              <img
                className="sidetagimage1"
                src={sidetagimage1}
                alt="hansmatrimony-sidetagimage1"
              />
              <img
                className="sidetagimage2"
                src={sidetagimage2}
                alt="hansmatrimony-sidetagimage2"
              />
            </div>
            <div>
              <img className="Frame9" src={Frame9} alt="hansmatrimony-Frame9" />
              <img
                className="sidetagimage3"
                src={sidetagimage3}
                alt="hansmatrimony-sidetagimage3"
              />
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="footer1">
            <h4 className="company-footer-div-header1">
              Browse Matrimonial profiles by
            </h4>
            <div className="footer-header">
              <p>Religion</p>
              <p>Occupation</p>
              <p>Caste</p>
              <p>State</p>
            </div>
            <div className="footer-casts"></div>
          </div>
          <div className="footer-bottom">
            <div className="company-footer-div">
              <h4 className="company-footer-div-header">Company</h4>
              <p>Experienced Matchmaker</p>
              <p>
                <a
                  href="https://hansmatrimony.com/blog"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  Hans Blog
                </a>
              </p>
              <p>Success Stories</p>
              <p>Kundali Matching</p>
              <p onClick={handleOpenModal}>Careers</p>
            </div>
            <div className="footer-contact-us-div">
              <h4 className="company-footer-div-header">Contact Us</h4>
              <p onClick={handleOnClick}>+919697989697</p>
              <p onClick={handleClickWhatsapp}>Chat with us</p>

              <p
                onClick={() =>
                  (window.location = "mailto:987654321akbr@gmail.com")
                }
              >
                matches@hansmatrimony.com
              </p>
              <p
                onClick={() => {
                  window.open(
                    "https://www.google.com/maps/place/Hans/@28.6536055,76.985152,12z/data=!4m10!1m2!2m1!1shans+matrimony!3m6!1s0x390d031611f159c3:0x54a8dee2196eb706!8m2!3d28.6536055!4d77.1293476!15sCg5oYW5zIG1hdHJpbW9ueVoQIg5oYW5zIG1hdHJpbW9ueZIBD3dlZGRpbmdfc2VydmljZZoBJENoZERTVWhOTUc5blMwVkpRMEZuU1VOUGFHRlRMVGRCUlJBQuABAA!16s%2Fg%2F11f5lychtw"
                  );
                }}
              >
                Office : H-18, Block F, Bali Nagar, New Delhi, Delhi 110015
              </p>
            </div>
            <div className="footer-information-div">
              <h4 className="company-footer-div-header">Information</h4>
              <p onClick={handlePrivacyPolicyClick}>Privacy Policy</p>
              <p onClick={handltandcClick}>Terms & Conditions</p>
              <p>FAQ’s</p>
            </div>
            <div className="footer-subs-div">
              <h4 className="company-footer-div-header">Subscription</h4>
              <p>Personalized Services</p>
              {/* <p>Self Service</p> */}
            </div>
            <div className="download-img-div">
              <img
                className="footerdownloadimage"
                src={footerdownloadimage}
                alt="footerdownloadimage"
                onClick={handleClick}
              />
            </div>
            <div className="follow-us-div">
              <h4 className="follow-us-div-header">Follow Us On :</h4>
              <div className="image-div-con">
                <img
                  className="socialmediaimage1"
                  src={socialmediaimage1}
                  alt="instagram-logo"
                  onClick={handleClick2}
                />
                <img
                  className="socialmediaimage2"
                  src={socialmediaimage2}
                  alt="facebook-logo"
                  onClick={handleClick1}
                />
                <img
                  className="socialmediaimage3"
                  src={socialmediaimage3}
                  alt="twitter-logo"
                  onClick={handleClick4}
                />
                <img
                  className="socialmediaimage4"
                  src={socialmediaimage4}
                  alt="linkedin-logo"
                  onClick={handleClick3}
                />
                <img
                  className="socialmediaimage5"
                  src={socialmediaimage5}
                  alt="youtube-logo"
                  onClick={handleClick5}
                />
              </div>
            </div>
            <div className="footer-company-info-div">
              {/* <div className="brandimagelogo">
            <img className="Hansnewlogo2" src={Hansnewlogo2} alt="Hansnewlogo2" />
            <p style={{fontSize : '21px'}}> Hans Matrimony</p>
            </div> */}
              <p className="footer-p" style={{ textAlign: "center" }}>
                Copyright @ 2022 hansmatrimony.com
              </p>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Phone Number Modal"
          style={{
            overlay: {
              zIndex: 9999, // Set z-index for the modal overlay
            },
            content: {
              height: "200px", // Adjust the height here as needed
              margin: "auto",
              maxWidth: "80%",
              background: "#fff",
              borderRadius: "10px",
              padding: "20px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
              textAlign: "center",
              zIndex: "103",
            },
          }}
        >
          <h2>Contact us :</h2>
          <p>{phoneNumber}</p>
          <button
            onClick={handleCloseModal}
            style={{
              backgroundColor: "#1E81CE",
              color: "#fff",
              borderRadius: "5px",
            }}
          >
            Close
          </button>
        </Modal>
      </div>
    );
  }
  return view;
};

export default Footer;

{
  /*             <img className="footer-icons" src={facebook} alt="facebook-icon" />
                <img className="footer-icons" src={twitter} alt="twitter-icon" />
                <img className="footer-icons" src={instagram} alt="instagram-icon" />
                <img className="footer-icons" src={linkedin} alt="linkedin-icon" />
              <p className='footer-p'>Copyright @ 2020 hansmatrimony.com</p> */
}
