

const RegisterActionTypes = {
  GET_ALL_CASTE_START: 'GET_ALL_CASTE_START',
  GET_ALL_CASTE_SUCCESS: 'GET_ALL_CASTE_SUCCESS',
  GET_ALL_CASTE_FAILURE: 'GET_ALL_CASTE_FAILURE',
  GET_CITY: 'GET_CITY',
  GET_STATE: 'GET_STATE',
  UPDATE_BASIC_START: 'UPDATE_BASIC_START',
  UPDATE_BASIC_SUCCESS: 'UPDATE_BASIC_SUCCESS',
  UPDATE_BASIC_FAILURE: 'UPDATE_BASIC_FAILURE',
}

export default RegisterActionTypes;