
import {  API, FETCH_FROM_NODE, APP_URL_PREFIX_NODE } from '../../utils/api';

import {setError} from '../error/error.action';
import TodaySpecialActionTypes from './today-special.types';

import {getCredits} from '../auth/auth.action';
import { removeAndUpdatePopularList } from '../popular/popular.action';
import { removeAndUpdateNewlyJoinedList } from '../newly-joined/newly-joined.action';
import axios from 'axios';


export const getTodayProfiles = (data) => {
  return dispatch => {
    dispatch({ type: TodaySpecialActionTypes.GET_TODAY_PROFILE_START });
    FETCH_FROM_NODE({
      url: `${API.TODAY_PROFILE_URL}?userId=${data.userId}&pref_changed=${data.pref_changed}`,
      method: 'GET',
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {
          dispatch({
            type: TodaySpecialActionTypes.GET_TODAY_PROFILE_SUCCESS,
            payload: resData
          });
      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: TodaySpecialActionTypes.GET_TODAY_PROFILE_FAILURE });
      });
  };

}

export const postProfileAction = data => {

  return dispatch => {
    dispatch({ type: TodaySpecialActionTypes.POST_PROFILE_ACTION_START })
    FETCH_FROM_NODE({
      method: 'POST',
      url: `${API.SAVE_TODAYS_ACTION_URL}?userIdFrom=${data.userIdFrom}&userIdTo=${data.userIdTo}&actionType=${data.actionType}&isTodaySpecial=${data.isTodaySpecial}`,
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {
        if (resData.status === 2) {
          throw new Error(resData);
        }
        // ############## utility dispatch function based on condition. ################
        switch (data.isTodaySpecial) {
          case "2":
            dispatch(removeAndUpdatePopularList(data.userIdTo))
            break;

          case "3":
            dispatch(removeAndUpdateNewlyJoinedList(data.userIdTo))
            break;
        
          default:
            removeAndUpdateTodaySpecialList(data.userIdTo)
            dispatch(getTodayProfiles({userId: data.userIdFrom, pref_changed: 0}))
            break;
        }
        
        dispatch(getCredits({is_lead: data.is_lead, id: data.userIdFrom}));
        
        dispatch({
          type: TodaySpecialActionTypes.POST_PROFILE_ACTION_SUCCESS,
          payload: resData
        });
        
      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: TodaySpecialActionTypes.POST_PROFILE_ACTION_FAILURE });
      });
  }
}

export const matchKundali = (data) => {
  return async dispatch => {
      dispatch({type: TodaySpecialActionTypes.GET_KUNDALI_START})

    try {
      let response = await axios.post(`${APP_URL_PREFIX_NODE}${API.GET_KUNDALI}`,data)

     if (response.data.status) {
          dispatch({type: TodaySpecialActionTypes.GET_KUNDALI_SUCCESS,
          payload: response.data.message.data})
     }

    } catch (error) {
        setError(error)
        dispatch({type: TodaySpecialActionTypes.GET_KUNDALI_FAILURE})
    }

      
  }
}


export const resetKundli = () => dispatch => dispatch({type: TodaySpecialActionTypes.RESET_KUNDLI})


function removeAndUpdateTodaySpecialList(id) {
  return dispatch => {
    dispatch({type: TodaySpecialActionTypes.REMOVE_AND_UPDATE_ITEM_FROM_TODAY_SPECIAL_LIST,
    payload: id})
  }
}


