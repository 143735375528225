import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2/dist/sweetalert2.js";

import "sweetalert2/src/sweetalert2.scss";
import Form from "react-bootstrap/Form";
import PhoneInput from "react-phone-number-input/input";
import Vectorcircle from "../../../components/assets/hero/Vectorcircle.png";
import Vectorflower from "../../../components/assets/hero/Vectorflower.png";
import Frame1 from "../../../components/assets/hero/Frame1.svg";
import Frame2 from "../../../components/assets/hero/Frame2.svg";
// import vectorBottom from "../../../components/assets/hero/VectorBottom.svg"
// import stripes from "../../../components/assets/hero/stripes.svg"
// import stripes2 from "../../../components/assets/hero/stripes2.svg"
import illsvg from "../../../components/assets/hero/illsvg.png";
import illsvg1 from "../../../components/assets/hero/illsvg1.svg";
import heroimage2 from "../../../components/assets/hero/heroimage2.png";
import frame1 from "../../../components/assets/Frame1.png";
import frame2 from "../../../components/assets/Frame2.png";
import backgroundellipse from "../../../components/assets/hero/backgroundellipse.svg";
import backgroundellipse1 from "../../../components/assets/backgroungOfIllustrationImage.png";
import backgroundellipse2 from "../../../components/assets/Ellipse2.png";
import flowers from "../../../components/assets/flowers.png";
import "./hero.styles.scss";
import { Link } from "react-router-dom";
import { selectName } from "../../../redux/auth/auth.selector";
import { color } from "@mui/system";
import axios from "axios";

const Hero = (props) => {
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 768); // Change the breakpoint as needed

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth < 768); // Change the breakpoint as needed
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // let smallScreen = true;
  let view;
  const navigate = useNavigate();
  const [groombride, setGroomBride] = useState("bride");
  const [selectedValue, setSelectedValue] = useState("");
  const [name, setName] = useState();
  const [number, setNumber] = useState();
  // const [lookingFor, setLookingFor] = useState();
  const handleChange = (e) => {
    e.preventDefault();
    console.log(e.target.value);
  };

  const Swal = require("sweetalert2");

  const [registerData, setRegisterData] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [lookingFor, setLookingFor] = useState("Myself");
  //
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!fullName || !phoneNumber || !lookingFor) {
      alert("Please fill in all fields.");
      return;
    }
    try {
      const response = await fetch(
        "https://partner.hansmatrimony.com/api/save-incomplete-leads",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_phone: phoneNumber,
            for: lookingFor,
            cname: fullName,
            channel: "google_ads",
          }),
        }
      );

      if (response.ok) {
        console.log("Lead saved successfully");
        toast.success(
          "Your data has been recieved our team will contact you soon!"
        );
        setFullName("");
        setPhoneNumber("");
        setLookingFor("myself");
      } else {
        console.error("Failed to save lead");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (!smallScreen) {
    view = (
      <>
        <ToastContainer toastClassName="custom-toast" />
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img className="flowers" src={flowers} alt="flowers" />
              <img
                src={backgroundellipse2}
                alt="ellipse2"
                className="ellipse2"
              />
              <img
                className="backgroundellipse1"
                src={backgroundellipse1}
                alt="backgroundellipse1"
              />
              <img
                className="hero-illustration"
                src={illsvg1}
                alt="hansmatrimony-Illustration-big-new"
              />
              <img src={frame1} alt="frame1" className="frame1" />
              <img src={frame2} alt="frame2" className="frame2" />
            </div>
            <div className="col-md-6">
              <div className="form-container">
                <form onSubmit={handleFormSubmit}>
                  {/* <div className="form-heading"> */}
                  <h6 className="Heading">
                    Upper/Middle Class Family’s Rishtey!
                  </h6>
                  {/* </div> */}
                  <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Enter your name"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone">Phone:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      placeholder="Enter your phone number"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="select">Looking For:</label>

                    <select
                      className="form-control"
                      id="select"
                      onChange={(e) => setLookingFor(e.target.value)}
                    >
                      <option value="myself">Myself</option>
                      <option value="son">Son</option>
                      <option value="daughter">Daughter</option>
                      <option value="brother">Brother</option>
                      <option value="sister">Sister</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Register Now
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    // new-landing-page-hero-image-changes
    view = (
      <div
        className="text-con-div"
        style={{ position: "relative", top: "8vh" }}
      >
        <div className="mobileScreen">
          <div ngIf="smallScreen">
            <div className="d-flex justify-content-center">
              <div className="land-hamburger">
                {/* <img className="" src={heroimage2} alt="background-ellipse"  style={{width: '107%',left: '12px',top: '21vh',position: 'relative'}}/> */}
                <div class="hero-container">
                  <img
                    class="hero-illustration-image-1"
                    src={heroimage2}
                    alt="background-ellipse"
                  />
                  <img
                    class="hero-illustration-image-2"
                    src={illsvg1}
                    alt="hansmatrimony-Illustration-big-new"
                  />
                  <div class="text-overlay">
                    <h3>We Specialize in Elite Alliances</h3>
                    <p style={{ marginLeft: "30px" }}>
                      Finding a Suitable Match Just Became Easier
                    </p>
                  </div>
                </div>

                {/* <button className='login-btn-mobile-res' onClick={(e) =>handleSubmit(e)} style={{left:'78vw' , position:'relative',bottom:'37vh'}}> Login </button> */}
                {/* <img className="mobile-menu-side-opener-btn" src={sidemenubtn} alt="sidemenubtn" /> */}

                {/* <div className="land-line"></div>
              <div className="land-line"></div>
              <div className="land-line"></div> */}
              </div>
            </div>
          </div>
          <div ngIf="smallScreen">
            <div className="form-con-div" style={{ marginTop: "40vh" }}>
              {/* <div className="d-flex justify-content-center" style={{background: 'rgba(53, 119, 179, 0.55)'}}>
              <h3 className='hero-h2' style={{paddingBlock : '10px'}}>Register for free in less than 60 seconds </h3>
            </div> */}

              <div
                className="d-flex justify-content-center"
                style={{ height: "20px" }}
                //  style={{background: 'rgba(189, 219, 242, 0.71)'}}
              >
                {/* form for laptop view  */}
                <div className="laptop-view-con">
                  <div className="container">
                    <div className="row">
                      <div className="col-1"></div>
                      <div className="col-5">
                        <p>Searching a</p>
                        <div className="d-flex hero-btn-holder">
                          {groombride === "bride" ? (
                            <div style={{ display: "flex" }}>
                              <button
                                onClick={() => setGroomBride("groom")}
                                className="btn hero-btn"
                              >
                                Groom
                              </button>
                              <button className="btn glow">Bride</button>
                            </div>
                          ) : (
                            <div style={{ display: "flex" }}>
                              <button className="btn glow">Groom</button>
                              <button
                                onClick={() => setGroomBride("bride")}
                                className="btn hero-btn"
                              >
                                Bride
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-6">
                        <p id="paragraph">For</p>

                        <div className="d-flex justify-content-center">
                          <div className="hero-dr-bt">
                            {/* <button type="button" className="btn hero-btn hero-myself-btn">{"Name"}</button>
                          <div className="btn-group" placement="top" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                            <button className="btn hero-btn hero-down" ngbDropdownToggle>)</button> */}
                            {/* <div className="dropdown-menu" ngbDropdownMenu>
                              <button className='btn hero-btn' click="mySelf(myself.id)" id="myself" ngbDropdownItem>Myself</button>
                              <button className='btn hero-btn' click="mySelf(daughter.id)" id="daughter" ngbDropdownItem>Daughter</button>
                              <button className='btn hero-btn' click="mySelf(son.id)" id="Son" ngbDropdownItem>Son</button>
                              <button className='btn hero-btn' click="mySelf(brother.id)" id="brother" ngbDropdownItem>Brother</button>
                              <button className='btn hero-btn' click="mySelf(sister.id)" id="sister" ngbDropdownItem>Sister</button>
                              <button className='btn hero-btn' click="mySelf(other.id)" id="other" ngbDropdownItem>Other</button>
                            </div> */}
                            <div>
                              <select
                                onChange={(e) => handleChange(e)}
                                className="btn hero-btn hero-myself-btn"
                              >
                                <option className="btn hero-btn">Myself</option>
                                <option className="btn hero-btn">Son</option>
                                <option className="btn hero-btn">
                                  Daughter
                                </option>
                                <option className="btn hero-btn">
                                  Brother
                                </option>
                                <option className="btn hero-btn">Sister</option>
                                <option className="btn hero-btn">Other</option>
                              </select>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      {/* <button click="goToCompatibility()"
                      routerLinkActive="router-link-active"
                      className="btn hero-btn hero-register-btn">Register For Free</button> */}
                    </div>
                  </div>
                </div>

                {/* form for mobile responsive container */}
                <div
                  id="register-now-redirect"
                  className="mobile-responsive-form-con"
                  style={{ justifyContent: "center", flexDirection: "column" }}
                >
                  <Button
                    className="register-button-form-con-1 form-1-con-btn"
                    variant="primary"
                    type="submit"
                    onClick={() => navigate("/phone-number")}
                    // onClick={(e) => {handleSubmit(e)}}
                  >
                    Register Now
                  </Button>

                  {/* <Form className='form-1-con'>
                <label className='form-1-con-name'> Name 
                  <input className='form-con' type = 'text' 
                  
                  value={name}
                  onChange = {(e) => setName(e.target.value)}/>
                </label>

                <label className='form-1-con-name'> Phone  
                  <input className='form-con' type = 'text' 
                  
                  value={number}
                  onChange = {(e) => setNumber(e.target.value)}/>
                </label>

                <p className='para' style={{marginTop : '0px' , marginBottom : '0px'}}>Looking For </p>
                <select className="form-select"  value = {lookingFor} onChange={(e) => setLookingFor(e.target.value)}>
                  <option className='btn hero-btn'>Myself</option>
                  <option className='btn hero-btn'>Son</option>
                  <option className='btn hero-btn'>Daughter</option>
                  <option className='btn hero-btn'>Brother</option>
                  <option className='btn hero-btn'>Sister</option>
                  <option className='btn hero-btn'>Other</option>
                </select>
                <Button className='form-1-con-btn' variant="primary" type="submit"  onClick={(e) => {handleSubmit(e)}}>
                  Register Now 
                </Button>
                
                <p className='text' style={{ textAlign :'center' , fontSize : '10px' , marginBottom : '24px'}}>By clicking on Register Now, you confirm that you accept the <span style={{color :'#00008B' , fontWeight : 'bold'}}>Terms of Use {" "}</span> and <span style={{color : '#00008B' , fontWeight : 'bold'}}>Privacy Policy . {" "}</span> </p>
              </Form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div fade="@fade" className="hero">
      <div id="vectors">
        {/* <img src={Vectorcircle} alt="hansmatrimony-vector-1" className="hero-vector-bottom" style={{ width: "147px", height: '138px' }} /> */}
        {/* <img src={Vectorflower} alt="hansmatrimony-vector-2" className="hero-stripes" style={{ width: "34px", height: '34px'  , left : '20px '}} /> */}
        {/* <img src={Vectorflower} alt="hansmatrimony-vector-2" className="hero-stripes" style={{ width: "34px", height: '34px'  , left : '20px '}} /> */}
        {/* <img src={Frame1} alt="hansmatrimony-vector-3" className="hero-stripes2" style={{ width: "86px", height: '89px' }} /> */}
        {/* <img src={Frame2} alt="hansmatrimony-vector-4" className="stripes3"/>  */}
      </div>
      {view}
    </div>
  );
};

export default Hero;
