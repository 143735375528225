import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectMaritalMapping } from '../../../redux/mappedData/mappedData.selector'

function MaritalStatusSelect({value,maritalStatus,handler,styles}) {
    return (
        <Autocomplete
        className={styles}
        id="martial_status"
        name="martial_status"
        options={maritalStatus.map(it => it.name)}
        sx={{ width: 300 }}
        defaultValue={value}
        onChange={handler}
        renderInput={(params) => <TextField {...params} label="Marital Status" />}
      />
      )
}

const mapStateToProps = createStructuredSelector({
    maritalStatus: selectMaritalMapping
})

export default connect(mapStateToProps,null)(MaritalStatusSelect)
