import UserChatActionTypes  from './user-chat.types';

const INITITAL_STATE = {
    openChat: false,
    status: null,
    userReference: null,
    rooms: null,
    messages : [],
    activeListener: null,
    roomsLoader: false
  }

  const addToArrayAndReturn = (existingArray,newItemm) => {
    const newArray = [...existingArray]
     newArray.push(newItemm);
     return newArray;
  }

  const userChatReducer = (state = INITITAL_STATE, action) => {
    switch (action.type) {
      case UserChatActionTypes.OPEN_USER_CHAT :
        return {
          ...state,
          openChat: !state.openChat
        }
      case UserChatActionTypes.GET_USER_CHAT_ROOMS_SUCCESS:
        return {
          ...state,
          rooms: action.payload
        }
        case UserChatActionTypes.GET_USER_CHAT_REFERENCE_SUCCESS:
        return {
            ...state,
            userReference: action.payload
        }
        case UserChatActionTypes.GET_USER_CHAT_ROOMS_MESSAGES_SUCCESS:
        return {
            ...state,
            messages: addToArrayAndReturn(state.messages,action.messages),
            activeListener: action.activeListener
        }
        case UserChatActionTypes.RESET_USER_CHAT_ROOMS_MESSAGES:
          if (typeof state.activeListener == 'function') {
            state.activeListener();
          }

        return {
            ...state,
            messages: []
        }

        case UserChatActionTypes.NEW_USER_CREATION_START:
          return {
            ...state,
            roomsLoader: true
          }

          case UserChatActionTypes.NEW_USER_CREATION_SUCCESS ||  UserChatActionTypes.NEW_USER_CREATION_FAILURE:
            return {
              ...state,
              roomsLoader: false
            }
      default:
        return {
          ...state
        }
    }
  }

  export default userChatReducer;
  