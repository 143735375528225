import React, { useState, useEffect } from 'react';
import './subscription.scss';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import center1 from '../../components/assets/subscription/Banner1.jpeg'
import center2 from '../../components/assets/subscription/Banner2.jpeg'
import center3 from '../../components/assets/subscription/Banner3.jpeg'
import OwlCarousel from 'react-owl-carousel';
import prev from '../../components/assets/offlineCenters/arrowLeft.svg';
import next from '../../components/assets/offlineCenters/arrowRight.svg';
import leftArrow from '../../components/assets/subscription/left-arrow-key.svg'
import selfServicePlan from '../../components/assets/subscription/wifi-connectionW.svg'
import personalisedPlan from '../../components/assets/subscription/preferencesN.svg'
import rings from '../../components/assets/subscription/Ring.png'
import { selectIsAuth } from "../../redux/auth/auth.selector";
import matchmakerPhoto from "../../components/assets/subscription/matchmakerPhoto1.png";
import logoOld from "../../components/assets/hero/logoOld.png";
import text from "../../components/assets/hero/text.svg";
import googleplay from "../../components/assets/newAppIllustrations/googleplay.jpg";
import sidemenubtn from "../../components/assets/hero/mobile-menu-side-opener-btn.svg";
import navbarimage from "../../components/assets/hero/navbarimage.svg";
import logo1 from "../../components/assets/logo1.png";
import heroimage2 from "../../components/assets/hero/heroimage2.png";
import illsvg1 from "../../components/assets/hero/illsvg1.svg";
import ScheduleCall from "../../components/assets/subscription/ScheduleCall.png";
// import "./landing-page.styles.scss";
import personalMatchmaker from "../../components/assets/subscription/personal-matchmaking.jpeg";
import Plans from "../../components/assets/subscription/Plans1_enhanced.png";
import VideoCarousel from "../landing-page/video-carousel/video-carousel";
import ToggleButton from "../landing-page/togglebutton";
import Drawer from "../landing-page/drawer/drawer.component";
import matchmakerInfo from "../../components/assets/subscription/matcmaker-info.jpeg";
import Hero from "../landing-page/hero/hero.component";
import Feature from "../landing-page/features/features.component";
import flowers from "../../components/assets/subscription/flowers.png";
import HowItWork from "../landing-page/how-it-work/how-it-work.component";
import OfflineCenter from "../landing-page/offline-center/offline-center.component";
import MatchMaker from "../landing-page/match-makers/match-makers.component";
import Footer from "../landing-page/footer/footer.component";
import selfService from "../../components/assets/subscription/Self-Service-Enhanced.jpeg";
import Testimonials from "../landing-page/testimonials/testimonials.component";
import FreeKundaliMatching from "../landing-page/free-kundali-matching/free-kundali-matching.component";
import Sidebar from "../landing-page/drawer/sidebar";
import VideoCarouselTestimonial from "../landing-page/video-carousel/video-carouselTestimonial";
import App2 from "../subscription/waste";
import axios from "axios";
import { Nav } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Subscription = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const phoneNum = '+919697989697'; // Your phone number here
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [lookingFor, setLookingFor] = useState("Myself");
  const modalStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#white',
    border: 'none',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 1)',
    maxWidth: '400px',
    width: '100%',
    textAlign: 'center',
    zIndex: '9999'
  };

  const overlayStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '9999'
  };
  const openModal = () => {
    console.log("inside openModal");
    setModalIsOpen(true);
    console.log(modalIsOpen);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const [registerModalIsOpen, setRegisterModalIsOpen] = useState(false);

  const openRegisterModal = () => {
    setRegisterModalIsOpen(true);
  };

  const closeRegisterModal = () => {
    setRegisterModalIsOpen(false);
  };
  // <div className='hero-1'>
  //   <Link to="/">
  //     <img className='leftarrow' src={leftArrow}></img>
  //   </Link>
  //   <div>
  //     <OwlCarousel
  //       items={1}
  //       className="owl-theme"
  //       margin={8}
  //       autoplay={true}
  //       nav={true}
  //       navText={[`<img src=${prev}>`, `<img src=${next}>`]}
  //       dots={false}
  //       loop={true}
  //     >
  //       <div className="off-slide item">
  //         <div className="off-temple-card off-text-shadow" style={{ marginTop: '20px' }}>
  //           <span className="off-temple-name"></span>
  //           <div className="off-featureCard2">
  //             <img className="off-feature2Img" src={center1}
  //               alt="Shiv Sai Dham Mandir" />
  //           </div>
  //         </div>
  //       </div>
  //       <div className="off-slide item">
  //         <div className="off-temple-card off-text-shadow" style={{ marginTop: '20px' }}>
  //           <span className="off-temple-name"></span>
  //           <div className="off-featureCard2">
  //             <img className="off-feature2Img" src={center2}
  //               alt="Sanathan Dharam Mandir" />
  //           </div>
  //         </div>
  //       </div>
  //       <div className="off-slide item">
  //         <div className="off-temple-card off-text-shadow" style={{ marginTop: '20px' }}>
  //           <span className="off-temple-name"></span>
  //           <div className="off-featureCard2">
  //             <img className="off-feature2Img" src={center3}
  //               alt="Lakshmi Narayan Mandir" />
  //           </div>
  //         </div>
  //       </div>
  //     </OwlCarousel>
  //   </div>
  //   <div style={{ display: 'flex' }}>
  //     <div className='selfserviceplan'>
  //       <div className='circle'>
  //         <img className='imgselfserviceplan' src={selfServicePlan}></img>
  //       </div>
  //       Self-Service Plans
  //     </div>
  //     <div className='selfserviceplan'>
  //       <div className='circle2'>
  //         <img className='imgpersonalisedplan' src={personalisedPlan}></img>
  //       </div>
  //       Personalised Plans
  //     </div>
  //   </div>

  // </div>
  const [sidebar, setSidebar] = useState(false);
  const [navbar, setNavbar] = useState(false);

  // const changeBackground  = () => {
  //   if (window.scrollY >= 120 )
  //   {
  //     setNavbar(true)
  //   }
  //   else {
  //     setNavbar(false)
  //   }
  // };

  const showSidebar = (e) => {
    console.log("hamburger clicked");
    e.preventDefault();
    sidebar === true ? setSidebar(false) : setSidebar(true);
  };

  // window.addEventListener("scroll", changeBackground);

  const [auth, setAuth] = useState();
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!fullName || !phoneNumber || !lookingFor) {
      alert("Please fill in all fields.");
      return;
    }
    try {
      const response = await fetch(
        "https://partner.hansmatrimony.com/api/save-incomplete-leads",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_phone: phoneNumber,
            for: lookingFor,
            cname: fullName,
            channel: "google_ads",
          }),
        }
      );

      if (response.ok) {
        console.log("Lead saved successfully");
        toast.success(
          "Your data has been recieved our team will contact you soon!"
        );
        setFullName("");
        setPhoneNumber("");
        setLookingFor("myself");
        closeRegisterModal();
      } else {
        console.error("Failed to save lead");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  let location = "kundali";
  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(`${location.hash}`);
      if (!element) {
        return;
      }
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);
  return (
    <>
      <Modal
        isOpen={registerModalIsOpen}
        onRequestClose={closeRegisterModal}
        contentLabel="Register Modal"
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999999999, // Ensure the modal appears on top of other content
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#ffffff",
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: "20px",
            maxWidth: "500px",
            height: "380px",
            width: "100%", // Adjust the width as needed
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
            zIndex: 999999999, // Ensure the modal content appears on top of the overlay
          },
        }}
      >
        <form
          onSubmit={handleFormSubmit}
          style={{ zIndex: "999999999999", height: "100%", width: "100%" }}
        >
          {/* <div className="form-heading"> */}
          <h6 className="Heading">Upper/Middle Class Family’s Rishtey!</h6>
          {/* </div> */}
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter your name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone:</label>
            <input
              type="text"
              className="form-control"
              id="phone"
              placeholder="Enter your phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="select">Looking For:</label>

            <select
              className="form-control"
              id="select"
              onChange={(e) => setLookingFor(e.target.value)}
            >
              <option value="myself">Myself</option>
              <option value="son">Son</option>
              <option value="daughter">Daughter</option>
              <option value="brother">Brother</option>
              <option value="sister">Sister</option>
              <option value="other">Other</option>
            </select>
          </div>
          <button type="submit" className="btn btn-primary">
            Register Now
          </button>
        </form>
      </Modal>
      <div className="mat-sidenav-container">
        <div>
          <nav className={navbar ? "navbar colorChange" : "originalColor"}>
            <div className="land-nav-hamburger">
              <nav className="Nav">
                <div className="land-logo-holder">
                  <a href="/">
                    <img
                      className="land-logo-img-fluid"
                      src={logo1}
                      alt="hansmatrimony-logo"
                      style={{ width: "130px", height: "43px" }}
                    />
                  </a>

                  {/* <div className="d-flex"> */}
                  <ul className="land-nav-links">
                    {/* <ul > */}
                    <li>
                      <a mdbPageScroll href="/">
                        Home
                      </a>
                    </li>
                    <li>
                      <Link
                        to="/subscription"
                        routerLinkActive="router-link-active"
                        style={{ fontWeight: 'bold', color: '#265682' }}
                      >
                        Subscription
                      </Link>
                    </li>
                    <li>
                      <a onClick={openModal}>Contact Us</a>
                    </li>
                    {/* {isAuth ? (
                    <li>
                      <Link
                        to="/chat"
                        routerLinkActive="router-link-active"
                        href=""
                      >
                        See Profiles
                      </Link>
                    </li> */}
                    {/* ) : */}
                    {/* ( */}
                    <li>
                      <Link
                        // to="/phone-number"
                        onClick={openRegisterModal}
                        routerLinkActive="router-link-active"
                      >
                        Login/Register
                      </Link>
                    </li>
                    {/* )} */}
                  </ul>
                </div>
                <button onClick={showSidebar} className="land-hamburger1">
                  <div className="land-line1"></div>
                  <div className="land-line1"></div>
                  <div className="land-line1"></div>
                  {sidebar && <Sidebar sidebar={true} />}
                </button>
                {/* </div> */}
              </nav>
            </div>
          </nav>
          <div>
            <div className='personalised-matchmaker'>
              <img src={matchmakerPhoto} alt="hansmatrimony-heroimage2" className='matchmaker-photo' />
              <img src={rings} alt='subscription-rings' className='rings' />
              <button className='schedule-call' onClick={openModal}>
                Schedule a call
              </button>
            </div>
            <div className='subscription-plans'>
              <img src={Plans} alt='subscription-plans' className='plans' />
              <button className='get-started' onClick={openModal}>Get started</button>
              <button className='get-started1' onClick={openModal}>Get started</button>
              <button className='get-started2' onClick={openModal}>Get started</button>
            </div>
            <div className='personalised-matchmaking'>
              <p className='heading-matchmaking' style={{ textAlign: 'center' }}>With Personalised Matchmaking you can</p>
              <img src={personalMatchmaker} alt='points-personal-matchmaking' className='points-personal-matchmaking' />
              <img src={flowers} alt='flowers' className='matchmaking-flowers' />
              <img src={rings} alt='rings' className='rings1' />
            </div>
            <div className='personalised-matchmaker2'>
              <img src={matchmakerInfo} alt="hansmatrimony-heroimage2" className='matchmaker-photo2' />
              <img src={rings} alt='subscription-rings' className='rings' />
              <button className='schedule-call2' onClick={openModal}>
                Schedule a call
              </button>
            </div>
            {/* <App2 /> */}
            {/* <Hero /> */}
            {/* <Feature /> */}
            {/* <OfflineCenter /> */}
            {/* <HowItWork /> */}
            {/* <MatchMaker /> */}
            {/* <FreeKundaliMatching /> */}
            {/* <VideoCarousel /> */}
            {/* <Testimonials /> */}
            {/* <VideoCarouselTestimonial /> */}
            <Footer
              Subscription={true}
            />
            <ToggleButton />
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Call Modal"
        style={{
          content: modalStyle,
          overlay: overlayStyle
        }}
      >
        <h2 style={{}}>Please give us a call at:</h2>
        <p style={{ fontSize: "20px" }}>{phoneNum}</p>
        <button onClick={closeModal} style={{ backgroundColor: "#FF9A9E", border: "none", borderRadius: "5px", color: "black", width: "100px", marginTop: "20px" }}>Close</button>
      </Modal>
    </>
  )
}
export default Subscription;