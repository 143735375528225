import React from 'react';


const Input = ({ className, name, value, type, handleChange, title, required,  defaultval,...otherProps }) => {
  return (
    <input
      className={className}
      name={name}
      value={value}
      type={type}
      onChange={handleChange}
      placeholder={title}
      required={required}
      {...otherProps}
    />
  )
}

export default Input;