import { createSelector } from 'reselect';

const register = state => state.register;

export const selectAllCaste = createSelector(
  [register],
  register => register.allCaste
)
export const selectCreateProfile = createSelector(
  [register],
  register => register.createProfile
)
export const selectReligion = createSelector(
  [register],
  register => register.religion
)
export const selectGender = createSelector(
  [register],
  register => register.gender
)
export const selectOccupation = createSelector(
  [register],
  register => register.occupation
)
export const selectIncome = createSelector(
  [register],
  register => register.income
)
export const selectMaritalStatus = createSelector(
  [register],
  register => register.maritalStatus
)
export const selectQualification = createSelector(
  [register],
  register => register.qualification
)
export const selectCity = createSelector(
  [register],
  register => register.city
)
export const selectHeights = createSelector(
  [register],
  register => register.heights
)

export const selectHeights1 = createSelector(
  [register],
  register => register.heights1
)

export const selectHeights2 = createSelector(
  [register],
  register => register.heights2
)
export const selectDesignation = createSelector(
  [register],
  register => register.designation
)
export const selectState = createSelector(
  [register],
register => register.state.map(item => ({ name: item.name, id: item.id }))
)
