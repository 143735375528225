import React from "react";

import useFromInput from "../../../../hooks/useFormInput";
import Input from "../../../../components/input/input.component";
import Select from "../../../../components/select/select.component";
import Select2 from "../../../../components/select/select2.component";
import Button from "../../../../components/button/button.component";
import { useState } from "react";
import axios from "axios";

let date = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];
let month = [
  "January",
  "Feburary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
let years = [
  "1970",
  "1971",
  "1972",
  "1973",
  "1974",
  "1975",
  "1976",
  "1977",
  "1978",
  "1979",
  "1980",
  "1981",
  "1982",
  "1983",
  "1984",
  "1985",
  "1986",
  "1987",
  "1988",
  "1989",
  "1990",
  "1991",
  "1992",
  "1993",
  "1994",
  "1995",
  "1996",
  "1997",
  "1998",
  "1999",
  "2000",
  "2001",
  "2002",
  "2003",
  "2004",
  "2005",
  "2006",
  "2007",
  "2008",
  "2009",
  "2010",
];

const KundaliForm = (props) => {
  const [formValidate, setFormValidate] = useState({
    currentGender: "male",
    maleValid: false,
    error: false,
    maleValid: false,
    femaleValid: false,
  });
  const [state, handleState, submitState] = useFromInput({
    maleBirthDate: "",
    maleBirthMonth: "",
    maleBirthYear: "",
    femaleBirthDate: "",
    femaleBirthMonth: "",
    femaleBirthYear: "",
    maleBirthTime: "",
    femaleBirthTime: "",
    maleBirthPlace: "",
    femaleBirthPlace: "",
    maleName: "",
    femaleName: "",
  });
  const {
    maleBirthDate,
    maleBirthMonth,
    maleBirthYear,
    femaleBirthDate,
    femaleBirthMonth,
    femaleBirthYear,
    maleBirthTime,
    femaleBirthTime,
    maleBirthPlace,
    femaleBirthPlace,
    maleName,
    femaleName,
    currentGender,
    maleValid,
  } = state;

  // const femaleformattedDate = femaleApiDate.toISOString().slice(0, 10);

  const detailsFilled = (e) => {
    e.preventDefault();
    console.log("Form Submitted");

    if (
      maleName &&
      maleBirthDate &&
      maleBirthMonth &&
      maleBirthYear &&
      maleBirthTime &&
      maleBirthPlace
    ) {
      if (formValidate.currentGender === "female") {
        if (
          femaleName &&
          femaleBirthDate &&
          femaleBirthMonth &&
          femaleBirthYear &&
          femaleBirthTime &&
          femaleBirthPlace
        ) {
          setFormValidate((state) => ({ ...state, currentGender: "male" }));

          console.log("form submited", state);
          state.maleName = "";
          state.femaleName = "";
          state.maleBirthTime = "";
          state.femaleBirthTime = "";
          state.maleBirthPlace = "";
          state.femaleBirthPlace = "";
          // api call
          // const maleApiDate = new Date(maleBirthYear,maleBirthMonth,maleBirthDate );
          const maleApi =
            maleBirthYear + "-" + maleBirthMonth + "-" + maleBirthDate;
          const femaleApi =
            femaleBirthYear + "-" + femaleBirthMonth + "-" + femaleBirthDate;
          // const femaleApiDate = new Date(maleBirthYear, maleBirthMonth, maleBirthDate);

          var data = JSON.stringify({
            maleBirthDate: maleApi,
            femaleBirthDate: femaleApi,
            femaleBirthTime: femaleBirthTime,
            maleBirthTime: maleBirthTime,
            maleBirthPlace: maleBirthPlace,
            femaleBirthPlace: femaleBirthPlace,
          });

          var config = {
            method: "post",
            maxBodyLength: Infinity,
            // url: 'https://hansmatrimony.com/api/v1/getKundali',
            url: "https://api.hansmatrimony.com/api/v1/getKundali",
            headers: {
              "X-HANS-KEY":
                "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJoYW5zIiwiaWF0IjoyMDIxMjQwNjAzNTk4NDgsInVzZXIiOnsibW9iaWxlIjoiOTcxOTcxODkwMCJ9LCJzdXBlciI6ImhhbnNub3cifQ.Li5DVVJv42fo7VynFjwyJWhFpDAcq4UGjpcpJ6JD4BwmwIhK_X6ZyGMAVnLGUMP0jsUYKkRarAFlgrZOhUPHiA",
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios(config)
            .then(() => (response) => {
              console.log(JSON.stringify(response.data));
            })
            .catch(() => (error) => {
              console.log(error);
            });
        } else {
          setFormValidate((state) => ({ ...state, error: "true" }));
        }
      } else
        setFormValidate((state) => ({ ...state, currentGender: "female" }));
    } else {
      setFormValidate((state) => ({ ...state, error: "true" }));
    }
  };

  return (
    <div>
      {formValidate.currentGender === "male" ? (
        <form className="kundali-form" onSubmit={detailsFilled}>
          <div className="d-flex justify-content-center">
            <h3 className="kundali-h3">
              Enter {formValidate.currentGender} Details
            </h3>
          </div>
          <br />
          <div className="kumdali-rowM">
            <div className="kundali-column1">
              <div className="kundali-details">
                <div className="col">
                  <h6 className="kundali-h6">Name</h6>
                  <Input
                    className="form-control kundali-input"
                    name="maleName"
                    value={maleName}
                    type="text"
                    required
                    handleChange={handleState}
                    title=""
                  />
                </div>
                <br />
                <div className="col">
                  <h6 className="kundali-h6">Birth Date</h6>
                  <div className="container">
                    <div className="row">
                      <div className="col-4 p-0 pr-2">
                        <Select
                          name="maleBirthDate"
                          className="form-control kundali-classNameic"
                          items={date}
                          handleChange={handleState}
                        />
                      </div>
                      <div className="col-4 pl-1 pr-1">
                        <Select
                          name="maleBirthMonth"
                          className="form-control kundali-classNameic"
                          items={month}
                          handleChange={handleState}
                        />
                      </div>
                      <div className="col-4 p-0 pl-2">
                        <Select
                          name="maleBirthYear"
                          className="form-control kundali-classNameic"
                          items={years}
                          handleChange={handleState}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="col">
                  <h6>Birth Time</h6>
                  <Input
                    className="form-control kundali-input"
                    name="maleBirthTime"
                    value={maleBirthTime}
                    type="time"
                    min="00:00:00"
                    max="24:59:59"
                    required
                    handleChange={handleState}
                    title=""
                  />
                </div>
                <br />
                <div className="col">
                  <h6>Birth Place</h6>
                  <Input
                    className="form-control kundali-input"
                    name="maleBirthPlace"
                    value={maleBirthPlace}
                    type="text"
                    required
                    handleChange={handleState}
                    title=""
                  />
                  {/* <input className="form-control kundali-input" type="text"
                // id="maleBirthPlace" [autoCompleteOptions]="autoComplete"
                // matValidateAddress matGoogleMapsAutocomplete
                // #matGoogleMapsAutocomplete="matGoogleMapsAutocomplete"
                change="placeChanged('maleBirthPlace')"
              /> */}
                </div>
                <br />
                <div className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    className="btn kundali-button"
                    title="Enter female Details"
                    // handleSubmit={detailsFilled}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <form className="kundali-form" onSubmit={detailsFilled}>
          <div className="d-flex justify-content-center">
            <h3 className="kundali-h3">
              Enter {formValidate.currentGender} Details
            </h3>
          </div>
          <br />
          <div className="kumdali-rowM">
            <div className="kundali-column1">
              <div className="kundali-details">
                <div className="col">
                  <h6 className="kundali-h6">Name</h6>
                  <Input
                    className="form-control kundali-input"
                    name="femaleName"
                    value={femaleName}
                    type="text"
                    required
                    handleChange={handleState}
                    title=""
                  />
                </div>
                <br />
                <div className="col">
                  <h6 className="kundali-h6">Birth Date</h6>
                  <div className="container">
                    <div className="row">
                      <div className="col-4 p-0 pr-2">
                        <Select2
                          name="femaleBirthDate"
                          className="form-control kundali-classNameic"
                          items={date}
                          handleChange={handleState}
                        />
                      </div>
                      <div className="col-4 pl-1 pr-1">
                        <Select2
                          name="femaleBirthMonth"
                          className="form-control kundali-classNameic"
                          items={month}
                          handleChange={handleState}
                        />
                      </div>
                      <div className="col-4 p-0 pl-2">
                        <Select2
                          name="femaleBirthYear"
                          className="form-control kundali-classNameic"
                          items={years}
                          handleChange={handleState}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="col">
                  <h6>Birth Time</h6>
                  <Input
                    className="form-control kundali-input"
                    name="femaleBirthTime"
                    value={femaleBirthTime}
                    type="time"
                    min="00:00:00"
                    max="24:59:59"
                    required
                    handleChange={handleState}
                    title=""
                  />
                </div>
                <br />
                <div className="col">
                  <h6>Birth Place</h6>
                  <Input
                    className="form-control kundali-input"
                    name="femaleBirthPlace"
                    value={femaleBirthPlace}
                    type="text"
                    required
                    handleChange={handleState}
                    title=""
                  />
                  {/* <input className="form-control kundali-input" type="text"
                  // id="maleBirthPlace" [autoCompleteOptions]="autoComplete"
                  // matValidateAddress matGoogleMapsAutocomplete
                  // #matGoogleMapsAutocomplete="matGoogleMapsAutocomplete"
                  change="placeChanged('maleBirthPlace')"
                /> */}
                </div>
                <br />
                <div className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    className="btn kundali-button"
                    title="Submit"
                    // handleSubmit={detailsFilled}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default KundaliForm;
