import React from 'react';

import './preloader.styles.scss'

const Preloader = (props) => {

  return (
    <div>loading...</div>
  )
}

export default Preloader;