import { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Modal } from 'react-bootstrap';


import { captureStandardPayment, getPaytmOrderId, payUsingPaytm } from '../../../redux/payment/payment.action';
import { selectMobile, selectName, selectIsLead, selectId } from '../../../redux/auth/auth.selector'
import { selectPaytmOrder } from '../../../redux/payment/payment.selector';

import { PAYTM_DEV_URL, PAYTM_PROD_URL, RAZOR_DEV_KEY } from '../../../utils/api';

import './checkout.styles.scss';

const Checkout = ({ id, isLead, captureStandardPayment, getPaytmOrderId, payUsingPaytm, paytmOrder, mobile, show, onHide, plan, name }) => {


  useEffect(() => {
    if (show) {
      getPaytmOrderId({ amount: Math.ceil(plan.amount - plan.amount * plan.discount / 100), mobile })
    }
  }, [show, getPaytmOrderId, mobile, plan])

  const paytm = () => {
    var information = {
      action: `${PAYTM_PROD_URL}${paytmOrder.orderId}`,
      params: paytmOrder
    }
    post(information)
  }
  const razorPay = () => {
    let options = {
      'key': RAZOR_DEV_KEY,
      'amount': Math.ceil(plan.amount - plan.amount * plan.discount / 100) * 100,
      'name': ' Hans Matrimony',
      'description': 'Order #',

      'handler': (response) => {
        console.log(response);
        captureStandardPayment({
          mobile,
          id: id.toString(),
          is_lead: isLead.toString(),
          payment_id: response.razorpay_payment_id,
          // plan_id: 'plan 1',
          currency: 'INR',
          whatsapp_point: '0',
          amount: Math.ceil(plan.amount - plan.amount * plan.discount / 100),
        })
        // alert('Payment Successfull \n We will get back to you shortly \n Your Payment ID: ' + response.razorpay_payment_id);
      },
      'prefill': {
        'name': name,
        'email': "deepak@gmail.com",
        'contact': mobile,
      },
      'notes': { service: 'Limited' },
      'theme': {
        'color': 'blue'
      }
    }
    const rzp = new window.Razorpay(options);
    rzp.open();
  }

  function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === '[object Date]'
  }

  function isObj(val) {
    return typeof val === 'object'
  }

  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val)
    } else {
      return val
    }
  }

  function buildForm({ action, params }) {
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', action)

    Object.keys(params).forEach(key => {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', key)
      input.setAttribute('value', stringifyValue(params[key]))
      form.appendChild(input)
    })

    return form
  }

  function post(details) {
    const form = buildForm(details)
    document.body.appendChild(form)
    form.submit()
    form.remove()
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      style={{zIndex: '99999'}}
      aria-labelledby="contained-modal-title-center"
      centered
    >
      <div className='checkout p-3 m-auto'>
        <header className='d-flex justify-content-between algin-items-center'>
          <div onClick={onHide}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.535 3.51501L7.04999 12L15.535 20.485L16.95 19.071L9.87799 12L16.95 4.92901L15.535 3.51501Z" fill="white" />
            </svg>
          </div>
          <div onClick={onHide}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#F0EDED" />
            </svg>
          </div>
        </header>

        <section className='mt-5 p-5'>
          <h5>Total: ₹ {Math.ceil(plan.amount - plan.amount * plan.discount / 100)}</h5>
          <button disabled className='btn'>Pay With</button>
          <button onClick={paytm} className='full-width mt-3 btn paytm pay' >Paytm</button>
          <button onClick={razorPay} className='full-width mt-3 btn pay'>Card / Netbanking / UPI</button>
        </section>
      </div>
    </Modal>
  )
}

const mapStateToProps = createStructuredSelector({
  mobile: selectMobile,
  id: selectId,
  isLead: selectIsLead,
  name: selectName,
  paytmOrder: selectPaytmOrder
})

const mapDispatchToProps = dispatch => ({
  getPaytmOrderId: data => dispatch(getPaytmOrderId(data)),
  payUsingPaytm: data => dispatch(payUsingPaytm(data)),
  captureStandardPayment: data => dispatch(captureStandardPayment(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);