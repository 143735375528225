import React, { memo } from 'react';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {IMAGE_URL_PREFIX} from '../../../utils/api.js'

const Carousel = ({ urls, setModalShow }) => {

  console.log(urls)

  const  getUrlForImage = (url) => {
    if(url.includes('https'))
    return url
    else 
    return IMAGE_URL_PREFIX+url
  }
  
  const item = urls?.map((url, idx) => (
    <div className="slide item open-profile-img" key={idx}
    style={{backgroundImage: `url(${getUrlForImage(url)})`}}
    onClick={() => setModalShow({ active: true, url: getUrlForImage(url) })}
    >
    </div>
  ))

  return (
    <OwlCarousel
      items={1}
      className="owl-theme"
      margin={8}
      autoplay={false}
    >
      {item}
    </OwlCarousel>
  )
}

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  // console.log(prevProps.urls, nextProps.urls, prevProps.urls.length === nextProps.urls.length && prevProps.urls.every((v, i) => v === nextProps.urls[i]))
  // return JSON.stringify(prevProps.urls) === JSON.stringify(nextProps.urls);
  return prevProps.urls?.length === nextProps.urls?.length && prevProps.urls?.every((v, i) => v === nextProps.urls[i])
}
export default memo(Carousel, areEqual);