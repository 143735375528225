export const APP_URL_PREFIX = "https://partner.hansmatrimony.com/api/"
// export const APP_URL_PREFIX_NODE = "https://hansmatrimony.com/api/v1/"
export const APP_URL_PREFIX_NODE = "https://api.hansmatrimony.com/api/v1/"

export const IMAGE_URL_PREFIX = "https://d2v6etlhrhtken.cloudfront.net/uploads/"
export const AUDIO_URL_PREFIX = "https://hansmatrimony.s3.ap-south-1.amazonaws.com/uploads/audioProfiles/"

export const PAYTM_DEV_URL = 'https://securegw-stage.paytm.in/theia/api/v1/showPaymentPage?mid=bkjPis66135619933053&orderId='
export const PAYTM_PROD_URL = 'https://securegw.paytm.in/theia/api/v1/showPaymentPage?mid=Twango57803369412564&orderId='
export const RAZOR_DEV_KEY = 'rzp_test_37I1EC7DpM6Dqq';
export const RAZOR_PROD_KEY = 'rzp_live_LUlMqhHJCUOuRY';
export const PAYTM_DEV_KEY = 'bkjPis66135619933053'
export const PAYTM_PROD_KEY = 'Twango57803369412564'

export const API = {
  // LOGIN_URL: "auth",
  LOGIN_URL: "authnewrequest",
  TODAY_PROFILE_URL: "getTodaysProfiles",
  GET_CONTACTED_PROFILES_URL: "contactedProfiles",//"contactedProfiles",
  GET_LIKED_PROFILES_URL: "sortListProfiles",
  GET_LIKED_ME_PROFILES_URL: "interestReceived",
  GET_REJECTED_PROFILES_URL: "rejectedProfiles",
  GET_OTHERS_PROFILES_URL: "premiumPro",
  SEND_REPLY_URL: "reply",
  GET_USER_PROFILE_URL: "getProfile",
  EDIT_PERSONAL_DETAILS_URL: "updatePersonalDetails",
  GET_CREDIT_COUNT_URL: "getWhatsappPoint",
  ALL_CASTES_URL: "getAllCaste",
  EDIT_FAMILY_URL: "updateFamilyDetails",
  EDIT_PREFERENCE_URL: "updateUserPreferences",
  CASTE_MAPPING_URL: "caste_mapping",
  GET_MUTUAL_LIKED_PROFILES_URL: "mutualProfiles",
  PREMIUM_CALL_REQUEST_URL: "insertPremiumInterest",
  SEND_OTP_URL: "sendOTP",
  // VERIFY_OTP_URL: "otpVerify", // "verifyOTP",
  VERIFY_OTP_URL: "otpverifynew",
  CAPTURE_PAYMENT_URL: "paymentCapture",
  DISCOVER_PROFILES_URL: "seeMore",//"getDiscoveryProfiles",
  DISCOVER_PREMIUM_PROFILES_URL: "premiumProNew",
  SAVE_DISCOVER_ACTION_URL: "saveAction",
  REQUEST_CALL_BACK_URL: "updateCallBack",
  COMPLAINT_CATEGORIES_URL: "getComplainCategories",
  REGISTER_COMPLAINT_URL: "registerComplaint",
  ADD_THREE_CREDIT_URL: "addFreeCredits",
  GET_PLANS_URL: "getWebsitePlan",
  UPDATE_BASIC_URL: "updateBasicFormOne",//"updateBasic",
  UPDATE_BASIC_URL_TWO: "updateBasicFormTwo",
  PAGE_TWO_URL: "formTwoProfile",
  PAYTM_ORDER_URL: "orderApp",
  PAYTM_TRANSACTION_URL: "transactionStatus",
  ONBOARDING_PICS_URL: "getPhotos",
  DAILY_PROFILE_COUNT_URL: "getCountOfRishtey",
  RECOMMENDED_PREFERENCE_URL: "getRecommendedPreferences",
  PAGE_THREE_URL: "formThreeProfile",
  SUBSCRIPTION_SEEN_URL: "isSubscriptionViewed",
  GET_STATES_URL: "getState",
  GET_CITY_URL: "getCities",
  SEARCH_CITY_URL: "suggestCities",
  DELETE_PROFILE_URL: "deleteProfileAPI",
  GET_HISTORY_URL: "getHistory",
  PROFILE_VIEWER_URL: "showViewedProfile",//"showViewedProfile",
  SAVE_TODAYS_ACTION_URL: "saveProfileAction",
  GET_COUNT_URL: "countings",
  UPLOAD_PHOTO_URL: 'uploadProfilePicture',
  UPLOAD_AUDIO_URL: 'uplaodAudioChat',
  MAPPED_DATA: 'mappedData',
  GET_COUNTRIES: 'allCountries',
  GET_POPULAR_PROFILES: 'getpopularprofiles',
  GET_NEWLY_JOINED_PROFILES: 'newlyjoinedprofiles',
  GET_RISHTEY_COUNT: 'getCountOfRistey',
  GET_RECOMMENDED_PREFERENCE: 'getRecommendedPreferences',
  GET_KUNDALI: 'getKundali',
  UPLOAD_PROFILE_AUDIO: 'uploadAudioProfile'
}

export const FETCH = ({ url, method, body, ContentType }) => {
  return fetch(APP_URL_PREFIX + url, {
    method: method ? method : 'GET',
    headers: {
      'Content-Type': ContentType ? ContentType : 'application/json, text/plain, */*',
      'X-HANS-KEY': 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJoYW5zIiwiaWF0IjoyMDIxMDkwODIwMDQ0MzUsInVzZXIiOnsibW9iaWxlIjoiOTkxMDM5NTgyMCJ9LCJzdXBlciI6ImhhbnNub3cifQ.3g6Vh3s42ypdKgwxEfde3mQuhKRW50t5VVrFmIROX8Hcr2Q0GOv9mwjyV4mb8bsTDxBTiOCmGawjD3Bd99hLuw'
    },
    body: body,
  })

}

export const FETCH_FROM_NODE = ({ url, method, body, ContentType, header = null }) => {
  return fetch(APP_URL_PREFIX_NODE + url, {
    method: method ? method : 'GET',
    headers: {
      'Content-Type': ContentType ? ContentType : 'application/json, text/plain, */*',
      'X-HANS-KEY': 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJoYW5zIiwiaWF0IjoyMDIxMDkwODIwMDQ0MzUsInVzZXIiOnsibW9iaWxlIjoiOTkxMDM5NTgyMCJ9LCJzdXBlciI6ImhhbnNub3cifQ.3g6Vh3s42ypdKgwxEfde3mQuhKRW50t5VVrFmIROX8Hcr2Q0GOv9mwjyV4mb8bsTDxBTiOCmGawjD3Bd99hLuw'
    },
    body: body
  })

}

export const UPLOAD_FROM_NODE = ({ url, method, body, ContentType, header = null }) => {
  return fetch(APP_URL_PREFIX_NODE + url, {
    method: method ? method : 'GET',
    headers: {
      // 'Content-Type': ContentType ? ContentType : 'application/json, text/plain, */*',
      'X-HANS-KEY': 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJoYW5zIiwiaWF0IjoyMDIxMDkwODIwMDQ0MzUsInVzZXIiOnsibW9iaWxlIjoiOTkxMDM5NTgyMCJ9LCJzdXBlciI6ImhhbnNub3cifQ.3g6Vh3s42ypdKgwxEfde3mQuhKRW50t5VVrFmIROX8Hcr2Q0GOv9mwjyV4mb8bsTDxBTiOCmGawjD3Bd99hLuw'
    },
    body: body
  })

}