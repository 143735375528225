import { createSelector } from 'reselect';
import { IMAGE_URL_PREFIX } from '../../utils/api';
import { setName, setHeight, setAge } from '../../utils/display.utils';
import { selectIsPremium } from '../auth/auth.selector';

const newlyJoined = state => state.newlyJoined;

export const selectNewlyJoinedList = createSelector(
    [newlyJoined],
    newlyJoined => newlyJoined.newly_joined_list
  )

export const selectNewlyJoinedItemsList = createSelector(
  [selectNewlyJoinedList,selectIsPremium],
  (newlyJoined,isPremium) => newlyJoined.map(it =>  getHeading(it,isPremium))
)

  const getHeading = (item,isPremium) => {

    return {
      title: `${setName(item.name,isPremium)}  | ${setAge(item.birth_date)}`,
      subTitle1: `${item.religion} | ${item.caste ?? ""}`,
      subTitle2: `${item.locality ? item.locality : item.working_city} | ${setHeight(`${item.height}`)}`,
      subTitle3: `${item.occupation ?? ""} | ${item.annual_income/1} Lpa`,
      photo:   item.photo ? IMAGE_URL_PREFIX + item.photo : null,
      id: item.id,
      item: item,
    mobile: item.user_mobile
    }
  }