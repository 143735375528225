import { API,FETCH_FROM_NODE } from '../../utils/api';
import {setError} from '../error/error.action';
import  MappedActionTypes from './mappedData.types';

const getCountriesList = () => {
  return dispatch => {
    dispatch({ type: MappedActionTypes.GET_COUNTRIES_START });
    FETCH_FROM_NODE({
      url: API.GET_COUNTRIES,
      method: 'GET'
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {
        dispatch({
          type: MappedActionTypes.GET_COUNTRIES_SUCCESS,
          payload: resData
        });
      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: MappedActionTypes.GET_COUNTRIES_FAILURE });
      });
  }
}

export const getMappedData = () => {
    return dispatch => {
      dispatch({ type: MappedActionTypes.GET_MAPPED_DATA_START });
      FETCH_FROM_NODE({
        url: API.MAPPED_DATA,
        method: 'GET'
      })
        .then(res => {
          let errorMessage;
          if (res.status !== 200) {
            errorMessage = 'Something Went Worng!';
          }
          if (errorMessage) throw new Error(errorMessage)
          return res.json();
        })
        .then(resData => {
          dispatch({
            type: MappedActionTypes.GET_MAPPED_DATA_SUCCESS,
            payload: resData
          });

          dispatch(getCountriesList())
        })
        .catch(err => {
          console.log(err);
          dispatch(setError(err))
          dispatch({ type: MappedActionTypes.GET_MAPPED_DATA_FAILURE });
        });
    }
  }

 