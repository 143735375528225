import firebase from '../../firebase-config';
import UserChatActionTypes from './user-chat.types';
import { API, UPLOAD_FROM_NODE } from '../../utils/api'
import {setError} from '../error/error.action';

const db = firebase.firestore();

export const openChatSection = (fromUserId,toUserId) => {
    return async (dispatch,getState) => {
        dispatch({
            type: UserChatActionTypes.OPEN_USER_CHAT
          })

        try {
            
            if(fromUserId) { // requesting to create new room between fromUser and toUser
                dispatch({type: UserChatActionTypes.NEW_USER_CREATION_START})
                     // website data of users
        const loggedInUserData = getState().auth.auth
        const profileData = getState().interested.liked.find(it => it.id === parseInt(toUserId))

        // firestore data of users
        const loggedInUserFireStoreData = await fetchOrCreateUserInFireStore(loggedInUserData)
        const profileUserFireStoreData = await fetchOrCreateUserInFireStore(profileData)

        // create new room 
        const roomId = await createNewRoomInFireStore(loggedInUserData.id,profileData.id)

        // add room id to logged in users collection and rooms collection in firestore
        await addRoomIdToRoomsCollectionInFireStore(roomId,loggedInUserData.id,profileUserFireStoreData)
        await addRoomIdToUsersCollectionInFireStore(roomId,loggedInUserData.id,loggedInUserFireStoreData)

        // add room id to profile users collection and rooms collection in firestore
        await addRoomIdToRoomsCollectionInFireStore(roomId,profileData.id,loggedInUserFireStoreData)
        await addRoomIdToUsersCollectionInFireStore(roomId,profileData.id,profileUserFireStoreData)

        dispatch({type: UserChatActionTypes.NEW_USER_CREATION_SUCCESS})
        console.log("room created successfully");
        // update rooms
        dispatch(getUserChatRooms({id: fromUserId}))

         }

        } catch (error) {
            console.warn("Failed to create room",error);
            dispatch(setError(error))
            dispatch({type: UserChatActionTypes.NEW_USER_CREATION_FAILURE})
        }
    }
}

/**
 * if user exists in users collection return user data else create new user and return new user data.
 * @param {*} data -> data of the user being used in the website
 * @returns user data in firestore users collection 
 */
const fetchOrCreateUserInFireStore = (data) => {
    return new Promise(async (resolve, reject) => {
        console.log("requesting user for",data);
        let userReference = db.collection('users').doc(data.id.toString())

       let doc =  await userReference.get()

       if (doc.exists) {
            resolve(doc.data())
       } else {
            resolve(await createNewFireStoreUser(data))
       }
    })
    
}

/**
 * create new user in users collection in firestore
 */
const createNewFireStoreUser = (data) => {
    return new Promise(async (resolve, reject) => {

    const userReference = db.collection('users').doc(data.id.toString())
    const roomReference = db.collection('rooms').doc(data.id.toString())

    const userData = {
        fcm : "",
        mobile: "",
        photo: data.photo ? data.photo : data.photoUrl ? data.photoUrl.length ? data.photoUrl[0] : "" : "",
        room: {},
        uid: data.id.toString(),
        userName: data.name
    }

    await userReference.set(userData)

    await roomReference.set(userData)

    resolve(userData)
    })
}

/**
 * 
 * @param {*} loggedInUserId 
 * @param {*} profileUserId 
 * @returns new room id
 */
const createNewRoomInFireStore = (loggedInUserId,profileUserId) => {
    return new Promise(async (resolve, reject) =>{
        const newRoomReference = db.collection('messages').doc();
        const newRoomId = newRoomReference.id;

        await newRoomReference.set({
            from: loggedInUserId.toString(),
            to: profileUserId.toString()
        })

        resolve(newRoomId)
    })
}

/**
 * add new room id to users rooms collection
 * @param {*} roomId 
 * @param {*} addToUserId 
 * @param {*} addData 
 * @returns 
 */
const addRoomIdToRoomsCollectionInFireStore = (roomId, addToUserId, addData)  => {
    return new Promise(async (resolve,reject) => {
        let roomReference = db.collection('rooms').doc(addToUserId.toString()).collection('usersRoom').doc(roomId)
       await roomReference.set(addData)
       resolve()
    })
}

/**
 * add new room id to users rooms collection
 * @param {*} roomId 
 * @param {*} addToUserId 
 * @param {*} currentAddToUserData 
 * @returns 
 */
const addRoomIdToUsersCollectionInFireStore = (roomId, addToUserId,currentAddToUserData) => {
    return new Promise(async (resolve,reject) => {
        let userReference = db.collection('users').doc(addToUserId.toString())
         /**
                 * adding room id to users collection:
                 * 
                 * if(user has room == null)
                 * we will create a new room in object and call the update function
                 * to update it in the user collection room property.
                 * 
                 * else we will add it to already existing room object and then calling the
                 * update function.
                 */
          if (!currentAddToUserData.room) { // creating new room object
            let newRoom = {}
            newRoom[roomId] = true

            userReference.update({
                room : newRoom
            })
        } else { // updating in already created room object
            let room = currentAddToUserData.room
            room[roomId] = true

            userReference.update({
                room : room
            })
            
        }
       resolve()
    })
}

export const getUserChatRooms = (data) => {
return dispatch => {
    dispatch({ type: UserChatActionTypes.GET_USER_CHAT_REFERENCE_START });
    const db = firebase.firestore();
    const currentUserReference = db.collection('users').doc(data.id);

    currentUserReference.get().then(
        (doc) => {
            if(doc.exists) {
                const userReference = doc.data();
                console.log("user rooms",userReference)
                dispatch({ 
                    type: UserChatActionTypes.GET_USER_CHAT_REFERENCE_SUCCESS,
                    payload: userReference
                 }); 
                 const roomKeyList = Object.keys(userReference.room)

                 if (roomKeyList.length > 0) {
                    dispatch({ 
                        type: UserChatActionTypes.GET_USER_CHAT_ROOMS_START,
                     }); 

                const roomData = []; 

                  roomKeyList.forEach(async (element,index) => {
                    const roomElement =  await fetchRoomDetails(data.id,element)//
                      
                            if (roomElement) {
                                roomData.push(roomElement);
                            }

                            if (index === roomKeyList.length - 1 ) {
                                dispatch({ 
                                    type: UserChatActionTypes.GET_USER_CHAT_ROOMS_SUCCESS,
                                    payload: roomData.sort( (a,b) => a.userName.localeCompare(b.userName))
                                 }); 
                            }
                 });
                 } else {
                    dispatch({ 
                        type: UserChatActionTypes.GET_USER_CHAT_ROOMS_SUCCESS,
                        rooms: []
                     }); 
                 }
                 

            } else {
                console.log("No such document!");
            }
        }
    ).catch((err) => {
        dispatch({ type: UserChatActionTypes.GET_USER_CHAT_REFERENCE_FAILURE });
        console.log("Error getting document:", err);
    })
}
}

const fetchRoomDetails = (userId,roomId) => {
    return new Promise((resolve,reject) => {
        const db = firebase.firestore();
        const roomReference = db.collection('rooms').doc(userId).collection('usersRoom').doc(roomId)
    
        roomReference.get().then(
            (doc) => {
                if (doc.exists) {
                    const room = doc.data();
                    room['roomId'] = roomId
                    resolve(room);
                } 
            }
        ).catch(
            (err) => {
                reject(err);
                console.log("Error getting room document:", err);
            }
        )
    })
    
}

export const getRoomMessages = (roomId) => {

    return dispatch => {
        dispatch({ type: UserChatActionTypes.GET_USER_CHAT_ROOMS_MESSAGES_START })
        const db = firebase.firestore();
        // const messagesList = [];
        const messageQuery = db.collection('messages').doc(roomId).collection('roomsMessage').orderBy('sentAt','asc')

        const listener = messageQuery.onSnapshot( snapshot => {
              snapshot.docChanges().forEach(function (change,index,list) {
                var message = change.doc.data();
                // messagesList.push(message);
                console.log('messagesss', message);

                if (change.type === 'added') {
                    dispatch({ 
                        type: UserChatActionTypes.GET_USER_CHAT_ROOMS_MESSAGES_SUCCESS,
                        messages: message,
                        activeListener: listener
                     }); 
                }
              })
        }) 
    }
}

export const shareNewText =  (Id,message,roomId,toUserId) => {

    console.log('values',roomId,Id,message)
    const db = firebase.firestore()
    const messageReference = db.collection('messages').doc(roomId).collection('roomsMessage').doc()
    const messageId = messageReference.id

   messageReference.set({
         fromUId: Id,
         messageId: messageId,
         messageText: message,
         playing : false,
         seenStatus: false,
         toUId: toUserId ? toUserId : '',
         sentAt: firebase.firestore.Timestamp.now()
     }).then(
         (res) => {
           console.log('added new text', res)
         }
     ).catch(
         (err) => {
            console.log('error in new text', err)
         }
     )
}


export const shareNewAudio =  (userId,blob,roomId,toUserId) => {

    console.log('values',roomId,userId,blob,toUserId)

    return dispatch => {
        dispatch({ type: UserChatActionTypes.GET_USER_CHAT_ROOMS_MESSAGES_START })
        const data = new FormData();
        data.append('audioFile', blob);

        UPLOAD_FROM_NODE({
          method: 'POST',
          url: `${API.UPLOAD_AUDIO_URL}?userIdFrom=${userId}&userIdTo=${toUserId}`,
          body: data,
        })
          .then(res => {
            let errorMessage;
            if (res.status !== 200) {
              errorMessage = 'Something Went Wrong!';
            }
            if (errorMessage) throw new Error(errorMessage)
            return res.json();
          })
          .then(resData => {
                console.log('audio upload response', resData);
                if (resData.status === true) {
                    shareNewText(userId,resData.audioUrl,roomId,toUserId);
                }
          })
          .catch(err => {
            console.log(err);
            dispatch(setError(err))
            dispatch({ type: UserChatActionTypes.GET_USER_CHAT_ROOMS_MESSAGES_FAILURE });
          });
      }
}







/**
 *  1. room creation
 *  2. get room id
 *  3. add room id to both users in users collection , if user exsists update the user  collection room property else first create new user and add room id to it.
 * 
 */

