import React from 'react';

import Managedby from '../../assets/profile/manage_by.svg';
import Verifiedat from '../../assets/profile/verified_at.svg';

const About = (about) => {
  return (
    <div id='About' className='mb-3'>
      <div className="row profile-row profile-field">
        <div className="col-6 profile-col-6">
          <img className="profile-titleImage" src={Verifiedat} alt="verified at" />
          <span className="profile-title">Verified At</span>
        </div>
        <div className="col-6 profile-col-6">
          <span
            className="profile-title">{about.verifiedAt}</span>
        </div>
      </div>

      <div className="row profile-row profile-field">
        <div className="col-6 profile-col-6">
          <img className="profile-titleImage" src={Managedby} alt="managedBy" />
          <span className="profile-title">
            Managed By
          </span>
        </div>
        <div className="col-6 profile-col-6">
          <span className="profile-title">
            {about.managedBy}
          </span>
        </div>
      </div>
      <div id="aboutSection">
        <h3 className="profile-titleHeading">Introduction
        </h3>
        <p className="profile-title" style={{ marginLeft: "0px" }}>
          <span style={{ color: "#4A5D6A", fontFamily: "sans-serif" }}>
            {about.about}
          </span>
        </p>
      </div>
    </div>
  )
}
export default About;