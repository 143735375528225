export const features = [
    {
        id: 1,
        details1: "Verified matches from",
        details2: "50+ Temples in India",
        image: '../../assets/featuresIllustrations/illustration1.jpg'
    },
    {
        id: 2,
        details1: "More than 50,000",
        details2: "suitable matches",
        image: '../../assets/featuresIllustrations/illustration2.jpg'
    },
    {
        id: 3,
        details1: "Get Your Personal Matchmaker",
        details2: "from 1000+Matchmakers",
        image: '../../assets/featuresIllustrations/illustration3.jpg'
    }
];
export const works = [
    {
        id: 1,
        header: "Join",
        details1: "Join our platform easily by providing your basic",
        details2: "details either online or offline at the temple centres.",
        image: '../../../components/assets/howItWorksIllustrations/illustration1.jpg',
        flex: "row"
    },
    {
        id: 2,
        header: "Get temple Verified Matches",
        details1: "Profiles are verified by the matchmakers at",
        details2: "hans matrimony temple centres to give you the",
        details3: " best profiles possible",
        image: '../../../components/assets/howItWorksIllustrations/illustration4.jpg',
        flex: "row-reverse"
    },
    {
        id: 3,
        header: "Get Personalized Matches",
        details1: "Contact our matchmakers to get your personalised preferences",
        details2: "matches anytime. Our matchmakers will share",
        details3: "profiles on a daily/weekly basis as per your",
        details4: "preferences",
        image: '../../../components/assets/howItWorksIllustrations/illustration3.jpg',
        flex: "row"
    },
    {
        id: 4,
        header: "Contact profiles you like",
        details1: "Choose your perfect matches and contact them by",
        details2: "becoming our prime member in just a single tap.",
        image: '../../../components/assets/howItWorksIllustrations/illustration2.jpg',
        flex: "row-reverse"
    },
    {
        id: 5,
        header: "Find your life partner",
        details1: "We at Hans Matrimony are dedicated to help you find",
        details2: "your life partner. Sign up and leave the hassle to us",
        image: '../../../components/assets/howItWorksIllustrations/illustration5.jpg',
        flex: "row"
    }
]
export const testimonials = [
    {
        id: 1,
        title: "Manav & Sakshi",
        paragraph: "There were a lot of marriage proposal at that time, but finding the right one was difficult. I Signed up on hans. I liked a profile there and today she is my wife. I’m thankful to hans to help me find my soulmate",
        image: "../../assets/testimonials/testimonial1.png"
    },
    {
        id: 2,
        title: "Lakshay & Priya",
        paragraph: "Thanks to Hans, i got my perfect match and now we are happily married. I will suggest everyone to be patient and Hans will definitely bring a good way for you.",
        image: "../../assets/testimonials/testimonial2.png"
    },
    {
        id: 3,
        title: "Dhawal & Pooja",
        paragraph: "After investing 3 years to find the perfect one for me traditionally, I landed to hans matrimony to continue my hunt. And the one I had been looking outside was waiting for me on this platform. Thanks you hans!",
        image: "../../assets/testimonials/testimonial3.png"
    },
    {
        id: 4,
        title: "Gunjan & Raghav",
        paragraph: "Would like to thank Hans Matrimony for finding a perfect partner for me. I was looking for a good match since last 6 months over multiple matrimonial sites however my wishes have been fulfilled by you.",
        image: "../../assets/testimonials/testimonial4.png"
    }
]