import React from "react";

import CallDark from "../../assets/profile/call-dark.svg";
import LocationDark from "../../assets/profile/location-dark.svg";
import Religion from "../../assets/profile/Religion.svg";
import MaritalStatus from "../../assets/profile/Marital Status.svg";
import FoodChoice from "../../assets/profile/Food Choice.svg";
import Weight from "../../assets/profile/Weight.svg";
import Occupation from "../../assets/profile/Occupation.svg";
import Designation from "../../assets/profile/Designation.svg";
import Income from "../../assets/profile/Income - Aaye.svg";
import Education from "../../assets/profile/Graduation.svg";
import College from "../../assets/profile/Schooling.svg";
import WorkingCity from "../../assets/profile/workingCity.svg";
import Manglik from "../../assets/profile/Mangalik.svg";
import DOB from "../../assets/profile/DOB.svg";
import BirthTime from "../../assets/profile/Birth Time.svg";
import { getProfileCount } from "../../../redux/auth/auth.action";
import IsDisplay from "./is-display/is-display.component";
import { useState } from "react";
import { makePayment } from "../../../redux/payment/payment.action";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import {
  CreditCardOffOutlined,
  CreditScoreOutlined,
  PersonAdd,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import axios from "axios";
import { getMyProfile } from "../../../redux/my-profile/my-profile.action";
import { useEffect } from "react";
import logoButtonComponent from "../../logo-button/logo-button.component";
const Personal = (personal, count, action, isContacted) => {
  console.log("isContacted", isContacted);
  console.log("contacted inside", personal.isContacted);
  const auth = useSelector((state) => state.auth.auth);
  const id = auth.id;
  const credit = parseInt(useSelector((state) => state.auth.credits));
  const [isActionClicked, setIsActionClicked] = useState(false);
  console.log("remaining credits", personal);
  return (
    <div id="Personal" className="mb-3">
      <div id="personalSection">
        <div className="d-flex justify-content-between">
          <span className="pofile-titleHeading">
            <h3 className="profile-titleHeading">Personal Details</h3>
          </span>
        </div>

        <div className="row profile-row" id="contactedSection">
          {/* <IsDisplay image={CallDark} title={personal.mobile} /> */}

          {personal.isContacted ? (
            <IsDisplay image={CallDark} title={personal.personal.mobile} />
          ) : (
            <IsDisplay image={CallDark} title="Visible After Contact" />
          )}
        </div>
        <IsDisplay image={LocationDark} title={personal.personal.locality} />
        <IsDisplay image={Religion} title={personal.personal.religion} />
        <IsDisplay
          image={MaritalStatus}
          title={personal.personal.maritalStatus}
        />
        <IsDisplay image={FoodChoice} title={personal.personal.foodChoice} />
        <IsDisplay image={Weight} title={personal.personal.weight} />
        <IsDisplay image={LocationDark} title={personal.personal.locality} />
        {/* <IsDisplay image={LocationDark} title='mobile' /> */}

        {/* <div className="row profile-row" *ngIf="item.marital_status === 'Divorcee'">
                            <div className="col-6 profile-col-6">
          <img className="profile-titleImage" src="../../../../assets/birthplace.svg"
            alt="marital status">
            <span className="profile-title">{{ item.children }}</span>
                            </div>
        </div>
      </div> */}
        <div id="careerSection">
          <div className="d-flex justify-content-between">
            <h3 className="profile-titleHeading">Career Details</h3>
          </div>

          <div className="row profile-row">
            <IsDisplay
              image={Occupation}
              title={personal.personal.occupation}
            />
            <IsDisplay
              image={Designation}
              title={personal.personal.profession}
            />
            <IsDisplay image={Income} title={personal.personal.monthlyIncome} />
            <IsDisplay image={Education} title={personal.personal.education} />
            <IsDisplay image={College} title={personal.personal.college} />
            <IsDisplay
              image={WorkingCity}
              title={personal.personal.workingCity}
            />
          </div>
        </div>
        <div id="horoscopeSection">
          <div className="d-flex justify-content-between">
            <h3 className="profile-titleHeading">Horoscope Details</h3>
          </div>

          <div className="row profile-row">
            <IsDisplay image={DOB} title={personal.personal.birthDate} />
            <IsDisplay image={BirthTime} title={personal.personal.birthTime} />
            <IsDisplay
              image={WorkingCity}
              title={personal.personal.birthPlace}
            />
            <IsDisplay image={Manglik} title={personal.personal.manglik} />
          </div>
        </div>
      </div>
    </div>
  );
};
// export default Personal;

const mapStateToProps = createStructuredSelector({
  // gender: selectUserGender,
  // credits:selectCredits
});

const mapDispatchToProps = (dispatch) => ({
  makePayment: () => dispatch(makePayment()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Personal);
