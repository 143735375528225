import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { AUDIO_URL_PREFIX } from "../../utils/api";

import {
  selectId,
  selectIsLead,
  selectHeader,
} from "../../redux/auth/auth.selector";
import {
  getMyProfile,
  uploadProfileAudio,
} from "../../redux/my-profile/my-profile.action";
import { selectMyProfile } from "../../redux/my-profile/my-profile.selector";

import {
  Add,
  Edit,
  Personal,
  About,
  Education,
  Horoscope,
  Contact,
  Family,
  Siblings,
  LifeStyle,
  BasicDetails,
  Career,
  Religion,
} from "./svg.jsx";

import playAudio from "../../components/assets/profile/play-audio.svg";
import recordAgain from "../../components/assets/profile/record-again-black.svg";

import PersonalForm from "./personal-form/personal-form.component";
import FamilyForm from "./family-form/family-form.component";
import PreferencesForm from "./prefereneces-from/prefereneces-from.component";

import "./my-profile.styles.scss";

import MyProfileHeader from "../../components/my-profile-header/my-profile-header.component";
import { useSelector } from "react-redux";
import Audio from "./audio/audio.component";
import { getMappedData } from "../../redux/mappedData/mappedData.action";
import { personalFormTypes } from "../../utils/display.utils";
import { useSelect } from "@mui/base";
const MyProfile = ({
  id,
  isLead,
  getMyProfile,
  myProfile,
  getMappedData,
  uploadIntroductionAudio,
  personalData,
}) => {
  const history = useNavigate();

  const auth = useSelector((state) => state.auth.auth);
  const userId = auth.id;
  //updated code
  // const whatsapp = useSelector(state => state.myProfile?.profile[0]?.whatsapp || state.myProfile?.whatsapp);
  // const user_mobile =  useSelector(state => state.myProfile?.profile[0]?.user_mobile || state.myProfile?.user_mobile);
  const whatsapp = useSelector(
    (state) =>
      state.myProfile?.profile?.[0]?.whatsapp || state.myProfile?.whatsapp
  );
  const user_mobile = useSelector(
    (state) =>
      state.myProfile?.profile?.[0]?.user_mobile || state.myProfile?.user_mobile
  );
  //Email contact
  const emailFamily = useSelector(
    (state) => state.myProfile?.profile?.[0]?.email_family
  );

  // height element
  // const height_int =  useSelector(state => state.myProfile?.profile[0]?.height_int || null );
  const height_int = useSelector(
    (state) => state.myProfile?.profile?.[0]?.height_int || null
  );
  console.log("int height", height_int);
  //about_family
  const aboutFamily = useSelector(
    (state) => state.myProfile?.profile?.[0]?.about_family
  );
  //City family
  const cityFamily = useSelector(
    (state) => state.myProfile?.profile?.[0]?.city_family
  );
  //Religion Updated
  const religionPref = useSelector(
    (state) => state.myProfile?.preferences?.religionPref[0]
  );
  console.log(religionPref);
  let religion;
  if (religionPref === 1) {
    religion = "Hindu";
  } else if (religionPref === 2) {
    religion = "Muslim";
  } else if (religionPref === 3) {
    religion = "Christian";
  } else if (religionPref === 4) {
    religion = "Buddhist";
  } else if (religionPref === 5) {
    religion = "Bahai";
  } else if (religionPref === 6) {
    religion = "Jewish";
  } else if (religionPref === 7) {
    religion = "Parsi";
  } else if (religionPref === 8) {
    religion = "Sikh";
  } else if (religionPref === 9) {
    religion = "Jain";
  }

  const height = (height_int / 12).toFixed(2);

  const [active, setActive] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [showFamily, setFamily] = useState(false);
  const [showPersonal, setPersonal] = useState({
    show: false,
    section: null,
  });
  const [showPreferences, setPreferences] = useState(false);

  useEffect(() => {
    getMyProfile({
      id,
      view: 0,
      header: selectHeader,
    });
    getMappedData();
  }, []);

  useEffect(() => {
    console.log("profile", myProfile);
  }, [myProfile]);

  const uploadProfileAudio = (url) => {
    const data = new FormData();
    data.append("audioFile", url);
    uploadIntroductionAudio(id, data);
  };

  if (!myProfile) {
    return (
      <div className="my-profile">
        <div className="container"></div>
      </div>
    );
  }

  // console.log("myProfile", myProfile);
  let personal = (
    <div className={`body ${active === 0 ? "d-block" : "d-none"}`}>
      <h1>Introduction</h1>
      <div className="audio">
        <div>
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.3125 25H4.6875C2.09846 25 0 22.9015 0 20.3125V4.6875C0 2.09846 2.09846 0 4.6875 0H20.3125C22.9015 0 25 2.09846 25 4.6875V20.3125C25 22.9015 22.9015 25 20.3125 25Z"
              fill="#E2EDFC"
            />
            <path
              d="M12.9999 14.9996C13.7639 14.9996 14.4176 14.7276 14.9616 14.1839C15.5055 13.6401 15.7774 12.986 15.7774 12.2221V7.7778C15.7774 7.01389 15.5057 6.36003 14.9616 5.81603C14.4176 5.27214 13.7639 5 12.9999 5C12.236 5 11.5821 5.27214 11.0381 5.81603C10.4941 6.35994 10.2222 7.01389 10.2222 7.7778V12.2221C10.2222 12.986 10.4942 13.6401 11.0381 14.1839C11.582 14.7276 12.236 14.9996 12.9999 14.9996Z"
              fill="#3577CE"
            />
            <path
              d="M17.8348 10.7204C17.7251 10.6104 17.5946 10.5554 17.4442 10.5554C17.2939 10.5554 17.1637 10.6104 17.0536 10.7204C16.9437 10.8303 16.8888 10.9605 16.8888 11.1109V12.2221C16.8888 13.2927 16.5082 14.2084 15.7472 14.9694C14.9864 15.7304 14.0706 16.1109 12.9999 16.1109C11.9293 16.1109 11.0136 15.7304 10.2525 14.9694C9.49159 14.2086 9.11115 13.2927 9.11115 12.2221V11.1109C9.11115 10.9605 9.05615 10.8303 8.94624 10.7204C8.83627 10.6104 8.70621 10.5554 8.55565 10.5554C8.40509 10.5554 8.27488 10.6104 8.16497 10.7204C8.05497 10.8303 8 10.9605 8 11.1109V12.2221C8 13.501 8.42686 14.6136 9.28035 15.5596C10.1339 16.5057 11.1885 17.0482 12.4444 17.187V18.3329H10.2222C10.0717 18.3329 9.94156 18.388 9.83162 18.498C9.72165 18.6078 9.66665 18.7381 9.66665 18.8886C9.66665 19.0388 9.72165 19.1693 9.83162 19.2791C9.94156 19.389 10.0717 19.4442 10.2222 19.4442H15.7775C15.9279 19.4442 16.0583 19.3891 16.1681 19.2791C16.2782 19.1693 16.3332 19.0388 16.3332 18.8886C16.3332 18.7381 16.2782 18.6079 16.1681 18.498C16.0583 18.388 15.928 18.3329 15.7775 18.3329H13.5556V17.187C14.8112 17.0482 15.8658 16.5057 16.7194 15.5596C17.573 14.6136 18 13.501 18 12.2221V11.1109C18 10.9605 17.9449 10.8304 17.8348 10.7204Z"
              fill="#3577CE"
            />
          </svg>
        </div>
        {!myProfile.personal.audioIntro ? (
          <>
            <div>
              <p>Add Audio Introduction</p>
            </div>
            <div onClick={() => setModalShow(true)}>
              <Add />
            </div>
          </>
        ) : (
          <>
            <div>
              <p className="f12">
                Play Or Change Audio <br /> Introduction
              </p>
            </div>
            <div>
              <img
                onClick={() => setModalShow(true)}
                src={playAudio}
                alt="play audio"
              />
              <img
                onClick={() => setModalShow(true)}
                className="ml-2"
                src={recordAgain}
                alt="record again"
              />
            </div>
          </>
        )}
        <Audio
          show={modalShow}
          onHide={() => setModalShow(false)}
          audioUrl={AUDIO_URL_PREFIX + myProfile.personal.audioIntro}
          uploadAudio={uploadProfileAudio}
        />
      </div>

      <h1>Personal Details</h1>
      <div className="content">
        <div className="d-flex justify-content-between algin-items-center content-container">
          <Personal />
          <Edit
            onClick={() =>
              setPersonal({
                show: true,
                section: personalFormTypes.PERSONAL_DETAILS,
              })
            }
          />
        </div>
        <div className="details d-flex flex-wrap justify-content-between algin-items-center">
          <div className="items">
            <span>
              {myProfile.personal.religion} | {myProfile.personal.caste}
            </span>
          </div>
          <div className="items">
            <span>
              {myProfile.personal.age} | {myProfile.personal.birth_date}
            </span>
          </div>
          <div className="items">
            {/* <span>{`${myProfile.personal.height} | ${myProfile.personal.weight}`}</span> */}
            <span>{`${height} ft| ${myProfile.personal.weight}`}</span>
          </div>
          <div className="items">
            <span>{myProfile.personal.foodChoice}</span>
          </div>
          <div className="items">
            <span>{myProfile.personal.locality}</span>
          </div>
          <div className="items">
            <span>{myProfile.personal.maritalStatus}</span>
          </div>
        </div>
      </div>

      <h1>About Me</h1>
      <div className="content">
        <div className="d-flex justify-content-between algin-items-center content-container">
          <About />
          <Edit
            onClick={() =>
              setPersonal({
                show: true,
                section: personalFormTypes.ABOUT,
              })
            }
          />
        </div>
        <div className="details about-container">
          <div className="items w-100 ">
            <span>{myProfile.personal.about}</span>
          </div>
        </div>
      </div>

      <h1>Education & Career details</h1>
      <div className="content">
        <div className="d-flex justify-content-between algin-items-center content-container">
          <Education />
          <Edit
            onClick={() =>
              setPersonal({
                show: true,
                section: personalFormTypes.EDUCATION_AND_CAREER,
              })
            }
          />
        </div>
        <div className="details d-flex flex-wrap justify-content-between algin-items-center ">
          <div className="items">
            <span className="item-label">Degree</span>
            <span className="item">{myProfile.personal.degree}</span>
          </div>
          <div className="items">
            <span className="item-label">College</span>
            <span className="item">{myProfile.personal.college}</span>
          </div>
          <div className="items">
            <span className="item-label">Additional Qualification</span>
            <span className="item mt-1">{myProfile.personal.additional}</span>
          </div>
          <div className="items">
            <span className="item-label">Occupation</span>
            <span className="item">{myProfile.personal.occupation}</span>
          </div>
          <div className="items">
            <span className="item-label">Annual Income (In LPA)</span>
            <span className="item">
              {(myProfile.personal.income * 100000).toFixed(1)}
            </span>
          </div>
          <div className="items">
            <span className="item-label">Working city</span>
            <span className="item">{myProfile.personal.working_city}</span>
          </div>
        </div>
      </div>

      <h1>Horoscope</h1>
      <div className="content">
        <div className="d-flex justify-content-between algin-items-center content-container">
          <Horoscope />
          <Edit
            onClick={() =>
              setPersonal({
                show: true,
                section: personalFormTypes.HOROSCOPE,
              })
            }
          />
        </div>
        <div className="details d-flex flex-wrap justify-content-between algin-items-center">
          <div className="items" style={{ width: "100%" }}>
            <span className="item-label">Horoscope</span>
            <span className="item">{myProfile.personal.horoscope}</span>
          </div>

          <div className="items" style={{ width: "100%" }}>
            <span className="item-label">Birth time</span>
            <span className="item">
              {myProfile.personal.birth_time} | {myProfile.personal.birth_date}{" "}
              | {myProfile.personal.birth_place}
            </span>
          </div>

          <div className="items" style={{ width: "100%" }}>
            <span className="item-label">Gotra</span>
            <span className="item">{myProfile.personal.gotra}</span>
          </div>
        </div>
      </div>

      <h1>Contact</h1>
      <div className="content">
        <div className="d-flex justify-content-between algin-items-center content-container">
          <Contact />
          <Edit
            onClick={() =>
              setPersonal({
                show: true,
                section: personalFormTypes.CONTACT,
              })
            }
          />
        </div>
        <div className="details d-flex flex-wrap justify-content-between algin-items-center ">
          <div className="items">
            <span className="item key">Email</span>
          </div>
          <div className="items">
            {/* <span className="item">{myProfile.profile[0].email_family}</span> */}
            <span className="item">{myProfile.personal.email}</span>
          </div>
          <div className="items">
            <span className="item key">Mobile</span>
          </div>
          <div className="items">
            {/* <span className='item'>{myProfile.personal.mobile}</span> */}
            <span className="item">{user_mobile}</span>
          </div>
          <div className="items">
            <span className="item key">Whatsapp</span>
          </div>
          <div className="items">
            {/* <span className='item'>{myProfile.personal.mobile}</span> */}
            <span className="item">{whatsapp}</span>
          </div>
        </div>
      </div>
    </div>
  );
  console.log("family", myProfile.family);
  let family = (
    <div className={`body ${active === 1 ? "d-block" : "d-none"}`}>
      <h1>Family Details</h1>
      <div className="content">
        <div className="d-flex justify-content-between algin-items-center content-container">
          <Family />
          <Edit onClick={() => setFamily(true)} />
        </div>
        <div className="details d-flex flex-wrap justify-content-between algin-items-center ">
          <div className="items">
            <span className="item key">About Family</span>
          </div>
          <div className="items">
            {/* <span className='item'>{myProfile.family.about}</span> */}
            <span className="item">{aboutFamily}</span>
          </div>
          <div className="items">
            <span className="item key">Mother Is</span>
          </div>
          <div className="items">
            <span className="item">{myProfile.family.mother}</span>
          </div>
          <div className="items">
            <span className="item key">Father Is</span>
          </div>
          <div className="items">
            <span className="item">{myProfile.family.father}</span>
          </div>
          <div className="items">
            <span className="item key">Family Living In</span>
          </div>
          <div className="items">
            {/* <span className='item'>{myProfile.family.familyLivingIn}</span> */}
            <span className="item">{cityFamily}</span>
          </div>
          <div className="items">
            <span className="item">{}</span>
          </div>
        </div>
      </div>

      <h1>Siblings</h1>
      <div className="content">
        <div className="d-flex justify-content-between algin-items-center content-container">
          <Siblings />
          <Edit onClick={() => setFamily(true)} />
        </div>
        <div className="details d-flex flex-wrap justify-content-between algin-items-center ">
          <div className="items">
            <span className="item key"> Married Sisters :</span>
          </div>
          <div className="items">
            <span className="item">{myProfile.family.married_sisters}</span>
          </div>
          <div className="items">
            <span className="item key"> Unmarried Sisters :</span>
          </div>
          <div className="items">
            <span className="item">{myProfile.family.unmarried_sisters}</span>
          </div>
          <div className="items">
            <span className="item key">Married Brothers :</span>
          </div>
          <div className="items">
            <span className="item">{myProfile.family.married_brothers}</span>
          </div>
          <div className="items">
            <span className="item key">Unmarried Brothers:</span>
          </div>
          <div className="items">
            <span className="item">{myProfile.family.unmarried_brothers}</span>
          </div>
        </div>
      </div>

      <h1>Lifestyle</h1>
      <div className="content">
        <div className="d-flex justify-content-between algin-items-center content-container">
          <LifeStyle />
          <Edit onClick={() => setFamily(true)} />
        </div>
        <div className="details d-flex flex-wrap justify-content-between algin-items-center ">
          <div className="items">
            <span className="item key">Family Type:</span>
          </div>
          <div className="items">
            <span className="item">{myProfile.family.familyType}</span>
          </div>
          <div className="items">
            <span className="item key">Family Income</span>
          </div>
          <div className="items">
            <span className="item">{myProfile.family.familyIncome}</span>
          </div>
          <div className="items">
            <span className="item key">House Type</span>
          </div>
          <div className="items">
            <span className="item">{myProfile.family.houseType}</span>
          </div>
          <div className="items">
            <span className="item key">Living With Parents?</span>
          </div>
          <div className="items">
            <span className="item">{myProfile.family.livingWithParents}</span>
          </div>
        </div>
      </div>
    </div>
  );
  // console.log(myProfile.preferences.foodChoice);
  let preferences = (
    <div className={`body ${active === 2 ? "d-block" : "d-none"}`}>
      <h1>Basic Details</h1>
      <div className="content">
        <div className="d-flex justify-content-between algin-items-center content-container">
          <BasicDetails />
          <Edit onClick={() => setPreferences(true)} />
        </div>
        <div className="details d-flex flex-wrap justify-content-between algin-items-center">
          <div className="items">
            <span className="item key">Age</span>
          </div>
          <div className="items">
            <span className="item">{myProfile.preferences.age}</span>
          </div>
          <div className="items">
            <span className="item key">Height</span>
          </div>
          <div className="items">
            <span className="item">{myProfile.preferences.height}</span>
          </div>
          <div className="items">
            <span className="item key">Marital Status</span>
          </div>
          <div className="items">
            <span className="item">{myProfile.preferences.maritalStatus}</span>
          </div>

          <div className="items">
            <span className="item key">Manglik Preference</span>
          </div>
          <div className="items">
            <span className="item">
              {myProfile.preferences.manglikPreference}
            </span>
          </div>

          <div className="items">
            <span className="item key">Food Choice</span>
          </div>
          <div className="items">
            <span className="item">{myProfile.preferences.foodChoice}</span>
          </div>
        </div>
      </div>

      <h1>Career & Occupation</h1>
      <div className="content">
        <div className="d-flex justify-content-between algin-items-center content-container">
          <Career />
          <Edit onClick={() => setPreferences(true)} />
        </div>
        <div className="details d-flex flex-wrap justify-content-between algin-items-center ">
          <div className="items">
            <span className="item key">Annual Income</span>
          </div>
          <div className="items">
            <span className="item">{myProfile.preferences.annualIncome}</span>
          </div>
          <div className="items">
            <span className="item key">Working preference</span>
          </div>
          <div className="items">
            <span className="item">
              {myProfile.preferences.workingPreference}
            </span>
          </div>
        </div>
      </div>

      <h1>Religion & Caste</h1>
      <div className="content">
        <div className="d-flex justify-content-between algin-items-center content-container">
          <Religion />
          <Edit onClick={() => setPreferences(true)} />
        </div>
        <div className="details d-flex flex-wrap justify-content-between algin-items-center ">
          <div className="items">
            <span className="item key">Religion Preference</span>
          </div>
          <div className="items">
            {/* <span className='item'>{myProfile.preferences.religionPreference}</span> */}
            <span className="item">{religion}</span>
          </div>
          <div className="items">
            <span className="item key">Caste Preference</span>
          </div>
          <div className="items">
            <span className="item">
              {myProfile.preferences.castePreference}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="my-profile">
      <div className="container">
        <div className="back" onClick={() => history(-1)}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.67 3.87001L9.9 2.10001L0 12L9.9 21.9L11.67 20.13L3.54 12L11.67 3.87001Z"
              fill="black"
            />
          </svg>
        </div>
        <MyProfileHeader />
        <hr />

        <div className="nav">
          <div
            onClick={() => setActive(0)}
            className={`${active === 0 ? "active" : ""}`}
          >
            <span>Personal</span>
          </div>
          <div
            onClick={() => setActive(1)}
            className={`${active === 1 ? "active" : ""}`}
          >
            <span>Family</span>
          </div>
          <div
            onClick={() => setActive(2)}
            className={`${active === 2 ? "active" : ""}`}
          >
            <span>Preferences</span>
          </div>
        </div>

        {personal}
        {family}
        {preferences}
        <FamilyForm
          show={showFamily}
          onHide={() => setFamily(false)}
          familyData={myProfile.family}
        />

        <PersonalForm
          show={showPersonal.show}
          onHide={() =>
            setPersonal({
              show: false,
              section: null,
            })
          }
          personalData={{ ...myProfile.personal, userId }}
          formType={showPersonal.section}
        />

        <PreferencesForm
          show={showPreferences}
          onHide={() => setPreferences(false)}
          preferenceData={myProfile.preferences}
        />
      </div>
    </div>
  );
};

const mapStateToProp = createStructuredSelector({
  id: selectId,
  isLead: selectIsLead,
  myProfile: selectMyProfile,
});

const mapDispatchToProps = (dispatch) => ({
  getMyProfile: (data) => dispatch(getMyProfile(data)),
  getMappedData: () => dispatch(getMappedData()),
  uploadIntroductionAudio: (id, data) => dispatch(uploadProfileAudio(id, data)),
});

export default connect(mapStateToProp, mapDispatchToProps)(MyProfile);
