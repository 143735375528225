import React, { Component } from "react";
import ill1 from "../../../components/assets/howItWorksIllustrations/illustration1.jpg";
import ill2 from "../../../components/assets/howItWorksIllustrations/illustration2.jpg";
import ill3 from "../../../components/assets/howItWorksIllustrations/illustration3.jpg";
import ill4 from "../../../components/assets/howItWorksIllustrations/illustration4.jpg";
import Frame6 from "../../../components/assets/hero/Frame6.svg";
import "./how-it-work.styles.scss";
// import {useNavigate} from 'react-router-dom';
import { Link } from "react-router-dom";

import { works } from "../../../components/assets/Data/data.js";

class HowItWork extends Component {
  state = {
    iPhone5: "",
    rightAppear: "",
    rightAppear1: "",
    rightAppear2: "",
    rightAppear3: "",
    appear: "",
    appear1: "",
    appear2: "",
    appear3: "",
    btnA: "",
    innerWidth: "",
    mobileBreakpoint: 320,
    works: works,
  };
  componentDidMount() {
    window.addEventListener("scroll", this.scroll, true);
    this.iPhone();
    this.setState({ iPhone5: this.iPhone() });
  }
  scroll = (event) => {
    this.scrollAppear();
  };
  scrollAppear() {
    let left = document.querySelector(".how-left-animate");
    let left1 = document.querySelector(".how-left-animate1");
    let left2 = document.querySelector(".how-left-animate2");
    let left3 = document.querySelector(".how-left-animate3");
    let bn = document.querySelector(".how-bn");
    let right = document.querySelector(".how-right");
    let right1 = document.querySelector(".how-right1");
    let right2 = document.querySelector(".how-right2");
    let right3 = document.querySelector(".how-right3");

    let leftPosition, leftPosition1, leftPosition2, leftPosition3;
    let bnPosition;
    let rightPosition, rightPosition1, rightPosition2, rightPosition3;

    if (
      left &&
      left1 &&
      left2 &&
      left3 &&
      bn &&
      right &&
      right1 &&
      right2 &&
      right3
    ) {
      leftPosition = left.getBoundingClientRect().top;
      leftPosition1 = left1.getBoundingClientRect().top;
      leftPosition2 = left2.getBoundingClientRect().top;
      leftPosition3 = left3.getBoundingClientRect().top;
      bnPosition = bn.getBoundingClientRect().top;
      rightPosition = right.getBoundingClientRect().top;
      rightPosition1 = right1.getBoundingClientRect().top;
      rightPosition2 = right2.getBoundingClientRect().top;
      rightPosition3 = right3.getBoundingClientRect().top;
    }
    let screenPosition = window.innerHeight;
    let rightAppear, rightAppear1, rightAppear2, rightAppear3;
    let appear, appear1, appear2, appear3;
    let btnA;
    if (bnPosition < screenPosition) {
      //  left.classNameList.add('.left-appear')
      btnA = true;
    } else {
      btnA = false;
    }
    if (leftPosition < screenPosition) {
      //  left.classNameList.add('.left-appear')
      appear = true;
    } else {
      appear = false;
    }
    if (leftPosition1 < screenPosition) {
      //  left.classNameList.add('.left-appear')
      appear1 = true;
    } else {
      appear1 = false;
    }
    if (leftPosition2 < screenPosition) {
      //  left.classNameList.add('.left-appear')
      appear2 = true;
    } else {
      appear2 = false;
    }
    if (leftPosition3 < screenPosition) {
      //  left.classNameList.add('.left-appear')
      appear3 = true;
    } else {
      appear3 = false;
    }
    if (rightPosition < screenPosition) {
      //  left.classNameList.add('.left-appear')
      rightAppear = true;
    } else {
      rightAppear = false;
    }
    if (rightPosition1 < screenPosition) {
      //  left.classNameList.add('.left-appear')
      rightAppear1 = true;
    } else {
      rightAppear1 = false;
    }
    if (rightPosition2 < screenPosition) {
      //  left.classNameList.add('.left-appear')
      rightAppear2 = true;
    } else {
      rightAppear2 = false;
    }
    if (rightPosition3 < screenPosition) {
      //  left.classNameList.add('.left-appear')
      rightAppear3 = true;
    } else {
      rightAppear3 = false;
    }
    this.setState({
      rightAppear,
      rightAppear1,
      rightAppear2,
      rightAppear3,
      appear,
      appear1,
      appear2,
      appear3,
      btnA,
    });
  }
  goToCompatibility() {
    // const history = useHistory();
    // history.push("/courses")
    // this.router.navigateByUrl('/fourReg');
    // const navigate = useNavigate();
    // navigate('/home')
  }
  resize() {
    this.innerWidth = window.innerWidth;
    return this.innerWidth;
  }
  iPhone() {
    window.onload = this.resize;
    window.onresize = this.resize;

    if (this.resize() <= this.mobileBreakpoint) {
      return true;
    } else {
      return false;
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scroll, true);
  }

  render() {
    const {
      rightAppear,
      rightAppear1,
      rightAppear2,
      rightAppear3,
      appear,
      appear1,
      appear2,
      appear3,
      btnA,
    } = this.state;
    return (
      <div className="abc">
        <div className="how-it-works-con">
          <div className="d-flex justify-content-center ">
            <div className="how">
              <h2 className="how-h2">
                How it <span style={{ color: "#24ADD8" }}>Works</span>
                <hr className="how-one" />
                <hr className="how-two" />
              </h2>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {!this.state.iPhone5 && (
                <div ngIf="!iPhone5" className="col-1"></div>
              )}
              <div className="col-5 how-left">
                <img
                  onScroll={this.scrollAppear}
                  className={`how-img-fluid how-img-left how-left-animate ${
                    appear ? "how-left-appear" : ""
                  }`}
                  src={ill1}
                  alt="hansmatrimony-how-it-works-illustration1"
                />
              </div>
              <div className="col-1">
                <div className="how-join"></div>
              </div>
              <div
                onScroll={this.scrollAppear}
                className={`col-5 how-right ${
                  rightAppear ? "how-right-appear" : ""
                }`}
              >
                <h3 className="how-h3">{this.state.works[0].header}</h3>
                <p className="how-p">
                  Join our platform easily by providing your basic details
                  either online or offline at the temple centres.
                </p>
              </div>
            </div>
            <div className="row ">
              {!this.state.iPhone5 && (
                <div ngIf="!iPhone5" className="col-1"></div>
              )}
              <div className="col-5 how-left">
                <div
                  scroll="scrollAppear()"
                  className={`how-right-text how-left-animate1 ${
                    appear1 ? "how-left-appear" : ""
                  }`}
                >
                  <h3 className="how-h3">{this.state.works[2].header}</h3>
                  <p className="how-p">
                    Our matchmakers help you get personalised matches, verified
                    at temples.They will share profiles on a daily/weekly basis
                    as per your preferences
                  </p>
                </div>
              </div>

              <div className="col-1">
                <div className="how-join"></div>
              </div>
              <div
                scroll={this.scrollAppear}
                className={`how-right1 col-5 ${
                  rightAppear1 ? "how-right-appear" : ""
                }`}
              >
                <img
                  className="how-img-right"
                  src={ill2}
                  alt="hansmatrimony-how-it-works-illustration2"
                />
              </div>
            </div>
            <div className="row">
              {!this.state.iPhone5 && (
                <div ngIf="!iPhone5" className="col-1"></div>
              )}
              <div className="col-5 how-left">
                <img
                  onScroll={this.scrollAppear}
                  className={`how-img-fluid how-img-left how-left-animate2 ${
                    appear2 ? "how-left-appear" : ""
                  }`}
                  src={ill3}
                  alt="hansmatrimony-how-it-works-illustration3"
                />
              </div>

              <div className="col-1">
                <div className="how-join"></div>
              </div>
              <div
                onScroll={this.scrollAppear}
                className={`col-5 how-right2 ${
                  rightAppear2 ? "how-right-appear" : ""
                }`}
              >
                <h3 className="how-3">{this.state.works[3].header}</h3>
                <p className="how-p">
                  Choose your perfect matches and contact them by becoming our
                  prime member in just a single tap.
                </p>
              </div>
            </div>
            <div className="row">
              {!this.state.iPhone5 && (
                <div ngIf="!iPhone5" className="col-1"></div>
              )}
              <div
                onScroll={this.scrollAppear}
                className={`col-5 how-left-animate3 how-bottom ${
                  appear3 ? "how-left-appear" : ""
                }`}
              >
                <div className="how-right-text ">
                  <h3 className="how-3">{this.state.works[4].header}</h3>
                  <p className="how-p">
                    We at Hans Matrimony are dedicated to help you find your
                    life partner. Sign up and leave the hassle to us
                  </p>
                </div>
              </div>

              <div className="col-1">
                <div className="how-join how-iPhone5Join"></div>
              </div>
              <div
                onScroll={this.scrollAppear}
                className={`how-right3 col-5 ${
                  rightAppear3 ? "how-right-appear" : ""
                }`}
              >
                <img
                  className="how-img-right"
                  src={ill4}
                  alt="hansmatrimony-how-it-works-illustration4"
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            {/* <button className={`how-button how-bn ${btnA ? 'how-left-appear' : ""}`} onScroll={this.scrollAppear} onClick={this.goToCompatibility} >Register For Free</button> */}
            {/* <button className={`how-button how-bn ${btnA ? 'how-left-appear' : ""}`} onScroll={this.scrollAppear} onClick={this.goToCompatibility}>Register For Free</button> */}
            <Link
              to="/fourReg"
              id="registerforfree"
              className={`how-button how-bn ${btnA ? "how-left-appear" : ""}`}
              onScroll={this.scrollAppear}
            >
              Register For Free
            </Link>
          </div>
        </div>

        {/* mobile responsive brand div for new landing page */}
        <div className="brand-container-div" style={{ marginTop: "0px" }}>
          <div className="brand-details-con" style={{}}>
            A community of over 1 million+ Famlies
          </div>
          <div className="brand-images-con-div" style={{}}>
            <img
              className="frame-image-6"
              src={Frame6}
              alt="Frame6"
              style={{ marginLeft: "25px" }}
            />
            <div style={{}}>with 200,000+ Families united</div>
          </div>
        </div>
      </div>
    );
  }
}

export default HowItWork;
