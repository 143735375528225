import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import crossbtn from '../../../components/assets/hero/cross-btn.png';
import navbarimage from '../../../components/assets/hero/navbarimage.svg'
import logoOld from '../../../components/assets/hero/logoOld.png';
import text from "../../../components/assets/hero/text.svg";
import googleplay from "../../../components/assets/newAppIllustrations/googleplay.jpg";
import "../../../App.scss"
const Sidebar = (props) => {

  const handleClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.twango.me')
  }

  const [userlogin, setUserlogin] = useState(false)
  return (
    <div className='Active-sidebar-comp'>
      <nav className={props.sidebar ? 'nav-menu active' : 'nav-menu'}>
        <ul className='ulsidebar' >
          <div className='mob-responsive-sidebar' style={{ marginRight: '10px', height: '50vh' }}>
            <div >
              <img src={crossbtn} alt='crossbuttonsidebar' style={{ position: 'absolute', top: '1vh', width: '7vw', left: '40vw', right: '5vh' }} />
              <img src={navbarimage} style={{ width: '20%', position: 'relative', top: '2vh', right: '14px' }}></img>
              {/* <b className='nav-text'>Hans Matrimony</b> */}
            </div>
            <br></br>
            <li style={{ marginRight: '10vw' }}><Link to='/subscription' routerLinkActive="router-link-active">Subscription</Link></li>
            <br></br>
            <li style={{ marginRight: '10vw' }}><a mdbPageScroll href="#kundali">Kundali Matching</a></li>
            <br></br>
            {userlogin ? (
              <li>
                <Link to='/chat' routerLinkActive="router-link-active">See Profiles</Link>
                <br></br>

                <li>

                  <img onClick={handleClick} className="land-googleplay" src={googleplay} alt="hansmatrimony-app-download-button" />

                </li>
                <br></br>

                <li ngIf="loggedIn">
                  <a style={{ cursor: "pointer" }} className='btn hero-btn hero-register-btn' click="logOut()">LogOut</a>
                </li>
              </li>) : (
              <li ngIf="loggedIn" style={{ marginRight: '10vw' }}>
                <Link to='/phone-number' routerLinkActive="router-link-active">Login/Register</Link>
                <br></br>
                <br></br>

                <li>
                  <a onClick={handleClick} style={{ position: 'absolute', left: '8vw', top: '30vh' }}>
                    <img click="downloadApp()" className="land-googleplay" src={googleplay} alt="hansmatrimony-app-download-button" />
                  </a>
                </li>
                {/* <li>
                <Link to='/fourReg' className='btn hero-btn hero-register-btn'>Register For Free</Link>
                </li> */}
              </li>
            )}
          </div>

        </ul>
      </nav>
    </div>
  )
}

export default Sidebar;