import React from "react";
import { FaWhatsapp, FaPhone, FaMapMarker } from "react-icons/fa";
import "./togglebutton.scss";
const ToggleButton = () => {
  const handleOptionClick = (option) => {
    let url;
    switch (option) {
      case "whatsapp":
        url = "https://wa.me/+918178083559";
        break;
      case "call":
        url = "tel:+919697989697";
        break;
      default:
        break;
    }
    if (url) {
      window.open(url, "_blank");
    }
  };

  return (
    <div className="fixed bottom-5 right-5 z-50 react-icon">
      <div className="flex flex-col gap-4 mt-1">
        <button
          className="bg-green-500 text-white p-2 rounded-md"
          onClick={() => handleOptionClick("whatsapp")}
        >
          <FaWhatsapp />
        </button>
        <button
          className="bg-blue-500 text-white p-2 rounded-md"
          onClick={() => handleOptionClick("call")}
        >
          <FaPhone />
        </button>
        {/* <button
          className="bg-red-500 text-white p-2 rounded-md"
          onClick={() => handleOptionClick("maps")}
        >
          <FaMapMarker />
        </button> */}
        {/* Add more buttons for additional options */}
      </div>
    </div>
  );
};

export default ToggleButton;
