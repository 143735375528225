import { createSelector } from 'reselect';

const mappedData = state => state.mappedData;


export const selectGenderMapping = createSelector(
    [mappedData],
    mappedData => mappedData.genderMapping
  )

export const selectReligionMapping = createSelector(
    [mappedData],
    mappedData => mappedData.religionMapping
  )

  export const selectCasteMapping = createSelector(
    [mappedData],
    mappedData => mappedData.casteMapping
  )

  export const selectEducationMapping = createSelector(
    [mappedData],
    mappedData => mappedData.educationMapping
  )

  export const selectUserOccupationMapping = createSelector(
    [mappedData],
    mappedData => mappedData.userOccupationMapping
  )

  export const selectParentOccupationMapping = createSelector(
    [mappedData],
    mappedData => mappedData.parentOccupationMapping
  )

  export const selectRelationMapping = createSelector(
    [mappedData],
    mappedData => mappedData.relationMapping
  )

  export const selectManglikMapping = createSelector(
    [mappedData],
    mappedData => mappedData.manglikMapping
  )

  export const selectMaritalMapping = createSelector(
    [mappedData],
    mappedData => mappedData.maritalStatusMapping
  )

  export const selectFoodChoiceMapping = createSelector(
    [mappedData],
    mappedData => mappedData.foodChoiceMapping
  )

  export const selectFamilyChoiceMapping = createSelector(
    [mappedData],
    mappedData => mappedData.familyTypeMapping
  )

  export const selectHouseTypeMapping = createSelector(
    [mappedData],
    mappedData => mappedData.houseTypeMapping
  )

  export const selectCountryList = createSelector(
    [mappedData],
    mappedData => mappedData.countryList
  )
