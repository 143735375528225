

const RegisterActionTypes = {
    // GET_ALL_CASTE_START: 'GET_ALL_CASTE_START',
    // GET_ALL_CASTE_SUCCESS: 'GET_ALL_CASTE_SUCCESS',
    // GET_ALL_CASTE_FAILURE: 'GET_ALL_CASTE_FAILURE',
    GET_CITY: 'GET_CITY',
    GET_STATE: 'GET_STATE',
    UPDATE_BASIC_START_TWO: 'UPDATE_BASIC_START_TWO',
    UPDATE_BASIC_SUCCESS_TWO: 'UPDATE_BASIC_SUCCESS_TWO',
    UPDATE_BASIC_FAILURE_TWO: 'UPDATE_BASIC_FAILURE_TWO',
  }
  
  export default RegisterActionTypes;