import NewlyJoinedActionTypes from "./newly-joined.types"

const INITIAL_STATE = {
    newly_joined_list: []
}

const newlyJoinedReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
  
      case NewlyJoinedActionTypes.GET_NEWLY_JOINED_SUCCESS:
        return {
          ...state,
          newly_joined_list: action.payload,
        }

        case NewlyJoinedActionTypes.REMOVE_AND_UPDATE_ITEM_FROM_NEWLY_JOINED_LIST:
          let itemIndex = state.newly_joined_list.findIndex(p => p.id === action.payload)
          let list = [...state.newly_joined_list]
          list.splice(itemIndex,1)

          return {
            ...state,
            newly_joined_list: list,
          }
  
      default:
        return {
          ...state
        }
    }
  }
  
  export default newlyJoinedReducer