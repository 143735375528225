import React,{useEffect,useState} from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import {  selectId } from '../../redux/auth/auth.selector'
import { selectProfile } from '../../redux/my-profile/my-profile.selector'
import { getPopularProfiles } from '../../redux/popular/popular.action'
import { selectPopularItemsList } from '../../redux/popular/popular.selector'
import { sectionTypes } from '../../utils/display.utils'
import ProfileOverview from '../profile-overview/profile-overview.component';

function Popular({getPopularProfiles,userId,userProfile,popularList}) {

    const [loadingPopular,setLoadingPopular] = useState(false)

  useEffect(() =>{
    console.log(userProfile +"popular comp call")
    if (!userProfile) {
      return;
    }
    let data = {
      gender: userProfile.gender,
      religion: userProfile.religion,
      userId : userId
    }
    getPopularProfiles(data)
  },[userProfile]);

  return (
    <div className='animate__animated animate__slideInRight'>
      <ProfileOverview 
      section={sectionTypes.POPULAR}
       items={popularList} />
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  userId: selectId,
  userProfile: selectProfile,
  popularList: selectPopularItemsList,

})

const mapDispatchToProps = dispatch => ({
  getPopularProfiles: (data) => dispatch(getPopularProfiles(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Popular)