
import { FETCH, API, FETCH_FROM_NODE } from '../../utils/api';

import { setError } from '../error/error.action';

import HistoryActionTypes from './history.types';

import { getCredits } from '../auth/auth.action';
import { getDiscoverProfile, getDiscoverPremiumProfile } from '../discover/discover.action';
import { json } from 'body-parser';
import { buttonActionTypes, sectionTypes } from '../../utils/display.utils';

export const getHistory = (data) => {
  return dispatch => {
    dispatch({ type: HistoryActionTypes.GET_HISTORY_START });
    FETCH_FROM_NODE({
      url: `${API.GET_HISTORY_URL}?userId=${data}`,
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {

        dispatch({
          type: HistoryActionTypes.GET_HISTORY_SUCCESS,
          payload: resData
        });

      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: HistoryActionTypes.GET_HISTORY_FAILURE });
      });
  };

}

export const postRepely = data => {

  return dispatch => {
    dispatch({ type: HistoryActionTypes.POST_REPELY_START })
    FETCH_FROM_NODE({
      method: 'POST',
      ContentType: 'application/json',
      url: API.SEND_REPLY_URL,
      body: JSON.stringify(data),
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {
        if (resData.status === 2) {
          throw new Error(resData);
        }
        dispatch(getCredits({ is_lead: data.is_lead, id: data.id }));
        // dispatch(getDiscoverProfile({is_lead: data.is_lead, id: data.id}));
        // dispatch(getDiscoverPremiumProfile({is_lead: data.is_lead, id: data.id}));
        dispatch({
          type: HistoryActionTypes.POST_REPLY_SUCCESS,
          payload: {
            id: data.action_id,
            removeFrom: data.action === 'NO' ? 'shortlist' : 'reject',
            removeTo: data.action !== 'NO' ? 'shortlist' : 'reject',
          }
        });

        // ############## utility dispatch function based on condition. ################

      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: HistoryActionTypes.POST_REPELY_FAILURE });
      });
  }
}

export const getProfile = (data) => {

  return dispatch => {
    dispatch({ type: HistoryActionTypes.GET_PROFILE_START })
    FETCH_FROM_NODE({
      method: 'GET',
      url: `${API.GET_USER_PROFILE_URL}?userId=${data.id}&viewing_mine=${data.view}`,
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {
        dispatch({
          type: HistoryActionTypes.GET_PROFILE_SUCCESS,
          payload: resData,
        });
      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: HistoryActionTypes.GET_PROFILE_FAILURE });
      });
  }
}

export const historyProfileAction = data => {

  return dispatch => {
    dispatch({ type: HistoryActionTypes.POST_PROFILE_ACTION_START })
    FETCH_FROM_NODE({
      method: 'POST',
      url: `${API.SAVE_TODAYS_ACTION_URL}?userIdFrom=${data.userIdFrom}&userIdTo=${data.userIdTo}&actionType=${data.actionType}&isTodaySpecial=${data.isTodaySpecial}`,
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {
        if (resData.status === 2) {
          throw new Error(resData);
        }

        dispatch({
          type: HistoryActionTypes.POST_REPLY_SUCCESS,
          payload: {
            id: data.userIdTo,
            removeFrom: data.actionType === buttonActionTypes.REJECTED ? 'shortlist' : 'reject',
            // removeTo: data.action !== buttonActionTypes.REJECTED ? 'shortlist' : 'reject',
          }
        });
        console.log("Data after this");
        console.log(data);
        dispatch(getHistory(data.userIdFrom))

        dispatch(getCredits({ is_lead: data.is_lead, id: data.userIdFrom }));

        dispatch({
          type: HistoryActionTypes.POST_PROFILE_ACTION_SUCCESS,
          payload: resData
        });

      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: HistoryActionTypes.POST_PROFILE_ACTION_FAILURE });
      });
  }
}




export const removeProfile = () => ({ type: HistoryActionTypes.REMOVE_PROFILE })