import React from 'react';
import './plan-item.styles.scss';

function PlanItem({idx,handleClick,activePlan,item}) {
  return (
    <div 
    onClick={() => handleClick(idx)}
            className={`item payment-card ${idx === activePlan ? 'active-plan' : ''}`}
            key={idx}>

               <span className='plan-validity'>
                   {
                       `${item.validity} months` 
                   }
               </span>

            <div className='body'>
          <p>{item.contacts}<span className='f12 m-l-5'>Contacts</span> </p>
          <p className='span-line-through f12'>₹{item.amount}</p>
        </div>
        <div className='footer'>
          <p>₹ {Math.ceil(item.amount - item.amount * item.discount / 100)}</p>
          <p >save {item.discount}% </p>
        </div>
    </div>
  )
}

export default PlanItem