import React from "react";
import Slider from "react-slick";
import Img1 from "../../../components/assets/Matchmakers/NeelamDewan.jpeg";
import Img2 from "../../../components/assets/Matchmakers/AnjuBalaSarangal.jpeg";
import Img3 from "../../../components/assets/Matchmakers/LoveleenKaur.jpeg";
import Img4 from "../../../components/assets/Matchmakers/RohanSharma.jpeg";
import Img5 from "../../../components/assets/Matchmakers/SimranKaur.jpeg";
import "./video-carousel.scss";

const VideoCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="hansvid">
      <div className="hansvid-header">
        <h1>"Experienced and Caring Matchmakers at your service"</h1>
      </div>
      <Slider {...settings} className="slider">
        <div className="hansvid-slider">
          <img
            src={Img1}
            alt="Image 1"
            style={{ width: "100%" }}
            className="image"
          />
          <div className="image-text">RM Chief</div>
          <div className="image-text">Neelam Dewan</div>
          <div className="image-text">21 Years of Experience</div>
        </div>
        <div className="hansvid-slider">
          <img
            src={Img2}
            alt="Image 2"
            style={{ width: "100%" }}
            className="image"
          />
          {/* <div className="image-text">
            Sr. Relationship Manager, Anju Bala Sarangal, 13 Years of Experience
          </div> */}
          <div className="image-text">Sr. Relationship Manager</div>
          <div className="image-text">Anju Bala Sarangal</div>
          <div className="image-text">13 Years of Experience</div>
        </div>
        <div className="hansvid-slider">
          <img
            src={Img3}
            alt="Image 3"
            style={{ width: "100%" }}
            className="image"
          />
          {/* <div className="image-text">
            Sr. Relationship Manager, Loveleen Kaur, 9 Years of Experience
          </div> */}
          <div className="image-text">Sr. Relationship Manager</div>
          <div className="image-text">Loveleen Kaur</div>
          <div className="image-text">9 Years of Experience</div>
        </div>
        <div className="hansvid-slider">
          <img
            src={Img4}
            alt="Image 4"
            style={{ width: "100%" }}
            className="image"
          />
          {/* <div className="image-text">
            Relationship Manager, Rohan Sharma, 6 Years of Experience
          </div> */}
          <div className="image-text">Relationship Manager</div>
          <div className="image-text">Rohan Saxena</div>
          <div className="image-text">6 Years of Experience</div>
        </div>
        <div className="hansvid-slider">
          <img
            src={Img5}
            alt="Image 5"
            style={{ width: "100%" }}
            className="image"
          />
          {/* <div className="image-text">
            Relationship Manager, Simran Kaur, 5 Years Experience
          </div> */}
          <div className="image-text">Relationship Manager</div>
          <div className="image-text">Simran Sharma</div>
          <div className="image-text">5 Years of Experience</div>
        </div>
      </Slider>
    </div>
  );
};

export default VideoCarousel;

// import React from 'react'
// import Vid1 from '../../../components/assets/video/vid1.webm'
// import Vid2 from '../../../components/assets/video/vid2.mp4'
// // import Vid3 from '../../../components/assets/video/vid3.mp4'
// import ReactPlayer from "react-player";
// import "bootstrap/dist/css/bootstrap.css";
// import { Carousel } from "react-bootstrap";
// import './video-carousel.scss'
// const VideoCarousel = () => {

//     const videoProperties = [
//         {
//           id: 1,
//           title: "Video 1",
//           src: Vid1,
//           credit: "Video by cottonbro from Pexels",
//         },
//         {
//           id: 2,
//           title: "Video 2",
//           src: Vid2,
//           credit: "Video by cottonbro from Pexels",
//         },
//         // {
//         //   id: 3,
//         //   title: "Video 3",
//         //   src: Vid3,
//         //   credit: "Video by cottonbro from Pexels",
//         // },
//       ];

//       return (
//         <div className="hansvid">
//           <Carousel>
//             {videoProperties.map((videoObj) => {
//               return (
//                 <Carousel.Item key={videoObj.id}>
//                   <ReactPlayer
//                     url={videoObj.src}
//                     pip={true}
//                     controls={true}
//                     playing={true}
//                   />
//                   {/* <Carousel.Caption>
//                     <h3>{videoObj.title}</h3>
//                     <p>{videoObj.credit}</p>
//                   </Carousel.Caption> */}
//                 </Carousel.Item>
//               );
//             })}
//           </Carousel>
//         </div>
//       );
// }

// export default VideoCarousel
