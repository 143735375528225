import NewlyJoinedActionTypes from "./newly-joined.types";
import {API, FETCH_FROM_NODE} from '../../utils/api';

import {setError} from '../error/error.action';

export const getNewlyJoinedProfiles = (data) => {

    return dispatch => {
      dispatch({ type: NewlyJoinedActionTypes.GET_NEWLY_JOINED_START})
      FETCH_FROM_NODE({
        url: `${API.GET_NEWLY_JOINED_PROFILES}?user_id=${data.userId}&religion=${data.religion}&gender=${data.gender}`,
        method: 'GET',
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error('Something went Worng!')
          }
          return res.json();
        })
        .then(resData => {
  
          dispatch({
            type: NewlyJoinedActionTypes.GET_NEWLY_JOINED_SUCCESS,
            payload: resData.data,
          })
  
        })
        .catch(err => {
          dispatch({ type: NewlyJoinedActionTypes.GET_NEWLY_JOINED_FAILURE })
          dispatch(setError(err))
        })
    }
  }

  export function removeAndUpdateNewlyJoinedList(id) {
    return dispatch => {
      dispatch({type: NewlyJoinedActionTypes.REMOVE_AND_UPDATE_ITEM_FROM_NEWLY_JOINED_LIST,
      payload: id})
    }
  }