import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectId } from '../../redux/auth/auth.selector'
import { selectProfile } from '../../redux/my-profile/my-profile.selector'
import { getNewlyJoinedProfiles } from '../../redux/newly-joined/newly-joined.action'
import { selectNewlyJoinedItemsList } from '../../redux/newly-joined/newly-joined.selector'
import { sectionTypes } from '../../utils/display.utils'
import ProfileOverview from '../profile-overview/profile-overview.component'

function NewlyJoined({getNewlyJoinedProfiles,userId,userProfile,newlyJoinedList}) {
  useEffect(() =>{
    if (!userProfile) {
      return;
    }
    let data = {
      gender: userProfile.gender,
      religion: userProfile.religion,
      userId
    }
    getNewlyJoinedProfiles(data)
  },[userProfile]);

  return (
    <div className='animate__animated animate__slideInRight'>
      <ProfileOverview
      section={sectionTypes.NEWLY_JOINED}
       items={newlyJoinedList} />
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  userId: selectId,
  userProfile: selectProfile,
  newlyJoinedList: selectNewlyJoinedItemsList

})

const mapDispatchToProps = dispatch => ({
  getNewlyJoinedProfiles: (data) => dispatch(getNewlyJoinedProfiles(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewlyJoined)