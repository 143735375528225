import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectCasteMapping } from '../../../redux/mappedData/mappedData.selector'

function CasteSelect({value,allCaste,handler,styles,multiple,variant}) {

  return (
    <>
       {
         allCaste ?  (
            <Autocomplete
                multiple={multiple}
              className={styles}
              id="caste"
              name="caste"
              options={allCaste.map(it => it.value)}
              defaultValue={value}
              onChange={handler}
              renderInput={(params) => <TextField {...params} 
              label="Caste" 
              variant={variant}
              placeholder="Add caste"/>
            }
            />
                ) : null
       }
    </>
  )
}


const mapStateToProps = createStructuredSelector({
    allCaste: selectCasteMapping
})

export default connect(mapStateToProps)(CasteSelect)