
const AuthActionTypes = {
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  SIGNUP_START: 'SIGNUP_START',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_FAILURE: 'SIGNUP_FAILURE',
  CHECK_LOGIN: 'CHECK_LOGIN',
  LOGOUT: 'LOGOUT',
  SEND_OTP_START: 'SEND_OTP_START',
  SEND_OTP_SUCCESS: 'SEND_OTP_SUCCESS',
  SEND_OTP_FAILURE: 'SEND_OTP_FAILURE',
  VERIFY_OTP_START: 'VERIFY_OTP_START',
  VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
  VERIFY_OTP_FAILURE: 'VERIFY_OTP_FAILURE',
  HIDE_OTP: 'HIDE_OTP',
  GET_PROFILE_COUNT_START: 'GET_PROFILE_COUNT_START',
  GET_PROFILE_COUNT_SUCCESS: 'GET_PROFILE_COUNT_SUCCESS',
  GET_PROFILE_COUNT_FAILURE: 'GET_PROFILE_COUNT_FAILURE',
  GET_CREDITS_COUNT_START: 'GET_CREDITS_COUNT_START',
  GET_CREDITS_COUNT_SUCCESS: 'GET_CREDITS_COUNT_SUCCESS',
  GET_CREDITS_COUNT_FAILURE: 'GET_CREDITS_COUNT_FAILURE',

  UPDATE_AUTH_PHOTO: 'UPDATE_AUTH_PHOTO',
}

export default AuthActionTypes;