import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import LocationSelect from '../../../components/forms-elements/location-select/location-select'
import ManglikSelect from '../../../components/forms-elements/manglik-select/manglik-select'
import { selectManglikMapping } from '../../../redux/mappedData/mappedData.selector'
import { updatePersonalDetails } from '../../../redux/my-profile/my-profile.action'
import { getManglikInt } from '../../../utils/display.utils'

function HoroscopeForm({close,personalData,updatePersonalDetails,submitForm,manglikList}) {

    useEffect(() => {
        if (submitForm) {
            updatePersonalDetails(formDetails)
            close()
        }
    },[submitForm])

    const [formDetails,setFormDetails] = useState({
        manglik: personalData.manglik,
        manglikCode: getManglikInt(personalData.manglik,manglikList),
        birth_place: personalData.birth_place,
        birth_time: personalData.birth_time,
        gotra: personalData.gotra,
    })


    const handleManglikChange = (e,value) => {
        if(value) {
            setFormDetails({
              ...formDetails,
              manglik: value,
              manglikCode: getManglikInt(value,manglikList)
            })
          }
    }
    
    const handleBirthPlaceChange = (e,value) => {
        if(value) {
            setFormDetails({
              ...formDetails,
              birth_place: value,
            })
          }
    }

    const handleChangeTextFields = (e) => {
        if (e.target.name) {
            setFormDetails({
              ...formDetails,
              [e.target.name] : e.target.value
            })
          } 
    }

  return (
    <form className='horoscope-form-container'>
            <ManglikSelect value={formDetails.manglik} handler={handleManglikChange} styles="input-field w-100 mt-3"/>
            
            <LocationSelect value={formDetails.birth_place} handler={handleBirthPlaceChange} styles="input-field w-100 mt-3" placeholder="Birth Place"/>


            <TextField  label="Birth Time" 
             className='input-field w-100 mt-3'
              variant="outlined"
               defaultValue={formDetails.birth_time}
               type="time"
               name='birth_time'
               onChange={handleChangeTextFields}
                />

             <TextField  label="Gotra" 
             className='input-field w-100 mt-3'
              variant="outlined"
               defaultValue={formDetails.gotra}
               type="text"
               name='gotra'
               onChange={handleChangeTextFields}
                />

    </form>
  )
}

const mapStateToProps = createStructuredSelector({
    manglikList: selectManglikMapping
})

const mapDispatchToProps = dispatch => ({
    updatePersonalDetails: (data) => dispatch(updatePersonalDetails(data),)
  })

export default connect(mapStateToProps, mapDispatchToProps)(HoroscopeForm)