import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postProfileAction } from "../../redux/today-special/today-special.action";
import {
  historyProfileAction,
  postRepely,
} from "../../redux/history/history.action";
import { makePayment } from "../../redux/payment/payment.action";
import { openChatSection } from "../../redux/user-chat/user-chat.action";
import { getProfileCount } from "../../redux/auth/auth.action";
import {
  selectIsLead,
  selectId,
  selectCredits,
} from "../../redux/auth/auth.selector";
import { useNavigate } from "react-router-dom";
import iconBg from "../assets/chat/phone-bg.svg";

import "./logo-button.styles.scss";
import { buttonActionTypes, sectionTypes } from "../../utils/display.utils";

import PersonalComponent from "../profile-list/profile-item/personal.component";
const LogoButton = ({
  getProfileCount,
  postProfileAction,
  historyAction,
  makePayment,
  postRepely,
  isLead,
  credits,
  id,
  action,
  actionId,
  actionOf,
  imageUrl,
  callFrom,
  glow,
  addCircularBg,
  openChat,
  section,
  showName,
  callBackAfterAction,
  mobile,
  onClick,
}) => {
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  console.log("mobile", mobile);
  useEffect(() => {
    if (count === 1) {
      switch (action) {
        case buttonActionTypes.WHATSAPP:
          window.open(`https://wa.me/+${mobile}`);
          break;

        case buttonActionTypes.CALL:
          window.open(`tel:+${mobile}`);
          break;

        case buttonActionTypes.CONTACTEDWHATSAPP:
          performButtonActions();
          window.open(`https://wa.me/+${mobile}`);
          break;
        case buttonActionTypes.LIKED:
          console.log("liked");
          toast.success("Profile Liked", {
            position: toast.POSITION.TOP_CENTER,
            toastId: 1,
          });
          performButtonActions();
          break;
        case buttonActionTypes.CONTACTED:
          toast.success("Profile Contacted", {
            position: toast.POSITION.TOP_CENTER,
            toastId: 2,
          });
          performButtonActions();
          break;
        case buttonActionTypes.REJECTED:
          toast.success("Profile Rejected", {
            position: toast.POSITION.TOP_CENTER,
            toastId: 3,
          });
          performButtonActions();
          break;
        default:
          performButtonActions();
          break;
      }
    }
  }, [count]);

  /**
   * perform button action
   */
  const performButtonActions = () => {
    if (id && action && actionId && actionOf) {
      let data = {
        userIdFrom: id,
        userIdTo: actionId,
        actionType: action, // 2-> popular 3 -> newly joined
      };

      switch (section) {
        case sectionTypes.PROFILES:
          data.isTodaySpecial = "1";
          break;

        case sectionTypes.TODAY_SPECIAL:
          data.isTodaySpecial = "1";
          break;

        case sectionTypes.POPULAR:
          data.isTodaySpecial = "2";
          break;

        case sectionTypes.NEWLY_JOINED:
          data.isTodaySpecial = "3";
          break;

        default:
          data.isTodaySpecial = "0";
          break;
      }

      console.assert(
        credits !== undefined || credits != null,
        "Credits are not available"
      );
      console.log({ credits });
      if ((action === "YES" || action === "CONTACTED") && credits === "0") {
        makePayment();
      } else {
        /**
         * action will be performed on the basis of call from
         */

        switch (callFrom) {
          case sectionTypes.LIKED_ME: // start chat and mark contacted
            if (
              action === buttonActionTypes.CONTACTED ||
              action === buttonActionTypes.CONTACTEDCHAT
            ) {
              // create new chat room with user
              openChat(data.userIdFrom.toString(), data.userIdTo.toString());
            }
            // post action to backend server
            postProfileAction(data);
            break;

          case sectionTypes.HISTORY_LIKE:
            data.callFrom = callFrom;
            historyAction(data);
            break;

          case sectionTypes.HISTORY_REJECT:
            data.callFrom = callFrom;
            historyAction(data);
            break;

          case sectionTypes.HISTORY_CONTACT:
            data.callFrom = callFrom;
            historyAction(data);
            break;
          case sectionTypes.POPULAR:
            data.callFrom = callFrom;
            postProfileAction(data);
          default:
            postProfileAction(data);
            break;
        }

        if (typeof callBackAfterAction == "function") {
          callBackAfterAction();
        }
      }

      setTimeout(() => setCount(0), 500);
      setTimeout(() => getProfileCount(`?userId=${id}`), 1000);
    } else {
      setCount(0);
    }
  };

  const getButtonNameAccordingToAction = () => {
    switch (action) {
      case "CONTACTED":
        return "Call";

      case "LIKED":
        return "Like";

      case "REJECTED":
        return "Reject";

      case "WHATSAPP":
        return "Whatsapp";

      case "CONTACTEDWHATSAPP":
        return "Whatsapp";

      case "CONTACTEDCHAT":
        return "Chat";
      default:
        break;
    }
  };

  if (glow) {
    return (
      <button onClick={() => setCount(count + 1)} className="btn logo-btn">
        <img className="icon-animate" src={imageUrl} alt="" />
        <img className="icon-bg" src={iconBg} alt="" />
      </button>
    );
  }
  if (addCircularBg) {
    return (
      <button onClick={() => setCount(count + 1)} className="btn logo-btn">
        <img className="icon" src={imageUrl} alt="" />
        {showName ? getButtonNameAccordingToAction() : null}
      </button>
    );
  }
  return (
    <>
      {/* <ToastContainer limit={1} /> */}
      <button
        onClick={() => {
          setCount(count + 1);
          if (typeof onClick == "function") {
            onClick();
          }
        }}
        className={`btn logo-btn ${action}`}
      >
        <img src={imageUrl} alt="" />
        {showName ? getButtonNameAccordingToAction() : null}
      </button>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  isLead: selectIsLead,
  id: selectId,
  credits: selectCredits,
});

const mapDispatchToProps = (dispatch) => ({
  postProfileAction: (data) => dispatch(postProfileAction(data)),
  historyAction: (data) => dispatch(historyProfileAction(data)),
  postRepely: (data) => dispatch(postRepely(data)),
  makePayment: () => dispatch(makePayment()),
  openChat: (from, to) => dispatch(openChatSection(from, to)),
  getProfileCount: (query) => dispatch(getProfileCount(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogoButton);
