import MyProfileActionTypes from "./my-profile.types";

const INITIAL_STATE = {
  country: null,
  family: null,
  preferences: null,
  profile: null,
  contact: null,
  rishteyCount: 0,
  recommendedPreference: null,
  imagUrl:'',

  whatsapp:null,
  
}

const myProfileReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

    case MyProfileActionTypes.GET_MY_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload.userProfileDetail,
        whatsapp:action.payload.userProfileDetail.whatsapp,
        preferences: action.payload.userPreferencesData,
      
        ...action.payload
      }
    case MyProfileActionTypes.GET_CONTACTED_PROFILES_SUCCESS:
      return {
        ...state,
        contact: action.payload
      }
    case MyProfileActionTypes.GET_RISHTEY_COUNT_SUCCESS:
      return {
        ...state,
        rishteyCount: action.payload
      }
      case MyProfileActionTypes.GET_RECOMMENDED_PREFERENCE_SUCCESS:
        return {
          ...state,
          recommendedPreference: action.payload
        }
        case MyProfileActionTypes.UPLOAD_PROFILE_PICTURE_SUCCESS:
          return {
            ...state,
            imageUrl: action.payload.imageUrl
          }
    default:
      return {
        ...state
      }
  }
}

export default myProfileReducer;