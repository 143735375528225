
import PaymentActionTypes from './payment.types';

const INITIAL_STATE = {
  showPayment: false,
  paytmOrder: null,
  paymentPlans: []
}

const paymentReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

    case PaymentActionTypes.MAKE_PAYMENT:
      return {
        ...state,
        showPayment: !state.showPayment,
      }
    case PaymentActionTypes.GET_PAYTM_ORDERID_SUCCESS:
      return {
        ...state,
        paytmOrder: action.payload
      }

      case PaymentActionTypes.GET_PAYMENT_PLANS_SUCCESS:
        return {
          ...state,
          paymentPlans: action.payload
        }

    default:
      return {
        ...state
      }
  }
}

export default paymentReducer