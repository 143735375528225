import {  API, FETCH_FROM_NODE } from '../../utils/api';

import {setError} from '../error/error.action';

import PopularActionTypes from './popular.types';

export const getPopularProfiles = (data) => {

    return dispatch => {
      dispatch({ type: PopularActionTypes.GET_POPULAR_PROFILES_START})
      FETCH_FROM_NODE({
        url: `${API.GET_POPULAR_PROFILES}?user_id=${data.userId}&religion=${data.religion}&gender=${data.gender}`,
        method: 'GET',
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error('Something went Worng!')
          }
          return res.json();
        })
        .then(resData => {
  
          dispatch({
            type: PopularActionTypes.GET_POPULAR_PROFILES_SUCCESS,
            payload: resData.data,
          })
  
        })
        .catch(err => {
          dispatch({ type: PopularActionTypes.GET_POPULAR_PROFILES_FAILURE })
          dispatch(setError(err))
        })
    }
  }

  export function removeAndUpdatePopularList(id) {
    return dispatch => {
      dispatch({type: PopularActionTypes.REMOVE_AND_UPDATE_ITEM_FROM_POPULAR_LIST,
      payload: id})
    }
  }