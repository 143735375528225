import AuthActionTypes from './auth.types';


const INTIAL_STATE = {
  authData: null,
  isAuth: false,
  authLoading: false,
  checkLogin: true,
  auth: {},
  name: null,
  photoUrl: null,
  gender: null,
  mobile: null,
  header: null,
  registered: null,
  showOtp: false,
  credits: null,
  newUser: null,
  count: {
    TPL: '',
    S: '',
    SL: '',
    VPL: ''
  },
};

const authReducer = (state = INTIAL_STATE, action) => {

  switch (action.type) {
    case AuthActionTypes.LOGIN_START:
      return {
        ...state,
        isAuth: false, // Set isAuth to true when login starts
        authLoading: true
      }
    case AuthActionTypes.SIGNUP_START:
    case AuthActionTypes.CHECK_LOGIN:
      return {
        ...state,
        // newUser: state.auth.new_user,
        authLoading: true
      }
    // case AuthActionTypes.LOGIN_SUCCESS:
    //   return {
    //     ...state,
    //     isAuth: true,
    //     checkLogin: false,
    //     authLoading: false,
    //     auth: state.authData.authUserDetails,
    //     registered: state.authData.authUserDetails.registered,
    //     name: state.authData.authUserDetails.name,
    //     photoUrl: state.authData.authUserDetails.photo_url,
    //     mobile: state.authData.authUserDetails.user_mobile,
    //     gender: state.authData.authUserDetails.gender,
    //     id: state.authData.authUserDetails.id,
    //     is_lead: state.authData.authUserDetails.is_lead,
    //     userId: state.authData.id,

    //     header: 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJoYW5zIiwiaWF0IjoyMDIxMDkwODIwMDQ0MzUsInVzZXIiOnsibW9iaWxlIjoiOTkxMDM5NTgyMCJ9LCJzdXBlciI6ImhhbnNub3cifQ.3g6Vh3s42ypdKgwxEfde3mQuhKRW50t5VVrFmIROX8Hcr2Q0GOv9mwjyV4mb8bsTDxBTiOCmGawjD3Bd99hLuw',
    //   }
    case AuthActionTypes.LOGIN_FAILURE:
    case AuthActionTypes.SIGNUP_FAILURE:
      return {
        ...state,
        isAuth: false,
        checkLogin: false,
        authLoading: false,
        auth: {},
        mobile: null,
        profileCompPercent: 0,
        registered: null

      }
    case AuthActionTypes.LOGOUT:
      return {
        // ...state,
        isAuth: false,
        authData: null,
        auth: {},
        authLoading: false,
        gender: null,
        useId: null,
        showOtp: false,
        userId: null,
        name: null,
        photoUrl: null,
        id: null,
        userMobile: null,
        header: ''
      }
    case AuthActionTypes.SEND_OTP_SUCCESS:
      return {
        ...state,
        showOtp: true,
        authData: action.payload,
        isAuth: false,
        checkLogin: false,
        authLoading: false,
        auth: action.payload.authUserDetails,
        registered: action.payload.authUserDetails.registered,
        name: action.payload.authUserDetails.name,
        photoUrl: action.payload.authUserDetails.photo_url,
        mobile: action.payload.authUserDetails.user_mobile,
        gender: action.payload.authUserDetails.gender,
        is_lead: action.payload.authUserDetails.is_lead,
        // id: state.authData.authUserDetails.id,
        userId: action.payload.authUserDetails.id,
        newUser: action.payload.new_user,
        userMobile: action.mob.query,
        header: 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJoYW5zIiwiaWF0IjoyMDIxMDkwODIwMDQ0MzUsInVzZXIiOnsibW9iaWxlIjoiOTkxMDM5NTgyMCJ9LCJzdXBlciI6ImhhbnNub3cifQ.3g6Vh3s42ypdKgwxEfde3mQuhKRW50t5VVrFmIROX8Hcr2Q0GOv9mwjyV4mb8bsTDxBTiOCmGawjD3Bd99hLuw'
      }
    //     case AuthActionTypes.LOGIN_SUCCESS:
    // return {
    //   ...state,
    //   isAuth: true,
    //   checkLogin: false,
    //   authLoading: false,
    //   auth: action.payload.authUserDetails,
    //   // registered: action.payload.authUserDetails.registered,
    //   name: action.payload.authUserDetails.name,
    //   photoUrl: action.payload.authUserDetails.photo_url,
    //   // mobile: action.payload.authUserDetails.user_mobile,
    //   // gender: action.payload.authUserDetails.gender,
    //   // id: action.payload.authUserDetails.id,
    //   // is_lead: action.payload.authUserDetails.is_lead,
    //   // userId: action.payload.authUserDetails.id,
    //   // newUser :action.payload.new_user,

    // }

    case AuthActionTypes.SEND_OTP_FAILURE:
    case AuthActionTypes.HIDE_OTP:
    case AuthActionTypes.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        isAuth: true,
        // header: 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJoYW5zIiwiaWF0IjoyMDIxMDkwODIwMDQ0MzUsInVzZXIiOnsibW9iaWxlIjoiOTkxMDM5NTgyMCJ9LCJzdXBlciI6ImhhbnNub3cifQ.3g6Vh3s42ypdKgwxEfde3mQuhKRW50t5VVrFmIROX8Hcr2Q0GOv9mwjyV4mb8bsTDxBTiOCmGawjD3Bd99hLuw'
      }
    case AuthActionTypes.GET_PROFILE_COUNT_SUCCESS:
      return {
        ...state,
        count: action.payload
      }
    case AuthActionTypes.GET_CREDITS_COUNT_SUCCESS:
      return {
        ...state,
        credits: action.payload
      }

    case AuthActionTypes.UPDATE_AUTH_PHOTO:
      return {
        ...state,
        photoUrl: action.payload
      }

    default:
      return state;
  }
}

export default authReducer;