import React from 'react';

const Button = ({ title, type, handleSubmit, className }) => {

  return (
    <button
      className={className}
      type={type}
      onSubmit={handleSubmit}
    >
      {title}
    </button>
  )
}

export default Button;