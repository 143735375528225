import { createStore, applyMiddleware,compose} from 'redux';
import logger from 'redux-logger';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import rootReducer from './root-reducer';

const middleware = [thunk];
if (process.env.NODE_ENV === 'development') {
  middleware.push(logger)
}
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(rootReducer, composeEnhancer(applyMiddleware(...middleware)))

export const persistor = persistStore(store);