import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './auth/auth.reducer';
import errorReducer from './error/error.reducer';
import historyReducer from './history/history.reducer';
import todaySpecialReducer from './today-special/today-special.reducer';
import interestedReducer from './interested/interested.reducer';
import discoverReducer from './discover/discover.reducer';
import myProfileReducer from './my-profile/my-profile.reducer';
import registerReducer from './register/register.reducer';
import paymentReducer from './payment/payment.reducer';
import languageReducer from './language/language.reducer';
import mappedDataReducer from './mappedData/mappedData.reducer';
import userChatReducer from './user-chat/user-chat.reducer';
import popularReducer from './popular/popular.reducer';
import newlyJoinedReducer from './newly-joined/newly-joined.reducer';
import registerReducerTwo from './register-form-2/register.form.two.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'myProfile', 'language'],
  // whitelist: []
}

const rootReducer = combineReducers({
  auth: authReducer,
  error: errorReducer,
  today: todaySpecialReducer,
  history: historyReducer,
  interested: interestedReducer,
  discover: discoverReducer,
  myProfile: myProfileReducer,
  register: registerReducer,
  registerTwo: registerReducerTwo,
  payment: paymentReducer,
  language: languageReducer,
  mappedData: mappedDataReducer,
  userchat: userChatReducer,
  popular: popularReducer,
  newlyJoined: newlyJoinedReducer
})

export default persistReducer(persistConfig, rootReducer);