import React from "react";
import Slider from "react-slick";

import { Component } from "react";
import "./free-kundali-matching.styles.scss";
import KundaliForm from "./kundali-form/kundali-from.component";
import Gifimage from "../../../components/assets/hero/Gifimage.gif";
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";

class FreeKundaliMatching extends Component {
  state = {
    appear: false,
    mobileBreakpoint: 600,
    mobileScreen: false,
  };

  scroll = (event) => {
    this.scrollAppear();
  };
  scrollAppear() {
    let ele = document.querySelector(".kundali-form");
    let pos;
    if (ele) {
      pos = ele.getBoundingClientRect().top;
    }
    let screenPosition = window.innerHeight;
    let appear = false;
    if (pos <= screenPosition) {
      appear = true;
    } else if (pos > screenPosition) {
      appear = false;
    }
    // console.log(pos, screenPosition, appear)
    this.setState({ appear });
  }
  resize() {
    this.innerWidth = window.innerWidth;
    return this.innerWidth;
  }
  detectMobileScreen() {
    window.onload = this.resize;
    window.onresize = this.resize;
    let mobileScreen = false;
    if (this.resize() < this.state.mobileBreakpoint) {
      mobileScreen = true;
    } else {
      mobileScreen = false;
    }
    this.setState({ mobileScreen });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scroll, true);
    this.detectMobileScreen();
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scroll, true);
  }

  render() {
    const settings = {
      dots: true,
      className: "center",
      centerMode: true,
      fade: true,
      infinite: true,
      centerPadding: "6px",
      speed: 2000,
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 2000,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="desktop">
        <div id="kundali" className="kundali">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div
                  className={`kundali-text ${
                    this.state.appear ? "kundali-appear" : ""
                  }`}
                >
                  <h2 className="kundali-h2">
                    Free <span style={{ color: "#24ADD8" }}>Kundali</span>{" "}
                    Matching
                  </h2>
                  <div className="kundali-p-holder">
                    <p className="kundali-p">
                      Kundali match making is a comparison between two natal
                      charts. Matching Kundalis of a bride and groom to-be,
                      helps them understand their compatibility and their
                      prospective marriage. Kundali matching is used in vedic
                      astrology to check the compatibility of two individuals in
                      order to have an enchanted and successful marriage. In the
                      chance of trouble, the matchmaking ensure to provide
                      solutions for a blissful marriage.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
              <div
                className={`col-md-5 kundali-form1 ${
                  this.state.appear ? "kundali-appear" : ""
                }`}
              >
                <div
                  className="kundali-mainBack"
                  style={{ zIndex: "-1!important" }}
                >
                  <div className="d-flex justify-content-center">
                    <KundaliForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='slider-container-1'>
          <h3 style={{ textAlign : 'center' , fontSize : '23px' , paddingTop : '30px' , paddingBottom : '30px'}}> “Experienced and Caring Matchmakers at your service" </h3>
          <Slider {...settings}>
            <div>
              <img className='Gifimage' src={Gifimage} alt="Gifimage2"/>
            </div>
            
            

          </Slider>
        </div> */}
      </div>
    );
  }
}

export default FreeKundaliMatching;
