export const hindi = {
  // buttons
  contact: 'कांटेक्ट करें',
  reject: 'नापसंद है',
  shortlist: 'पसंद है',

  // top details
  verifiedAt: 'वेरिफ़िएड एट',
  managedBy: 'मैनेज्ड बय',
  hello: 'हेलो',
  about: 'अबाउट',
  personalizedDetails: 'पर्सनल डिटेल्स',
  careerDetails: 'शिक्षा और करियर डिटेल्स',
  horoscopeDetails: 'होरोस्कोप',
  familyDetails: 'फॅमिली डिटेल्स',
  myself: 'माइसेल्फ ',

  // personal details
  weight: 'वेट',
  martialStatus: 'मैरिटल स्टेटस',
  foodChoice: 'फ़ूड चॉइस',
  religion: 'रिलिजन',

  // career details
  occupation: 'ऑक्यूपेशन',
  designation: 'देसिग्नेशन',
  annualIncome: 'आय',
  education: 'क्वालिफिकेशन',
  college: 'कॉलेज',
  workingCity: 'वर्किंग सिटी',

  // horoscope details
  dob: 'D.O.B',
  birthTime: 'बर्थ टाइम',
  birthPlace: 'बर्थ प्लेस',
  manglik: 'मांगलिक',

  // family details
  sisters: 'बहनें',
  brothers: 'भाई',
  fatherStatus: 'पिता',
  motherStatus: 'माता',
  gotra: 'गोत्र',
  familyType: 'फॅमिली टाइप',
  houseType: 'हाउस टाइप',
  familyIncome: 'फॅमिली इनकम',


  // my-profile-nav
  personal: 'पर्सनल',
  horoscope: 'होरोस्कोप',
  family: 'फॅमिली',
  preference: 'प्रैफरेंसेज',

  // my-profile-menu
  logout: 'लॉगआउट ',
  subscription: 'सब्सक्रिप्शन',

  // my-profile
  myprofile: 'मेरी प्रोफाइल',
  aboutMe: 'अबाउट मी',
  name: 'नाम',
  age: 'उम्र',
  height: 'उचाई',
  locality: 'लोकैलिटी',
  educational: 'शिक्षा',
  additional: 'एडिशनल',
  company: 'कंपनी',
  birthDate: 'जनम तिथि',
  email: 'ईमेल',
  mobile: 'मोबाइल',
  whatsapp: 'व्हाट्सप्प',
  familyLivingIn: 'फॅमिली लिविंग इन',
  aboutFamily: 'अबाउट फैमिली',
  ageMin: 'न्यूनतम उम्र',
  ageMax: 'अधिकतम उम्र',
  heightMin: 'न्यूनतम उचाई',
  heightMax: 'अधिकतम उचाई',
  incomeMin: 'न्यूनतम आय',
  incomeMax: 'अधिकतम आय',
  castePref: 'जाती प्रेफरेंस',
  manglikPref: 'मांगलिक प्रेफरेंस',
  foodChoicePref: 'फ़ूड चॉइस प्रेफरेंस',
  motherTonguePref: 'मात्र भाषा प्रेफरेंस',
  occupationPref: 'ऑक्यूपेशन प्रेफरेंस',
  workingPref: 'वर्किंग प्रेफरेंस',
  desc: 'डिस्क्रिप्शन',
  todaysSpecial: 'आज के रिश्ते',
  discover: 'और देखें',
  likesYou: 'मुझे पसंद किय़ा',
  yourLikes: 'आपकी पसंद',
  history: 'पुराने रिश्ते',
  // credits
  credits: 'बचे हुए कॉन्टेक्ट्स:',

  //Liked Me
  likedMe: 'मुझे पसंद किय़ा',
  viewedMe: 'मुझे देखा',

  //bottom navigation
  profiles: 'प्रोफाइल' 
}

export const english = {
  contact: 'Contacted',
  reject: 'Rejected',
  shortlist: 'You Liked',

  // top details
  verifiedAt: 'Verified At',
  managedBy: 'Managed By',
  hello: 'Hello',
  about: 'About',
  personalizedDetails: 'Personal Details',
  careerDetails: 'Education and Career Details',
  horoscopeDetails: 'Horoscope',
  familyDetails: 'Family Details',
  myself: 'Myself ',

  // personal details
  weight: 'Weight',
  martialStatus: 'Marital Status',
  foodChoice: 'Food Choice',
  religion: 'Religion',


  // career details
  occupation: 'Occupation',
  designation: 'Designation',
  annualIncome: 'Annual Income',
  education: 'Qualification',
  college: 'College',
  workingCity: 'Working City',

  // horoscope details
  dob: 'D.O.B',
  birthTime: 'Birth Time',
  birthPlace: 'Birth Place',
  manglik: 'Manglik',

  // family details
  sisters: 'Sisters',
  brothers: 'Brothers',
  fatherStatus: 'Father',
  motherStatus: 'Mother',
  gotra: 'Gotra',
  familyType: 'Family Type',
  houseType: 'House Type',
  familyIncome: 'Family Income',

  // my-profile-nav
  personal: 'Personal',
  horoscope: 'Horoscope',
  family: 'Family',
  preference: 'Preference',

  // my-profile-menu
  logout: 'Logout ',
  subscription: 'Subscription',

  // my-profile
  myprofile: 'My Profile',
  aboutMe: 'About Me',
  name: 'Name',
  age: 'Age',
  height: 'Height',
  locality: 'Locality',
  educational: 'Educational Details',
  additional: 'Additional',
  company: 'Company',
  birthDate: 'Birth Date',
  email: 'Email',
  mobile: 'Mobile',
  whatsapp: 'Whatsapp',
  familyLivingIn: 'Family Living In',
  aboutFamily: 'About Family',
  ageMin: 'Age Min',
  ageMax: 'Age Max',
  heightMin: 'Height Min',
  heightMax: 'Height Max',
  incomeMin: 'Income Min',
  incomeMax: 'Income Max',
  castePref: 'Caste Preference',
  manglikPref: 'Manglik Preference',
  foodChoicePref: 'Food Choice Preference',
  motherTonguePref: 'Mother Tongue Preference',
  occupationPref: 'Occupation Preference',
  workingPref: 'Working preference',
  desc: 'Description',
  todaysSpecial: 'Today\'s Special',
  discover: 'See More',
  likesYou: 'Interested',
  yourLikes: 'My Likes',
  history: 'History',

  //liked Me
  likedMe: 'Liked Me',
  viewedMe: 'Profile viewers',

  // credits
  credits: 'Credits Left: ',


  //bottom navigation
  profiles: 'Profiles' 
}