import React, { useState } from "react";
// import { ToastContainer } from "react-toastify";
import ProfilePreview from "./profile-preview/profile-preview.component";

import "./profile-overview.styles.scss";
import { connect } from "react-redux";
import { getProfile } from "../../redux/history/history.action";

const ProfileOverview = ({ items, getProfile, section }) => {
  const openProfile = (id) => {
    getProfile({ id, view: 1 });
  };
  console.log("Profile");
  console.log(openProfile);
  return (
    <>
      {/* <ToastContainer limit={1} /> */}
      <div className="profile-overview">
        {items?.map((item, idx) => (
          <ProfilePreview
            key={`${item.id}-${idx}`}
            section={section}
            index={idx}
            item={item}
            openProfile={openProfile}
          />
        ))}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getProfile: (data) => dispatch(getProfile(data)),
});

export default connect(null, mapDispatchToProps)(ProfileOverview);
