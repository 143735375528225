import  MappedActionTypes from './mappedData.types';

const INITIAL_STATE = {
    "genderMapping": null,
    "religionMapping": null,
    "casteMapping": null,
    "educationMapping": null,
    "userOccupationMapping": null,
    "parentOccupationMapping": null,
    "relationMapping": null,
    "manglikMapping": null,
    "maritalStatusMapping": null,
    "foodChoiceMapping": null,
    "familyTypeMapping": null,
    "houseTypeMapping": null,
    "countryList": null
  }

  const mappedDataReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case MappedActionTypes.GET_MAPPED_DATA_SUCCESS:
        return {
        ...state,
        ...action.payload
        }

        case MappedActionTypes.GET_COUNTRIES_SUCCESS:
        return {
        ...state,
        countryList: action.payload.countryData
        }


        default:
      return {
        ...state
      }

    }
}

export default mappedDataReducer;