

const PaymentActionTypes = {
  MAKE_PAYMENT: 'MAKE_PAYMENT',
  GET_PAYMENT_PLANS_START: 'GET_PAYMENT_PLANS_START',
  GET_PAYMENT_PLANS_SUCCESS: 'GET_PAYMENT_PLANS_SUCCESS',
  GET_PAYMENT_PLANS_FAILED: 'GET_PAYMENT_PLANS_FAILED',
  GET_PAYTM_ORDERID_START: 'GET_PAYTM_ORDERID_START',
  GET_PAYTM_ORDERID_SUCCESS: 'GET_PAYTM_ORDERID_SUCCESS',
  GET_PAYTM_ORDERID_FAILURE: 'GET_PAYTM_ORDERID_FAILURE',
  CAPTURE_PAYMENT_START: 'CAPTURE_PAYMENT_START',
  CAPTURE_PAYMENT_SUCCESS: 'CAPTURE_PAYMENT_SUCCESS',
  CAPTURE_PAYMENT_FAILURE: 'CAPTURE_PAYMENT_FAILURE',
  TRANSACTION_STATUS_START: 'TRANSACTION_STATUS_START',
  TRANSACTION_STATUS_SUCCESS: 'TRANSACTION_STATUS_SUCCESS',
  TRANSACTION_STATUS_FAILURE: 'TRANSACTION_STATUS_FAILURE',
}

export default PaymentActionTypes;