import React from 'react';
const Select2 = ({ className, name, handleChange, defaultval, items, ...otherProps }) => {
  return (
    <select
      name={name}
      onChange={handleChange}
      className={className}
      {...otherProps}
    >
      {/* <optgroup label="Select One"></optgroup> */}
      <option selected hidden>Select One</option>
      {
        items.map((item, key) =>{
          if(defaultval==item)
          return <option value={item} selected>{item}</option>
          else
          return <option value={item}>{item}</option>
        }
        // <option value={item}>{item}</option>
          // {defaultval==item?<option value={item} selected>{item}</option>:<option value={item}>{item}</option>}
          
        )
      }
    </select>
  )
}

export default Select2;