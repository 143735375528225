import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useState } from "react";
import { createStructuredSelector } from "reselect";
import { Modal } from "react-bootstrap";
import "../my-profile-form.styles.scss";

import useFromInput from "../../../hooks/useFormInput";
import Button from "../../../components/button/button.component";
import {
  Autocomplete,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import CasteSelect from "../../../components/forms-elements/caste-select/caste-select";

import { getAllCaste } from "../../../redux/register/register.action";
import {
  selectCity,
  selectHeights1,
  selectHeights,
  selectMaritalStatus,
  selectReligion,
  selectAllCaste,
} from "../../../redux/register/register.selector";
import { updatePreferencesDetails } from "../../../redux/my-profile/my-profile.action";
import { selectId, selectIsLead } from "../../../redux/auth/auth.selector";
import {
  selectTempleId,
  selectIdentityNumber,
} from "../../../redux/my-profile/my-profile.selector";
import { CommitSharp } from "@mui/icons-material";

const PreferencesForm = ({
  id,
  isLead,
  templeId,
  identityNumber,
  show,
  onHide,
  getCity,
  heights,
  height1,
  maritalStatus,
  religion,
  allCaste,
  getAllCaste,
  updatePreferencesDetails,
  preferenceData,
}) => {
  const [state, changeState, , reset, updateState] =
    useFromInput(preferenceData);

  //mapped data religon :
  const [mappedDataReligion, setMappedDataReligion] = useState([]);
  //mapped data relation :
  const [mappedDataRelation, setMappedDataRelation] = useState([]);
  //education_qualification
  const [educationQualification, setEducationQualification] = useState([]);
  //occupation
  const [mappedOccupation, setMappedOccupation] = useState([]);
  //matiral status
  const [mappedMaritalStaus, setMappedMaritalStatus] = useState([]);
  //cast Pref
  const [cast, setCast] = useState([]);

  useEffect(() => {
    console.log("preference", state);
    getAllCaste();
  }, []);

  useEffect(() => {
    async function fetchMappedData() {
      const response = await fetch(
        // "https://hansmatrimony.com/api/v1/mappedData"
        "https://api.hansmatrimony.com/api/v1/mappedData"
      );
      const data = await response.json();
      setMappedDataReligion(data.religionMapping);
      setMappedDataRelation(data.relationMapping);
      setEducationQualification(data.educationMapping);
      setMappedOccupation(data.userOccupationMapping);
      setMappedMaritalStatus(data.maritalStatusMapping);
      setCast(data.casteMapping);
    }
    fetchMappedData();
  }, []);
  const foodChoiceList = [
    "Doesn't Matter",
    "Vegetarian",
    "Non-Vegetarian",
    "Eggetarian",
  ];
  // function getFoodChoiceString (value){
  //   console.log(value);
  //   return
  // }
  const shouldUpdateData = (state) => {
    // religion
    const selectedReligion = state.religionPreference;
    const religionData = mappedDataReligion.find(
      (item) => item.religion === selectedReligion
    );
    const religioncode = religionData ? religionData.mapping_id : "";
    //cast code
    const casteData = cast.find((item) => item.value === state.caste);
    const casteCode = casteData ? casteData.id : "";
    let food_choiceCode;
    let maritalcode;
    //maritalStaus
    if (state.maritalStatus === "Never Married") {
      maritalcode = 1;
    } else if (state.maritalStatus === "Awaiting Divorce") {
      maritalcode = 3;
    } else if (state.maritalStatus === "Divorcee") {
      manglik_code = 4;
    } else if (state.maritalStatus === "Widowed") {
      maritalcode = 5;
    } else if (state.maritalStatus === "Anulled") {
      maritalcode = 6;
    } else if (state.maritalStatus === "Doesn't Matter") {
      maritalcode = 7;
    } else {
      maritalcode = 7;
    }

    console.log("preference", state);

    console.log("maritalPreferences", maritalcode);

    console.log("cast", casteCode);
    let manglik_code;
    if (state.manglikPreference === "Manglik") {
      manglik_code = 1;
    } else if (state.manglikPreference === "Non-Manglik") {
      manglik_code = 2;
    } else if (state.manglikPreference === "Anshik Manglik") {
      manglik_code = 3;
    } else if (state.manglikPreference === "Don't Know") {
      manglik_code = 4;
    } else if (state.manglikPreference === "Doesn't Matter") {
      manglik_code = 5;
    }
    if (state.foodChoicePref === "Doesn't Matter") {
      food_choiceCode = [0];
    } else if (state.foodChoicePref === "Vegetarian") {
      food_choiceCode = [1];
    } else if (state.foodChoicePref === "Non-vegetarian") {
      food_choiceCode = [2];
    }

    let religionCode;
    if (state.religionPreference === "Hindu") {
      religionCode = 1;
    } else if (state.religionPreference === "Muslim") {
      religionCode = 2;
    } else if (state.religionPreference === "Sikh") {
      religionCode = 8;
    } else if (state.religionPreference === "Christian") {
      religionCode = 3;
    } else if (state.religionPreference === "Buddhist") {
      religionCode = 4;
    }
    if (state.religionPreference === "Jain") {
      religionCode = 9;
    } else if (state.religionPreference === "Parsi") {
      religionCode = 7;
    } else if (state.religionPreference === "Jewish") {
      religionCode = 6;
    } else if (state.religionPreference === "Bahai") {
      religionCode = 5;
    }

    let castePref;
    if (state.isCaste) {
      castePref = [481];
    } else if (state.caste) {
      const casteData = cast.find((item) => item.value === state.caste);
      castePref = casteData ? [parseInt(casteData.id)] : [];
    } else {
      castePref = state.castePref || preferenceData.castePref;
    }

    return {
      userId: id,
      castePref1: state.isCaste ? "All" : state.caste, /// look at here leter
      // castePref:([parseInt(casteCode)]) ,
      castePref: castePref,
      manglikPref: manglik_code || 5,
      marital_statusPref: [parseInt(maritalcode)],
      working: state.workingStatus,
      occupation: state.occupation,
      religionPref: religionCode,
      religion: state.religionPreference,
      food_choicePref: food_choiceCode,
      description: state.description,
      income_min: state.minIncome,
      income_max: state.maxIncome,
      height_min: height1[heights.indexOf(state.minHeight)],
      height_max: height1[heights.indexOf(state.maxHeight)],
      age_min: state.minAge,
      age_max: state.maxAge,
      // mother_tongue: state.motherTongue,

      //     temple_id:"online",

      //   manglikPref:1,
      //   castePref:[5],
      //   food_choicePref:1,
      //   income_min:0,
      //   income_max:50,
      //   height_min:60,
      //   height_max:72,
      // age_min:25,
      //   age_max :33,
      //   marital_statusPref:[1],
      //   cityPref:[706],
      //   religionPref:4,
      //   regionPref:[37],
      //   countryPref:[101]
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updatePreferencesDetails(shouldUpdateData(state));
    reset({});
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <div className="my-profile-form">
        <form onSubmit={handleSubmit}>
          <header className="d-flex justify-content-space-between align-items-center">
            <div onClick={onHide}>
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.75 11.9167H7.39917L11.2775 8.0275L9.75 6.5L3.25 13L9.75 19.5L11.2775 17.9725L7.39917 14.0833H22.75V11.9167Z"
                  fill="black"
                />
              </svg>
              <span className="header-text">Preferences</span>
            </div>
            {/* <div className="p-3"> */}
            <Button type="submit" className="btn btn-primary" title="Save" />
            {/* </div> */}
          </header>
          <div className="d-flex flex-wrap justify-content-between align-items-center  pl-3 pr-3">
            <TextField
              label="Age Min"
              className="input-field w-50 pr-2 mt-3"
              variant="outlined"
              value={state.minAge}
              InputProps={{
                inputProps: {
                  max: 80,
                  min: 18,
                },
              }}
              onChange={changeState}
              type="number"
              name="minAge"
            />

            <TextField
              label="Age Max"
              className="input-field w-50 pl-2 mt-3"
              variant="outlined"
              value={state.maxAge}
              InputProps={{
                inputProps: {
                  max: 80,
                  min: 18,
                },
              }}
              onChange={changeState}
              type="number"
              name="maxAge"
            />

            <Autocomplete
              className="input-field w-50 mt-3 pr-2"
              options={heights}
              name="minHeight"
              value={state.minHeight}
              onChange={(e, value) => changeState(e, value, "minHeight")}
              renderInput={(params) => (
                <TextField {...params} label="Min Height" />
              )}
            />

            <Autocomplete
              className="input-field w-50 mt-3 pl-2"
              options={heights}
              name="maxHeight"
              value={state.maxHeight}
              onChange={(e, value) => changeState(e, value, "maxHeight")}
              renderInput={(params) => (
                <TextField {...params} label="Max Height" />
              )}
            />

            {/* <Autocomplete
              className='input-field w-50 mt-3 pr-2'
              options={['Never Married','Awaiting Divorce','Divorcee','Widowed','Anulled',"Doesn't Matter",]}
              name="maritalStatus"
              value={state.maritalStatus}
              onChange={(e, value) => changeState(e, value, 'marital status')}
              renderInput={(params) => <TextField {...params} label="Marital Status" />}
            /> */}

            <Autocomplete
              className="input-field w-50 mt-3 pr-2"
              options={[
                "Never Married",
                "Awaiting Divorce",
                "Divorcee",
                "Widowed",
                "Anulled",
                "Doesn't Matter",
              ]}
              name="maritalStatus"
              value={state.maritalStatus}
              onChange={(e, value) => changeState(e, value, "maritalStatus")}
              renderInput={(params) => (
                <TextField {...params} label="Marital Status" />
              )}
            />

            <Autocomplete
              className="input-field w-50 mt-3 pr-2"
              options={["Non-vegetarian", "Vegetarian", "Doesn't Matter"]}
              name="manglik"
              value={state.foodChoicePref}
              onChange={(e, value) => changeState(e, value, "foodChoicePref")}
              renderInput={(params) => (
                <TextField {...params} label="Food Choice" />
              )}
            />

            <TextField
              label="Min Income (in LPA)"
              className="input-field w-50 pr-2 mt-3"
              variant="outlined"
              value={state.minIncome}
              InputProps={{
                inputProps: {
                  max: 200,
                  min: 0,
                },
              }}
              onChange={changeState}
              type="number"
              name="minIncome"
            />
            <TextField
              label="Max Income (in LPA)"
              className="input-field w-50 pl-2 mt-3"
              variant="outlined"
              value={state.maxIncome}
              InputProps={{
                inputProps: {
                  max: 200,
                  min: 0,
                },
              }}
              onChange={changeState}
              type="number"
              name="maxIncome"
            />

            <Autocomplete
              className="input-field w-50 mt-3 pr-2"
              options={["Working", "Not-working", "Doesn't Matter"]}
              name="workingStatus"
              value={state.workingPreference}
              onChange={(e, value) => changeState(e, value, "maxHeight")}
              renderInput={(params) => (
                <TextField {...params} label="Working Status" />
              )}
            />

            <TextField
              label="Mother Tongue"
              className="input-field w-50 pl-2 mt-3"
              variant="outlined"
              value={"Mother Tongue"}
              onChange={changeState}
              type="text"
              name="motherTongue"
            />

            <Autocomplete
              className="input-field w-50 mt-3 pr-2"
              options={[
                "Manglik",
                "Non-Manglik",
                "Anshik Manglik",
                "Doesn't Matter",
              ]}
              name="manglik"
              value={state.manglikPreference}
              onChange={(e, value) =>
                changeState(e, value, "manglikPreference")
              }
              renderInput={(params) => (
                <TextField {...params} label="Manglik" />
              )}
            />

            <Autocomplete
              className="input-field w-50 mt-3 pl-2"
              options={religion}
              name="religion"
              value={state.religionPreference}
              onChange={(e, value) =>
                changeState(e, value, "religionPreference")
              }
              renderInput={(params) => (
                <TextField {...params} label="Religion" />
              )}
            ></Autocomplete>

            {/* {!state.isCaste && (
              <CasteSelect value={state.castePreference}
              //  handler={changeState} 
              handler={(e, value) => changeState(e, value, 'castPreference')}
              
                multiple={false} />
            )} */}

            <Select
              className="input-field w-50 mt-3 pl-2"
              labelId="test-select-label"
              label="Label"
              name="caste"
              //  handleChange={changeState}
              onChange={(e) => changeState(e)}
              //  onBlur={handleFocusOut}
              //  onFocus={handleFocusIn}
            >
              {cast.map((caste) => (
                <MenuItem
                  key={caste.id}
                  value={caste.value}
                  // onClick={handleSuggestionSelect}
                >
                  {caste.value}
                </MenuItem>
              ))}
            </Select>

            {/* styles="input-field w-100 mt-3" */}
            <FormControlLabel
              control={
                <Checkbox
                  name="isCaste"
                  onChange={(e) => updateState("isCaste", e.target.checked)}
                />
              }
              label="No Caste Bar"
              className="mt-1"
            />
          </div>

          <div className="p-3">
            {/* <div>
              <label className='w-100'>Description(max 300 words):</label>
              <textarea className="w-100 mt-3"
                cols={50}
                rows={2}
                maxLength="300"
                defaultValue={"Description"}
                placeholder="Description (max 300 characters)"
                onChange={changeState}
              />
            </div> */}

            {/* <Button
              type="submit"
              className="btn btn-primary full-width mt-3"
              title="Save"
            /> */}
          </div>
        </form>
      </div>
    </Modal>
  );
};
const mapStateToProps = createStructuredSelector({
  religion: selectReligion,
  allCaste: selectAllCaste,
  maritalStatus: selectMaritalStatus,
  height1: selectHeights1,
  heights: selectHeights,
  city: selectCity,
  id: selectId,
  isLead: selectIsLead,
  templeId: selectTempleId,
  identityNumber: selectIdentityNumber,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCaste: () => dispatch(getAllCaste()),
  updatePreferencesDetails: (data) => dispatch(updatePreferencesDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreferencesForm);
