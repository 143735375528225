import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { updatePersonalDetails } from "../../../redux/my-profile/my-profile.action";
import { selectId } from "../../../redux/auth/auth.selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
function ContactForm({
  close,
  personalData,
  updatePersonalDetails,
  submitForm,
}) {
  const [emailFamily, setEmailFamily] = useState();
  const [formDetails, setFormDetails] = useState({
    // email_family: personalData.email,
    mobile: personalData.mobile,
    whatsapp: personalData.whatsapp,
  });

  useEffect(() => {
    if (submitForm) {
      updatePersonalDetails({ ...formDetails, email_family: emailFamily });
      close();
    }
  }, [submitForm, formDetails]);

  const handleChangeTextFields = (e) => {
    if (e.target.name) {
      setFormDetails({
        ...formDetails,
        [e.target.name]: e.target.value,
      });
    }
  };
  console.log("email", emailFamily);
  return (
    <form className="contact-form-container">
      {/* <TextField
        label="Email"
        onChange={handleChangeTextFields}
         className='input-field w-100 mt-3'
              variant="outlined"
               value={formDetails.email}
               type="email"
               name='email'
                /> */}

      <TextField
        label="Email"
        onChange={(e) => {
          setEmailFamily(e.target.value);
        }}
        className="input-field w-100 mt-3"
        variant="outlined"
        value={emailFamily}
        type="email"
        name="email"
        style={{ flexWrap: "wrap" }}
        numberOfLines={1}
      />

      <TextField
        label="Mobile"
        onChange={handleChangeTextFields}
        className="input-field w-100 mt-3"
        variant="outlined"
        value={formDetails.mobile}
        name="mobile"
        disabled
      />

      <TextField
        label="Whatsapp"
        onChange={handleChangeTextFields}
        className="input-field w-100 mt-3"
        variant="outlined"
        value={formDetails.whatsapp}
        name="whatsapp"
        type="number"
      />
    </form>
  );
}

// export default ContactForm
const mapStateToProps = createStructuredSelector({
  id: selectId,
});

const mapDispatchToProps = (dispatch) => ({
  updatePersonalDetails: (data) => dispatch(updatePersonalDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
