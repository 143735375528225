import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectManglikMapping } from '../../../redux/mappedData/mappedData.selector'

function ManglikSelect({value,manglikList,handler,styles}) {
  return (
    <Autocomplete
    className={styles}
    id="food_choice"
    name="food_choice"
    options={manglikList.map(it => it.name)}
    sx={{ width: 300 }}
    defaultValue={value}
    onChange={handler}
    renderInput={(params) => <TextField {...params} label="Manglik Status" />}
  />
  )
}

const mapStateToProps = createStructuredSelector({
    manglikList: selectManglikMapping
})

export default connect(mapStateToProps,null)(ManglikSelect)