import HistoryActionTypes from './history.types';

const INITIAL_STATE = {
  contact: [],
  reject: [],
  shortlist: [],
  profile: null,
  likesCount: null,
  rejectedCount: null,
  contactedCount: null,
}

const historyReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case HistoryActionTypes.GET_HISTORY_SUCCESS:
      return {
        ...state,
        contact: action.payload.contactedProfiles,
        reject: action.payload.rejectedProfiles,
        shortlist: action.payload.likedProfiles,
        ...action.payload,
      }
    case HistoryActionTypes.POST_REPLY_SUCCESS:
      return {
        ...state,
        [action.payload.removeFrom]: state[action.payload.removeFrom].filter(item => item.id.toString() !== action.payload.id.toString()),
        // [action.payload.removeTo]: [...state[action.payload.removeFrom].filter(item => item.id.toString() === action.payload.id.toString()), ...state[action.payload.removeTo]],
      }
    case HistoryActionTypes.GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload.userProfileDetail,
        ...action.payload
      }
    case HistoryActionTypes.REMOVE_PROFILE:
      return {
        ...state,
        profile: null
      }
    case HistoryActionTypes.GET_CREDITS_COUNT_SUCCESS:
      return {
        ...state,
        credits: action.payload
      }
    default:
      return {
        ...state
      }
  }
}

export default historyReducer; 