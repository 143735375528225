
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import './payment.styles.scss';

import Paytm from './paytm.svg';
import Razor from './razor.svg';
import Visa from './visa.svg';
import Master from './master.svg';
import Hand from './hand.svg';
import Checkout from './checkout/checkout.component';
import { useEffect } from 'react';
import { selectPaymentPlans } from '../../redux/payment/payment.selector';
import { getPaymentPlans } from '../../redux/payment/payment.action';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectId, selectIsLead } from '../../redux/auth/auth.selector';
import PlanItem from './plan-item/plan-item.component';

const Payment = ({show,onHide,plansList,getPlans,id,isLead}) => {

  const [planType, setPlanType] = useState(0);
  const [activePlan, setActivePlan] = useState(0);
  const [isPay, setIsPay] = useState(false);
  const [filteredPlanList, setFilteredPlanList] = useState([]);
  

  useEffect(() => {
    getPlans({id,is_lead: isLead})
  },[id])

  useEffect(() => {
    if (plansList.length) {
      setFilteredPlanList(plansList.filter(item => item.plan_type === "Self Service Plan"))
    }
  },[plansList])

  useEffect(() => {

    const loadRazorPayScript = () => {
      const razor = document.getElementById('razorPay');
      if (!razor) {
        const fileName = document.createElement('script');
        fileName.async = true;
        fileName.setAttribute('type', 'text/javascript');
        fileName.setAttribute('src', 'https://checkout.razorpay.com/v1/checkout.js');
        fileName.id = 'razorPay';
        document.body.appendChild(fileName);
      }
    }
    loadRazorPayScript();
  }, [])

  const handleClick = (idx) => {
    console.log(idx)
    setActivePlan(state => idx)
  }

  const setActivePlanType = (type) => {
    let list = []
    if (type === 0) {
      list = plansList.filter(item => item.plan_type === "Self Service Plan")
    } else { 
      list = plansList.filter(item => item.plan_type === "Personalized Plan")
    }
    setFilteredPlanList(list)

   setPlanType(type)
  }

  const startPayment = () => setIsPay(true)

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      style={{zIndex: "9999"}}
      aria-labelledby="contained-modal-title-center"
      centered
    >
      <div class="d-flex justify-content-center align-items-center mt-5">
        <div class="payment-popup">
          <div class="container">

            <div
              onClick={onHide}
              className='d-flex justify-content-end align-items-center mt-3'>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#F0EDED" />
              </svg>
            </div>

            <header class="plan-type d-flex mt-4">
              <div
                onClick={() => setActivePlanType(0)}
                className={`d-flex justify-content-center align-items-center ${planType === 0 ? 'active' : ''}`}
              >
                <span>Self Service</span>
              </div>
              <div
                onClick={() => setActivePlanType(1)}
                className={`d-flex justify-content-center align-items-center ${planType === 1 ? 'active' : ''}`}
              >
                <span>Personalised</span>
              </div>
            </header>

                <div className="plan-item-container">
                  {

                  filteredPlanList.length ? filteredPlanList.map((plans,idx) => (
                    <PlanItem 
                    key={plans.id}
                    item={plans}
                    idx={idx}
                    handleClick={handleClick}
                    activePlan={activePlan}
                    />
                  )) : []
                }
                </div>

                  
                 {
                    filteredPlanList.length ? (
                      <button 
                      onClick={startPayment}
                      className="buy-now-btn">
                      Buy Now
                    </button>
                    ) : null
                   
                 }

          

            <footer>
              <div className='d-flex justify-content-between align-items-center mt-5'>
                <div><hr /></div>
                <div><p> GUARANTEED SAFE CHECKOUT</p></div>
                <div style={{ position: 'relative' }}>
                  <hr />
                </div>
              </div>
              <div className='d-flex justify-content-between align-items-center mt-2'>
                <div><img src={Master} alt="" /></div>
                <div><img src={Visa} alt="" /></div>
                <div><img src={Razor} alt="" /></div>
                <div><img src={Paytm} alt="" /></div>
              </div>
            </footer>

            {
              filteredPlanList.length ? <Checkout show={isPay} onHide={() => setIsPay(false)} plan={filteredPlanList[activePlan]} /> : null
            }
          </div>
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProps = createStructuredSelector({
  id: selectId,
  isLead: selectIsLead,
  plansList: selectPaymentPlans
})

const mapDispatchToProps = dispatch => ({
  getPlans: (data) => dispatch(getPaymentPlans(data)),
})


export default connect(mapStateToProps,mapDispatchToProps)(Payment);