import React from 'react';

import Sister from "../../assets/profile/Sister.svg";
import Brother from "../../assets/profile/Brother.svg";
import Mother from "../../assets/profile/Mother.svg";
import Father from "../../assets/profile/Father.svg";
import Swastika from "../../assets/profile/swastika.svg";
import FamilyType from "../../assets/profile/Family Type.svg";
import FamilyLivingIn from "../../assets/profile/familyIn.svg";
import House from "../../assets/profile/House Type.svg";
import Money from "../../assets/profile/moneybag.svg";

import IsDisplay from './is-display/is-display.component';

const Family = (family) => {

  return (
    <div id='Family' className='profile-mb-5'>
      <div id="familySection">
        <div className="d-flex justify-content-between">
          <h3 className="profile-titleHeading">Family Details</h3>
        </div>


        <div className="row profile-row">
          <IsDisplay image={Sister} title={family.sister} />
          <IsDisplay image={Brother} title={family.brother} />
          <IsDisplay image={Mother} title={family.mother} />
          <IsDisplay image={Father} title={family.father} />
          <IsDisplay image={Swastika} title={family.gotra} />
          <IsDisplay image={FamilyType} title={family.familyType} />
          <IsDisplay image={FamilyLivingIn} title={family.familyLivingIn} />
          <IsDisplay image={House} title={family.houseStatus} />
          <IsDisplay image={Money} title={family.familyIncome} />
        </div>

      </div>
    </div>
  )
}
export default Family;