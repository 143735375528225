import { createSelector } from 'reselect';
import {
  selectReligionMapping, selectCasteMapping, selectFoodChoiceMapping,
  selectMaritalMapping, selectManglikMapping,
  selectEducationMapping, selectUserOccupationMapping,
  selectCountryList
} from "./../mappedData/mappedData.selector";


import {
  toTitleCase,
  setAbout,
  setIncome,
  setMarriageBrothers,
  setMarriageSisters,
  LifeStatus,
  setHeight,
  getCasteString,
  getReligionString,
  getFoodChoiceString,
  getMaritalString,
  getProfessionString,
  getManglikString,
  setHeightInString,
  setAge
} from '../../utils/display.utils';

import { IMAGE_URL_PREFIX } from '../../utils/api';

const myProfile = state => state.myProfile;

export const selectProfile = createSelector(
  [myProfile],
  myProfile => myProfile.profile ? myProfile.profile[0] : []
)
export const selectPreferences = createSelector(
  [myProfile],
  myProfile => myProfile.preferences
)
export const selectFamily = createSelector(
  [myProfile],
  myProfile => myProfile.family
)
export const selectCountry = createSelector(
  [selectCountryList],
  countryList => countryList?.map(item => ({ name: item.name, userId: item.id }))
)
export const selectTempleId = createSelector(
  [selectProfile],
  profile => profile.temple_id
)

export const selectUserGender = createSelector(
  [selectProfile],
  profile => profile ? profile.gender : undefined
)

export const selectIdentityNumber = createSelector(
  [selectProfile],
  profile => profile.identity_number
)

const setMaritalFromArray = (maritalPref, maritalList) => {
  return getMaritalString(maritalPref, maritalList)
}

const setFoodChoiceFromArray = (foodPref, foodChoiceList) => {
  return getFoodChoiceString(foodPref, foodChoiceList)
}
const setOccupationPrefString = (value) => {
  const occupationList = [
    "Business/Self Employed",
    "Doctor",
    "Government Job",
    "Teacher",
    "Private Job",
    "Studying",
    "Homely",
    "other",
  ];
  return occupationList[value];
}
const setCasteFromArray = (casteArray, casteArrayList) => {
  const caste = casteArray
  let casteString = ""
  caste.forEach(element => {
    if (casteString === "") {
      casteString = getCasteString(element, casteArrayList)
    } else {
      casteString += ", " + getCasteString(element, casteArrayList)
    }
  });
  return casteString
}

const setManglikFromArray = (manglikPref, manglikList) => {
  return getManglikString(manglikPref, manglikList)
}

export const selectMyProfile = createSelector(
  [selectProfile, selectPreferences, selectFamily, selectCasteMapping, selectReligionMapping, selectFoodChoiceMapping, selectMaritalMapping,
    selectManglikMapping, selectEducationMapping, selectUserOccupationMapping],
  (profile, preferences, family, casteList, religionList, foodChoiceList,
    maritalList, manglikList, degreeList, userOccupationList) => {
    if (!profile || !preferences) {
      return null;
    }

    return {
      personal: {
        // verifiedAt: profile.facebook ? setVerifiedAt(profile.facebook) : 'Hans Matrimony Office',
        // managedBy: profile.relation ? profile.relation : profile.gender == 'Male' ? 'Mother' : 'Father',
        about: profile.about ? profile.about.length < 30 ? toTitleCase(setAbout(profile, casteList, manglikList, degreeList, userOccupationList)) : toTitleCase(profile.about) : toTitleCase(setAbout(profile, casteList, manglikList, degreeList, userOccupationList)),
        college: toTitleCase(profile.college),
        occupation: profile.occupation,
        profession: getProfessionString(profile.profession),
        religion: profile.religion,
        caste: profile.caste,
        foodChoice: profile.food_choice,
        weight: `${profile.weight} Kg`,
        maritalStatus: profile.marital_status,
        locality: profile.locality ? profile.locality : profile.city ? profile.city : '',
        name: profile.name,
        age: `${setAge(profile.birth_date)}`,
        height: setHeight(profile.height),
        mobile: profile.mobile_profile ? profile.mobile_profile : profile.mobile,
        horoscope: profile.manglik,
        birth_place: profile.birth_place,
        birth_time: profile.birth_time,
        birth_date: profile.birth_date?.split("T")[0],
        additional: profile.additional_qualification,
        company: profile.company,
        email: profile.email_family,
        user_weight: `${profile.weight}`,
        degree: profile.degree,
        income: profile.annual_income / 100000,
        working_city: profile.working_city,
        gotra: profile.gotra,
        audioIntro: profile.audio_profile,
        manglik: profile.manglik
      },
      preferences: {
        minAge: preferences.age_min,
        maxAge: preferences.age_max,
        age: `${preferences.age_min} to ${preferences.age_max}`,
        height: `${setHeight(preferences.height_min)} to ${setHeight(preferences.height_max)}`,
        minHeight: setHeightInString(preferences.heightMin),
        maxHeight: setHeightInString(preferences.heightMax),
        maritalStatus: setMaritalFromArray(preferences.marital_statusPref, maritalList),
        foodChoice: setFoodChoiceFromArray(preferences.foodChoicePref[0], foodChoiceList),
        annualIncome: `${preferences.income_min} to ${preferences.income_max} LPA`,
        minIncome: preferences.incomeMin,
        maxIncome: preferences.incomeMax,
        workingPreference: setOccupationPrefString(preferences.occupationPref[0]),
        religionPreference: getReligionString(preferences.religionPref[0], religionList),
        castePreference: setCasteFromArray(preferences.castePref, casteList),//preferences.caste,
        manglikPreference: setManglikFromArray(preferences.manglikPref, manglikList) //preferences.manglik,
      },
      family: {
        about: profile.about,
        married_sisters: profile.married_sisters,
        unmarried_sisters: profile.unmarried_sisters,
        married_brothers: profile.married_brothers,
        unmarried_brothers: profile.unmarried_brothers,
        mother: LifeStatus(profile.mother_status, profile.occupation_mother, 'Mother'),
        father: LifeStatus(profile.father_status, profile.occupation_father, 'Father'),
        father_occupation: profile.father_status ? profile.father_status === 1 ? profile.occupation_father : '' : '',
        mother_occupation: profile.mother_status ? profile.mother_status === 1 ? profile.occupation_mother : '' : '',
        gotra: profile.gotra,
        familyType: profile.family_type,
        familyLivingIn: profile.city ? profile.city : profile.locality ? profile.locality : '',
        houseType: profile.house_type,
        familyIncome: setIncome(profile.family_income),
        livingWithParents: profile.livingWithParents,
      },
      userId: profile.id
    };
  }
)



export const selectRishteyCount = createSelector(
  [myProfile],
  myProfile => myProfile.rishteyCount
)

export const selectRecommendedPreference = createSelector(
  [myProfile],
  myProfile => myProfile.recommendedPreference
)


export const selectContactProfile = createSelector(
  [myProfile],
  myProfile => myProfile.contact ? myProfile.contact.map(item => getHeading(item)) : []
)

const getHeading = (item) => {

  return {
    title: `${item.name} | ${item.age}`,
    subTitle1: `${item.religion} | ${item.caste ?? ""}`,
    subTitle2: `${item.locality ? item.locality : item.working_city ?? ""} | ${setHeight(`${item.height_int}`)}`,
    subTitle3: `${item.occupation ? item.occupation + " | " : ""}  ${item.annual_income?.length > 5 ? item.annual_income / 1 : item.annual_income} Lpa`,
    photo: item.photo ? item.photo.includes('https') ? item.photo : IMAGE_URL_PREFIX + item.photo : null,
    id: item.id,
    item: item,
    mobile: item.user_mobile
  }
}