import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Doughnut } from 'react-chartjs-2';

import { selectPhotoUrl, selectName } from '../../redux/auth/auth.selector';

import './my-profile-header.styles.scss';
import UploadPhoto from '../upload-photo/upload-photo.component';
import avatar from "../../components/assets/profile/avatar.svg"
import { useSelector } from 'react-redux';

const DrawerHeader = ({ name, photoUrl }) => {

const userPhoto = useSelector(state => state.myProfile?.profile?.[0]?.user_photos);
// console.log('userPhot',userPhoto[0].photo_url)
// console.log(photoUrl);
// const url = userPhoto[0].photo_url;

  const data = {
    labels: [
      'Red',
      'Green',
    ],
    datasets: [{
      data: [25, 75],
      backgroundColor: [
        '#C4C4C4',
        '#0D70B2',
      ],
      hoverBackgroundColor: [
        '#C4C4C4',
        '#0D70B2',
      ]
    }],
  };
  console.log('this is the name and photo url',name,photoUrl)
  const [isEditPhoto, setIsEditPhoto] = useState(false);
  return (
    <div className='my-profile-header'>
      <div className='logo' onClick={() => setIsEditPhoto(true)}>
        <img src={userPhoto?.length ? 'https://hansmatrimony.s3.ap-south-1.amazonaws.com/uploads/'+userPhoto[0].photo_url: avatar} alt="" />
        {/* <img src={userPhoto?.length ? 'https://d2v6etlhrhtken.cloudfront.net/uploads/' + userPhoto[0].photo_url : avatar} alt="" /> */}
        
      </div>
      <div>
        <h1>{name}</h1>
        <div>
          <Doughnut
            data={data}
            width={60}
            height={60}
            legend={{
              display: false
            }} 
            options={{ maintainAspectRatio: false }}
          />
        </div>
        <div className='sub-title'>
          <span>75% Profile completed</span>
        </div>
      </div>
      <UploadPhoto show={isEditPhoto} onHide={() => setIsEditPhoto(false)} />
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  photoUrl: selectPhotoUrl,
  name: selectName
})

export default connect(mapStateToProps, null)(DrawerHeader);