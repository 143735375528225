import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Modal } from 'react-bootstrap';

import { uploadProfilePicture } from '../../redux/my-profile/my-profile.action';
import { selectId, selectIsLead, selectPhotoUrl } from '../../redux/auth/auth.selector';

import { generateBase64FromImage } from '../../utils/images';

import { Delete, Edit, Plus } from './svg';


import './upload-photo.styles.scss';
import { IMAGE_URL_PREFIX } from '../../utils/api';
import { useEffect } from 'react';

const UploadPhoto = ({ show, onHide, uploadProfilePicture, isLead, id,photoUrl }) => {

  const [photoAlbum,setPhotoAlbum] = useState([])

  useEffect(() => {
    if (photoUrl?.length) {
        setPhotoAlbum(photoUrl.map(it => !it.includes('https') ? IMAGE_URL_PREFIX + it : it))
    }
  },[photoUrl])

  const handleChange = event => {
    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0];
      const mimeType = img.type;
      if (mimeType.match(/image\/*/) == null) {
        console.log('Only images are supported.')
        return;
      }

      generateBase64FromImage(img)
        .then(b64 => {
          let cloneAlbum = [...photoAlbum]
          cloneAlbum.push(b64)
          setPhotoAlbum(cloneAlbum)

          const byteString = atob(b64.split(',')[1]);
          let mimeString = b64.split(',')[0].split(':')[1].split(';')[0];

          // write the bytes of the string to a typed array
          let ia = new Uint8Array(byteString.length);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }

          let blob = new Blob([ia], { type: mimeString });

          let uploadData = new FormData();
          uploadData.append('profilePicture', blob);
          
          uploadProfilePicture(id, uploadData)

        })
        .catch(e => {
          console.log(e)
        });
    }
  }


  const clickImageSelector = (id) => {
    document.getElementById(id).click();
}

  return (
    <Modal show={show} onHide={onHide} >
      <div className='upload-photo'>
        <div>
          <div onClick={onHide}>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.75 11.9167H7.39917L11.2775 8.0275L9.75 6.5L3.25 13L9.75 19.5L11.2775 17.9725L7.39917 14.0833H22.75V11.9167Z" fill="black" />
            </svg>
          </div>
          <h1>Upload Photo</h1>
        </div>
        <div className="body d-flex flex-wrap justify-content-around align-items-center m-3">

          <div className="row">

            <div className='col-6  photo'>
              <div className='img' onClick={() => clickImageSelector("1")}>
                {
                  photoAlbum[0] ? (
                    <img src={photoAlbum[0]} alt="user" />
                  ) : <Plus />
                }
                
              </div>
              <div className='d-flex justify-content-end align-items-end icons'>
                <div className='icon' ><Delete /></div>
                <label className='icon' style={{ backgroundColor: '#fff' }} htmlFor="1">
                  <Edit />
                  <input id='1' name='1' onChange={handleChange} type="file" hidden />
                </label>
              </div>
            </div>

            <div className='col-6 photo'>
            <div className='img' onClick={() => clickImageSelector("2")}>
                {
                  photoAlbum[1] ? (
                    <img src={photoAlbum[1]} alt="user" />
                  ) : <Plus />
                }
                
              </div>
              <div className='d-flex justify-content-end align-items-end icons'>
                <div className='icon' ><Delete /></div>
                <label className='icon' style={{ backgroundColor: '#fff' }} htmlFor="2">
                  <Edit />
                  <input id='2' name='2' onChange={handleChange} type="file" hidden />
                </label>
              </div>
            </div>

          </div>

          <div className="row">

            <div className='col-6  photo'>
            <div className='img' onClick={() => clickImageSelector("3")}>
                {
                  photoAlbum[2] ? (
                    <img src={photoAlbum[2] } alt="user" />
                  ) : <Plus />
                }
                
              </div>
              <div className='d-flex justify-content-end align-items-end icons'>
                <div className='icon' ><Delete /></div>
                <label className='icon' style={{ backgroundColor: '#fff' }} htmlFor="3">
                  <Edit />
                  <input id='3' name='3' onChange={handleChange} type="file" hidden />
                </label>
              </div>
            </div>

            <div className='col-6 photo'>
            <div className='img' onClick={() => clickImageSelector("4")}>
                {
                  photoAlbum[3] ? (
                    <img src={photoAlbum[3] } alt="user" />
                  ) : <Plus />
                }
                
              </div>
              <div className='d-flex justify-content-end align-items-end icons'>
                <div className='icon' ><Delete /></div>
                <label className='icon' style={{ backgroundColor: '#fff' }} htmlFor="4">
                  <Edit />
                  <input id='4' name='4' onChange={handleChange} type="file" hidden />
                </label>
              </div>
            </div>

          </div>

          <div className="row">

            <div className='col-6  photo'>
            <div className='img' onClick={() => clickImageSelector("5")}>
                {
                  photoAlbum[4] ? (
                    <img src={photoAlbum[4]} alt="user" />
                  ) : <Plus />
                }
                
              </div>
              <div className='d-flex justify-content-end align-items-end icons'>
                <div className='icon' ><Delete /></div>
                <label className='icon' style={{ backgroundColor: '#fff' }} htmlFor="5">
                  <Edit />
                  <input id='5' name='5' onChange={handleChange} type="file" hidden />
                </label>
              </div>
            </div>

            <div className='col-6 photo'>
            <div className='img' onClick={() => clickImageSelector("6")}>
                {
                  photoAlbum[5] ? (
                    <img src={photoAlbum[5] } alt="user" />
                  ) : <Plus />
                }
                
              </div>
              <div className='d-flex justify-content-end align-items-end icons'>
                <div className='icon' ><Delete /></div>
                <label className='icon' style={{ backgroundColor: '#fff' }} htmlFor="6">
                  <Edit />
                  <input id='6' name='6' onChange={handleChange} type="file" hidden />
                </label>
              </div>
            </div>

          </div>

        </div>
      </div>
    </Modal>
  )
}

const mapStateToProps = createStructuredSelector({
  isLead: selectIsLead,
  id: selectId,
  photoUrl: selectPhotoUrl
})

const mapDispatchToProps = dispatch => ({
  uploadProfilePicture: (id,data) => dispatch(uploadProfilePicture(id,data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(UploadPhoto);