const UserChatActionTypes = {
    OPEN_USER_CHAT: 'OPEN_USER_CHAT',
    GET_USER_CHAT_REFERENCE_START: 'GET_USER_CHAT_REFERENCE_START',
    GET_USER_CHAT_REFERENCE_SUCCESS: 'GET_USER_CHAT_REFERENCE_SUCCESS',
    GET_USER_CHAT_REFERENCE_FAILURE: 'GET_USER_CHAT_REFERENCE_FAILURE',

    GET_USER_CHAT_ROOMS_START: 'GET_USER_CHAT_ROOMS_START',
    GET_USER_CHAT_ROOMS_SUCCESS: 'GET_USER_CHAT_ROOMS_SUCCESS',
    GET_USER_CHAT_ROOMS_FAILURE: 'GET_USER_CHAT_ROOMS_FAILURE',

    GET_USER_CHAT_ROOMS_MESSAGES_START: 'GET_USER_CHAT_ROOMS_MESSAGES_START',
    GET_USER_CHAT_ROOMS_MESSAGES_SUCCESS: 'GET_USER_CHAT_ROOMS_MESSAGES_SUCCESS',
    GET_USER_CHAT_ROOMS_MESSAGES_FAILURE: 'GET_USER_CHAT_ROOMS_MESSAGES_FAILURE',

    RESET_USER_CHAT_ROOMS_MESSAGES: 'RESET_USER_CHAT_ROOMS_MESSAGES',

    NEW_CHAT_ROOM_CREATION_START: 'NEW_CHAT_ROOM_CREATION_START',
    NEW_CHAT_ROOM_CREATION_SUCCESS: 'NEW_CHAT_ROOM_CREATION_SUCCESS',
    NEW_CHAT_ROOM_CREATION_FAILURE: 'NEW_CHAT_ROOM_CREATION_FAILURE',

    NEW_USER_CREATION_START: 'NEW_USER_CREATION_START',
    NEW_USER_CREATION_SUCCESS: 'NEW_USER_CREATION_SUCCESS',
    NEW_USER_CREATION_FAILURE: 'NEW_USER_CREATION_FAILURE',
  }
  
  export default UserChatActionTypes;