import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectProfile } from "../../redux/history/history.selector";
import LogoButton from "../logo-button/logo-button.component";
import ProfileItem from "../profile-list/profile-item/profile-item.component";
import call from "../assets/chat/call.svg";
import liked from "../assets/chat/liked.svg";
import reject from "../assets/chat/reject.svg";
import comment from "../assets/comment.png";
import whatsapp from "../assets/chat/whatsapp_icon.png";
import { removeProfile } from "../../redux/history/history.action";
import { useNavigate, useParams } from "react-router-dom";
import Payment from "../payment/payment.componet";
import { selectShowPayment } from "../../redux/payment/payment.selector";
import { makePayment } from "../../redux/payment/payment.action";
import { sectionTypes } from "../../utils/display.utils";
import "./open-profile.styles.scss";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OpenProfile = ({
  selectedProfile,
  removeProfile,
  makePayment,
  showPaymentPopup,
}) => {
  const history = useNavigate();
  const urlParams = useParams();
  const location = useLocation();
  console.log("selectedProfile ", selectedProfile);

  useEffect(() => {
    return () => removeProfile();
  }, []);

  const removeProfileAndClose = () => {
    history(-1);
  };
  const [isContacted, setIsContacted] = useState(
    location.hash.includes("#Contacted")
  );
  console.log("isContacted", isContacted);
  console.log("urlParams", location.hash);
  /**
   * get buttons according to the section type
   * @param {*} item
   * @returns
   */
  function getButtonsAccordingToSections() {
    let button;
    let item = selectedProfile;
    let section = urlParams.type;
    console.log("secton and profile", section, item);

    switch (section) {
      case sectionTypes.SEE_MORE:
        return (
          <>
            <LogoButton
              imageUrl={whatsapp}
              action="WHATSAPP"
              // action={buttonActionTypes.WHATSAPP}
              actionId={selectedProfile.userId}
              actionOf="1"
              callFrom={sectionTypes.SEE_MORE}
              addCircularBg={true}
              // section={section}
              mobile={selectedProfile.mobile}
              showName={true}
              // callBackAfterAction={removeProfileAndClose}
            />

            <LogoButton
              imageUrl={call}
              action="CONTACTED"
              actionId={selectedProfile.userId}
              actionOf="1"
              callFrom={sectionTypes.SEE_MORE}
              showName={true}
              callBackAfterAction={() => {
                console.log("button clicked");
                setIsContacted(true);
              }}
              // callBackAfterAction={removeProfileAndClose}
            />
          </>
        );

      case sectionTypes.LIKED_ME:
        return (
          <>
            <LogoButton
              imageUrl={comment}
              action="CONTACTEDCHAT"
              actionId={item.userId}
              actionOf="1"
              callFrom={sectionTypes.LIKED_ME}
              addCircularBg={true}
              section={section}
              showName={true}
              // callBackAfterAction={removeProfileAndClose}
            />

            <LogoButton
              imageUrl={call}
              action="CONTACTED"
              actionId={selectedProfile.userId}
              actionOf="1"
              callFrom={sectionTypes.LIKED_ME}
              showName={true}
              callBackAfterAction={() => {
                console.log("button clicked");
                setIsContacted(true);
              }}
              // callBackAfterAction={removeProfileAndClose}
            />
          </>
        );

      case sectionTypes.VIEWED_ME:
        return (
          <>
            <LogoButton
              imageUrl={comment}
              action="CONTACTED"
              actionId={item.userId}
              actionOf="1"
              callFrom={sectionTypes.VIEWED_ME}
              addCircularBg={true}
              section={section}
              showName={true}
              callBackAfterAction={() => {
                console.log("button clicked");
                setIsContacted(true);
              }}
            />

            <LogoButton
              imageUrl={call}
              action="CONTACTED"
              actionId={selectedProfile.userId}
              actionOf="1"
              callFrom={sectionTypes.VIEWED_ME}
              showName={true}
              // callBackAfterAction={removeProfileAndClose}
              callBackAfterAction={() => {
                console.log("button clicked");
                setIsContacted(true);
              }}
            />
          </>
        );

      case sectionTypes.TODAY_SPECIAL:
        return (
          <>
            <LogoButton
              imageUrl={liked}
              action="LIKED"
              actionId={item.userId}
              actionOf="1"
              callFrom={sectionTypes.TODAY_SPECIAL}
              section={section}
              showName={true}
              // callBackAfterAction={removeProfileAndClose}
            />

            <LogoButton
              imageUrl={call}
              action="CONTACTED"
              actionId={item.userId}
              actionOf="1"
              callFrom={sectionTypes.TODAY_SPECIAL}
              showName={true}
              callBackAfterAction={() => {
                console.log("button clicked");
                setIsContacted(true);
              }}
              // callBackAfterAction={removeProfileAndClose}
            />
            <LogoButton
              imageUrl={reject}
              action="REJECTED"
              actionId={item.userId}
              actionOf="1"
              callFrom={sectionTypes.TODAY_SPECIAL}
              section={section}
              showName={true}
              //  callBackAfterAction={removeProfileAndClose}
            />
          </>
        );
      case sectionTypes.NEWLY_JOINED:
        return (
          <>
            <LogoButton
              imageUrl={liked}
              action="LIKED"
              actionId={item.userId}
              actionOf="1"
              callFrom={sectionTypes.NEWLY_JOINED}
              section={section}
              showName={true}
              onClick={() => {
                console.log("button clicked");
                toast.success("Clicked Liked button");
                // ... (other actions)
              }}
              // callBackAfterAction={removeProfileAndClose}
            />

            <LogoButton
              imageUrl={call}
              action="CONTACTED"
              actionId={selectedProfile.userId}
              actionOf="1"
              callFrom={sectionTypes.NEWLY_JOINED}
              showName={true}
              callBackAfterAction={() => {
                console.log("button clicked");
                setIsContacted(true);
              }}
              // callBackAfterAction={removeProfileAndClose}
            />
            <LogoButton
              imageUrl={reject}
              action="REJECTED"
              actionId={item.userId}
              actionOf="1"
              callFrom={sectionTypes.NEWLY_JOINED}
              section={section}
              showName={true}
              //  callBackAfterAction={removeProfileAndClose}
            />
          </>
        );
      case sectionTypes.POPULAR:
        return (
          <>
            <LogoButton
              imageUrl={liked}
              action="LIKED"
              actionId={item.userId}
              actionOf="1"
              callFrom={sectionTypes.POPULAR}
              section={section}
              showName={true}
              // callBackAfterAction={removeProfileAndClose}
            />

            <LogoButton
              imageUrl={call}
              action="CONTACTED"
              actionId={selectedProfile.userId}
              actionOf="1"
              callFrom={sectionTypes.POPULAR}
              section={section}
              showName={true}
              callBackAfterAction={() => {
                console.log("button clicked");
                setIsContacted(true);
              }}
            />
            <LogoButton
              imageUrl={reject}
              action="REJECTED"
              actionId={item.userId}
              actionOf="1"
              callFrom={sectionTypes.POPULAR}
              section={section}
              showName={true}
              //  callBackAfterAction={removeProfileAndClose}
            />
          </>
        );
      case sectionTypes.HISTORY_LIKE:
        return (
          <>
            <LogoButton
              imageUrl={call}
              action="CONTACTED"
              actionId={selectedProfile.userId}
              actionOf="1"
              callFrom={sectionTypes.HISTORY_LIKE}
              showName={true}
              callBackAfterAction={() => {
                console.log("button clicked");
                setIsContacted(true);
              }}
              // callBackAfterAction={removeProfileAndClose}
            />
            <LogoButton
              imageUrl={reject}
              action="REJECTED"
              actionId={item.userId}
              actionOf="1"
              callFrom={sectionTypes.HISTORY_LIKE}
              section={section}
              showName={true}
              // callBackAfterAction={removeProfileAndClose}
            />
          </>
        );

      case sectionTypes.HISTORY_REJECT:
        return (
          <>
            <LogoButton
              imageUrl={liked}
              action="LIKED"
              actionId={item.userId}
              actionOf="1"
              callFrom={sectionTypes.HISTORY_REJECT}
              section={section}
              showName={true}
              // callBackAfterAction={removeProfileAndClose}
            />

            <LogoButton
              imageUrl={call}
              action="CONTACTED"
              actionId={selectedProfile.userId}
              actionOf="1"
              callFrom={sectionTypes.HISTORY_REJECT}
              showName={true}
              callBackAfterAction={() => {
                console.log("button clicked");
                setIsContacted(true);
              }}
              // callBackAfterAction={removeProfileAndClose}
            />
          </>
        );

      case sectionTypes.HISTORY_CONTACT:
        return (
          <>
            <LogoButton
              imageUrl={whatsapp}
              action="WHATSAPP"
              actionId={item.userId}
              actionOf="1"
              callFrom={sectionTypes.HISTORY_CONTACT}
              section={section}
              showName={true}
              // callBackAfterAction={removeProfileAndClose}
              addCircularBg="true"
            />

            <LogoButton
              imageUrl={call}
              action="CALL"
              actionId={selectedProfile.userId}
              actionOf="1"
              callFrom={sectionTypes.HISTORY_CONTACT}
              showName={true}

              // callBackAfterAction={removeProfileAndClose}
            />
          </>
        );

      default:
        return (
          <>
            <LogoButton
              imageUrl={liked}
              action="LIKED"
              actionId={item.userId}
              actionOf="1"
              callFrom="history"
              section={section}
              showName={true}
              onClick={() => {
                toast.success("Clicked Liked button");
                // ... (other actions)
              }}
              // callBackAfterAction={removeProfileAndClose}
            />

            <LogoButton
              imageUrl={call}
              action="CONTACTED"
              actionId={selectedProfile.userId}
              actionOf="1"
              callFrom={section}
              showName={true}
              callBackAfterAction={() => {
                console.log("button clicked");
                setIsContacted(true);
              }}
              //  callBackAfterAction={removeProfileAndClose}
            />
          </>
        );
    }
  }
  console.log("last one", isContacted);
  return (
    <>
      {/* <ToastContainer /> */}
      <Payment show={showPaymentPopup} onHide={makePayment} />

      {selectedProfile ? (
        <div className="profile-overview">
          <div onClick={removeProfileAndClose} className="back ml-auto">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke="#9F9F9F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M6 6L18 18"
                stroke="#9F9F9F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <ProfileItem
            user={selectedProfile}
            isContacted={isContacted}
            idx={0}
            className="list1"
          />
          <div className="call-profile-item">
            <div className="button-container">
              {getButtonsAccordingToSections()}
            </div>
            {/*    
    <p style={{ color: '#0D70B2' }}>Call</p> */}
          </div>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedProfile: selectProfile,
  showPaymentPopup: selectShowPayment,
});

const mapDispatchToProps = (dispatch) => ({
  removeProfile: () => dispatch(removeProfile()),
  makePayment: () => dispatch(makePayment()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpenProfile);
