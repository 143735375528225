
import PopularActionTypes from "./popular.types"

const INITIAL_STATE = {
  popular_profiles_list: [],
}

const popularReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

    case PopularActionTypes.GET_POPULAR_PROFILES_SUCCESS:
      return {
        ...state,
        popular_profiles_list: action.payload,
      }
      
    case PopularActionTypes.REMOVE_AND_UPDATE_ITEM_FROM_POPULAR_LIST:
      let itemIndex = state.popular_profiles_list.findIndex(p => p.id === action.payload)
      let list = [...state.popular_profiles_list]
      list.splice(itemIndex,1)

      return {
        ...state,
        popular_profiles_list: list
      }

    default:
      return {
        ...state
      }
  }
}

export default popularReducer