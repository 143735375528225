
import { FETCH, API,FETCH_FROM_NODE } from '../../utils/api';
import { login } from '../auth/auth.action';

import {setError} from '../error/error.action';
import RegisterActionTypes from './register.types';

export const getAllCaste = (data) => {
  return dispatch => {
    dispatch({ type: RegisterActionTypes.GET_ALL_CASTE_START });
    FETCH({
      url: API.ALL_CASTES_URL,
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {


        dispatch({
          type: RegisterActionTypes.GET_ALL_CASTE_SUCCESS,
          payload: resData
        });
      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: RegisterActionTypes.GET_ALL_CASTE_FAILURE });
      });
  };

}

export const getCity = (query) => {
  return dispatch => {
    FETCH({
      url: API.GET_CITY_URL + query,
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {


        dispatch({
          type: RegisterActionTypes.GET_CITY,
          payload: resData
        });
      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
      });
  };

}

export const searchCity = (city) => {
  return dispatch => {
    FETCH_FROM_NODE({
      url: `${API.SEARCH_CITY_URL}?searchCity=${city}`,
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {


        dispatch({
          type: RegisterActionTypes.GET_CITY,
          payload: resData.citiesFound
        });
      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
      });
  };

}

export const updateBasic = (data) => {

  return dispatch => {

    dispatch({ type: RegisterActionTypes.UPDATE_BASIC_START })
    FETCH_FROM_NODE({
      url: API.UPDATE_BASIC_URL,
      body: JSON.stringify(data),
      method: 'POST',
      ContentType : 'application/json'
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {

        if (resData.status !== 1) {
          throw Error('Something went Worng')
        }

        dispatch({
          type: RegisterActionTypes.UPDATE_BASIC_SUCCESS,
          payload: resData
        });
        dispatch(login("phone_number=" + data.mobile.slice(3)))
      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: RegisterActionTypes.UPDATE_BASIC_FAILURE })

      });
  }
}


export const getState = (query) => {
  return dispatch => {
    FETCH({
      url: API.GET_STATES_URL + query,
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {


        dispatch({
          type: RegisterActionTypes.GET_STATE,
          payload: resData
        });
      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
      });
  };

}