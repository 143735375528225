import { createBrowserHistory } from 'history'

import { FETCH, API, FETCH_FROM_NODE } from '../../utils/api'
import AuthActionTypes from './auth.types';
import { setError } from '../error/error.action.js'

export const history = createBrowserHistory()


export const login = (query) => {
  return dispatch => {
    dispatch({ type: AuthActionTypes.LOGIN_START });
    // FETCH({ url: `auth?${query}` })
    const data = {
      'mobile': `${query}`
    }
    FETCH_FROM_NODE({
      url: API.LOGIN_URL,
      method: 'POST',
      body: JSON.stringify(data),
      ContentType: 'application/json'
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {
        console.log('this is', resData);
        dispatch({
          type: AuthActionTypes.SEND_OTP_SUCCESS,
          payload: resData,
          mob: query
        })
        // let creditparams={is_lead: resData.auth_user_details.is_lead, id: resData.auth_user_details.id}

        // dispatch(getCredits(creditparams));
      })
      .catch(err => {
        console.log('this is the error', err);
        dispatch(setError(err))
        dispatch({ type: AuthActionTypes.LOGIN_FAILURE });
      });
  };

}

export const checkAuth = () => {
  let a = 5;
}

export const logout = () => (dispatch) => dispatch({ type: AuthActionTypes.LOGOUT })

export const sendOtp = (data) => {
  return dispatch => {
    dispatch({ type: AuthActionTypes.SEND_OTP_START });
    FETCH({
      url: API.SEND_OTP_URL,
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Wrong!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {
        dispatch({
          type: AuthActionTypes.SEND_OTP_SUCCESS,
        });
      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: AuthActionTypes.SEND_OTP_FAILURE });
      });
  };

}
export const verifyOtp = (data) => {
  return dispatch => {
    dispatch({ type: AuthActionTypes.VERIFY_OTP_START });
    FETCH_FROM_NODE({
      ContentType: 'application/json',
      url: API.VERIFY_OTP_URL,
      method: 'POST',
      body: JSON.stringify(data)
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {

        if (resData.status !== true) {
          throw new Error('Invalid Otp')
        }
        dispatch({
          type: AuthActionTypes.VERIFY_OTP_SUCCESS,
        });
        dispatch({
          type: AuthActionTypes.LOGIN_SUCCESS
        });

      })
      .catch(err => {
        console.log('the error is', err);
        dispatch(setError(err))
        dispatch({ type: AuthActionTypes.VERIFY_OTP_FAILURE });
      });
  };

}

export const hideOtp = () => ({ type: AuthActionTypes.HIDE_OTP })

export const getProfileCount = query => {
  return dispatch => {
    dispatch({ type: AuthActionTypes.GET_PROFILE_COUNT_START })
    FETCH_FROM_NODE({
      url: API.GET_COUNT_URL + query,
      method: 'GET',
      // body: JSON.stringify(data)
    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Worng!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {

        dispatch({
          type: AuthActionTypes.GET_PROFILE_COUNT_SUCCESS,
          payload: resData,
        });
      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: AuthActionTypes.GET_PROFILE_COUNT_FAILURE });
      });
  }
}

//changes made at 13/3/23 use fetch from node instead of fetch 
export const getCredits = (data) => {
  return dispatch => {
    dispatch({ type: AuthActionTypes.GET_CREDITS_COUNT_START });
    FETCH_FROM_NODE({
      url: `${API.GET_CREDIT_COUNT_URL}?userId=${data.id}`,
      method: 'GET',

    })
      .then(res => {
        let errorMessage;
        if (res.status !== 200) {
          errorMessage = 'Something Went Wrong!';
        }
        if (errorMessage) throw new Error(errorMessage)
        return res.json();
      })
      .then(resData => {
        dispatch({
          type: AuthActionTypes.GET_CREDITS_COUNT_SUCCESS,
          payload: resData.whatsappPoint ?? "0"
        });
      })
      .catch(err => {
        console.log(err);
        dispatch(setError(err))
        dispatch({ type: AuthActionTypes.GET_CREDITS_COUNT_FAILURE });
      });
  }
}

export const updatePhotoList = list => dispatch => dispatch({ type: AuthActionTypes.UPDATE_AUTH_PHOTO, payload: list })