/* eslint-disable no-unused-vars */
import React, { Suspense, lazy, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import 'animate.css/animate.css';
import './App.scss';
import { useSelector } from 'react-redux';
import { checkAuth, verifyOtp } from './redux/auth/auth.action';
import { selectIsAuth } from './redux/auth/auth.selector';
import { selectShowOtp } from './redux/auth/auth.selector';
import LandingPage from './pages/landing-page/landing-page.component';
import Preloader from './components/preloader/preloader.component';
import Subscription from './pages/subscription/subscription';
import authRoutes from './routes';
import Profiles from './components/profiles/profiles.component';
import TodayProfileComponent from './components/today-profile/today-profile.component';
import PopularComponent from './components/popular/popular.component';
// import RegisterTwo from './pages/register.form.two/register.form.two.container'
import NewlyJoinedComponent from './components/newly-joined/newly-joined.component';
import UploadPhotoOne from './pages/upload.photo/upload.photo.one';
import UploadPhotoTwo from './pages/upload.photo/upload.photo.two';
const Register = lazy(() => import('./pages/register/register.container'));
const RegisterTwo = lazy(() => import('./pages/register.form.two/register.form.two.container'));
const Dashboard = lazy(() => import('./pages/dashboard/dashboard.component'));
const PhoneNo = lazy(() => import('./pages/phone-no/phone-no.component'));
const DiscoverProfile = lazy(() => import('./pages/discover-profile/discover-profile.component'))
const Interested = lazy(() => import('./pages/interested/interested.component'))
const HistorySection = lazy(() => import('./pages/history-section/history-section.component'))


function App({ checkAuth, isAuth, showOtp }) {
  const auth = useSelector(state => state.auth)
  const newUser = auth.newUser || 0;
  return (
    <Suspense fallback={<Preloader />}>
      <Routes>
        {/* <Route path='/fourReg' element={(isAuth )? (<Dashboard />) : (<Register />)} /> */}
        {/* <Route path={isAuth && showOtp ? '/dashboard' : '/fourReg'} element={isAuth ? <Dashboard /> : <Register />} /> */}
        <Route path='/fourReg' element={(<Register />)} />
        <Route path="fourReg2/uploadPhotoPartTwo" element={(<UploadPhotoTwo />)} />
        <Route path='/phone-number' element={(<PhoneNo />)} />
        <Route path="fourReg2/uploadPhotoPartOne" element={(<UploadPhotoOne />)} />

        {
          isAuth ? authRoutes.map((route, idx) => (
            <Route exact={route.exact ?? false} path={route.path} element={route.component} />
          )) : null
        }
        {
          (isAuth && newUser === 1) ? (<Route path='/fourReg2' element={(<RegisterTwo />)} />) : null
        }

        {
          isAuth ? (
            <Route path="/chat" element={<Dashboard />}>
              {/* <Route path="/dashboard" element={<Dashboard />} /> */}
              <Route path='/chat' element={<Profiles />} >
                <Route index element={<TodayProfileComponent />} />
                <Route path="popular" element={<PopularComponent />} />
                <Route path="new" element={<NewlyJoinedComponent />} />
              </Route>
              <Route exact path="discover-profile" element={<DiscoverProfile />} />
              <Route exact path="interested" element={<Interested />} />
              <Route exact path="history" element={<HistorySection />} />
            </Route>
          ) : null
        }
        {/* <Route exact path='/' element={<LandingPage />} /> */}
        <Route exact path='/' element={<PhoneNo />} />

        <Route path='/subscription' element={<Subscription />} />
      </Routes>
    </Suspense>
  );
}

const mapStateToProps = createStructuredSelector({
  isAuth: selectIsAuth,
  showOtp: selectShowOtp
})

// const mapDispatchToProps = dispatch => ({
//   checkAuth: (data) => dispatch(checkAuth(data))
// })

const mapDispatchToProps = dispatch => ({
  verifyOtp: (data) => dispatch(verifyOtp(data))
})


export default connect(mapStateToProps, mapDispatchToProps)(App);
